import { defineMessages } from 'react-intl'

export const CreateJobQueueOutOfCreditsErrorContentMessages = defineMessages({
  body: {
    defaultMessage: `To create a job queue, you need to recharge your credit balance. Visit your billing page, where you can add credits or configure automatic recharge for your organization.`,
    id: 'bgQaFzFz',
    description:
      'The toast notification body shown when we encounter an error on their request to create a job queue because the user is out of credits.',
  },
  title: {
    defaultMessage: `You're Out of Credits!`,
    id: 'xQWSIfzs',
    description:
      'The toast notification title shown when we encounter an error on their request to create a job queue because the user is out of credits.',
  },
})

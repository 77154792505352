import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Modal } from '../../block/Modal'
import { Button } from '../../Button'
import { OutOfCreditsModalMessages } from '../messages'

export interface OutOfCreditsModalProps {
  /** Callback executed when the user clicks the Cancel button. */
  onCancel: () => void
  /** Callback executed when the user clicks the View Billing Information button. */
  onViewBillingInformation: () => void
}

export const OutOfCreditsModal: FunctionComponent<OutOfCreditsModalProps> = ({
  onCancel,
  onViewBillingInformation,
}) => {
  const intl = useIntl()

  return (
    <Modal onClose={onCancel} title={intl.formatMessage(OutOfCreditsModalMessages.title)}>
      <h1 className="mb-8 text-2xl font-bold">{intl.formatMessage(OutOfCreditsModalMessages.title)}</h1>
      <p className="mb-4">{intl.formatMessage(OutOfCreditsModalMessages.descriptionPartOne)}</p>
      <p className="mb-8">{intl.formatMessage(OutOfCreditsModalMessages.descriptionPartTwo)}</p>
      <div className="flex gap-6">
        <Button variant="green-outlined" onClick={onCancel} isFullWidth>
          {intl.formatMessage(OutOfCreditsModalMessages.cancelButtonLabel)}
        </Button>
        <Button variant="green-filled" onClick={onViewBillingInformation} isFullWidth>
          {intl.formatMessage(OutOfCreditsModalMessages.ViewBillingInformationButtonLabel)}
        </Button>
      </div>
    </Modal>
  )
}

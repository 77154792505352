import { defineMessages } from 'react-intl'

export const PaymentRemovedWithoutBillingProfileUpdatedMessages = defineMessages({
  body: {
    defaultMessage:
      'Your payment method has been removed, but we are having trouble getting your updated billing profile that reflects those changes. Please try refreshing the page.',
    id: 'Z7iMHxXH',
    description:
      'The description shown in a toast notification message shown when we encounter an error to remove the payment method.',
  },
  title: {
    defaultMessage: 'Payment Method has been removed',
    id: 'KEamzKU4',
    description: 'The toast notification title shown when we encounter an error to remove the payment method.',
  },
})

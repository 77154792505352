import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelEvent } from '../../features/analytics/analyticsSlice'
import { showStripeFormError } from '../../features/billing/billingSlice'
import {
  getManualCreditRequestChangePaymentMethodPageData,
  getManualCreditRequestChangePaymentMethodSetupStatus,
  stopPollingForManualCreditChangePaymentMethod,
} from '../../features/billingProfile/billingProfileSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectRequestStatus } from '../../features/requestStatus/requestStatusSelectors'
import { selectStripeSetupIntentClientSecret } from '../../features/stripeSetupIntent/stripeSetupIntentSelector'
import { clearStripeSetupIntent } from '../../features/stripeSetupIntent/stripeSetupIntentSlice'
import { ManuallyAddCreditWizardPath } from '../../routes/routePaths'
import { getManualCreditRequestPaymentSummaryPage } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { ManualCreditRequestChangePaymentMethodPage } from './ManualCreditRequestChangePaymentMethodPage'

export const ConnectedManualCreditRequestChangePaymentMethodPage: FunctionComponent = () => {
  const { organizationName = '' } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const isPageDataLoading = useAppSelector(
    (state) => selectRequestStatus(state, 'getManualCreditRequestChangePaymentMethodPageData') === 'pending',
  )
  const isGetManualCreditRequestChangePaymentMethodSetupStatusPending = useAppSelector(
    (state) => selectRequestStatus(state, 'getManualCreditRequestChangePaymentMethodSetupStatus') === 'pending',
  )
  const clientSecret = useAppSelector((state) => selectStripeSetupIntentClientSecret(state))

  const handleBack = useCallback(() => {
    navigate(getManualCreditRequestPaymentSummaryPage(organizationName))
  }, [navigate, organizationName])

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const handleShowStripeFormError = useCallback(
    (message?: string) => {
      dispatch(showStripeFormError({ message }))
    },
    [dispatch],
  )

  const handleSubmitStripeFormSucceeded = useCallback(() => {
    if (organizationName) {
      dispatch(getManualCreditRequestChangePaymentMethodSetupStatus({ organizationName }))
    }
  }, [dispatch, organizationName])

  /*
  Workaround to prevent duplicate API calls in local development due to React Strict Mode's double rendering.
  The setup intent API call fails if triggered twice on page load, so useRef ensures it runs only once.
  */
  const hasManualCreditRequestChangePaymentMethodPageDataBeenCalled = useRef(false)

  useEffect(() => {
    if (!hasManualCreditRequestChangePaymentMethodPageDataBeenCalled.current) {
      dispatch(getManualCreditRequestChangePaymentMethodPageData({ organizationName }))
      dispatch(
        trackMixpanelEvent({
          event: 'Page Viewed',
          properties: { path: ManuallyAddCreditWizardPath.ChangePaymentMethod },
        }),
      )
      hasManualCreditRequestChangePaymentMethodPageDataBeenCalled.current = true
    }
  }, [dispatch, organizationName])

  useEffect(() => {
    return () => {
      dispatch(clearStripeSetupIntent())
      dispatch(stopPollingForManualCreditChangePaymentMethod())
    }
  }, [dispatch, organizationName])

  return isPageDataLoading ? (
    <LoadingPage />
  ) : (
    <ManualCreditRequestChangePaymentMethodPage
      clientSecret={clientSecret}
      isGetManualCreditRequestChangePaymentMethodSetupStatusPending={
        isGetManualCreditRequestChangePaymentMethodSetupStatusPending
      }
      isLeftColumnOpen={isLeftColumnOpen}
      onBack={handleBack}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onSubmitStripeFormError={handleShowStripeFormError}
      onSubmitStripeFormSucceeded={handleSubmitStripeFormSucceeded}
    />
  )
}

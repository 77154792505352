import { defineMessages } from 'react-intl'

export const ManualCreditRequestSuccessfulContentMessages = defineMessages({
  body: {
    defaultMessage: '{credit_amount} in credit has successfully been added to your organization.',
    id: 'xJfny8Mp',
    description:
      'The description shown in a toast notification message shown when we encounter an error loading the billing profile of an organization.',
  },
  title: {
    defaultMessage: 'Credit added to organization',
    id: 'wge8pNVs',
    description:
      'The toast notification title shown when we encounter an error loading the billing profile of an organization.',
  },
})

import { catchError, concat, delay, filter, from, mergeMap, of } from 'rxjs'
import { BillingProfileAPI } from '../apiMethods'
import { disableAutoRechargeSettings, getBillingProfile } from '../features/billingProfile/billingProfileSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  getAutoRechargeSettingsErrorContent,
  getAutoRechargeSettingsSuccessContent,
} from '../notifications/clientToastNotificationContent/billingProfile'
import { AppEpic } from '../store'

export const onDisableAutoRechargeSettings: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(disableAutoRechargeSettings.match),
    mergeMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'disableAutoRechargeSettings', status: 'pending' })),
        from(
          BillingProfileAPI.updateAutomaticRechargeSettings({
            organizationName,
            updateAutomaticRechargeSettings: {
              enabled: false,
            },
          }),
        ).pipe(
          mergeMap(() =>
            concat(
              of(
                setRequestStatus({ request: 'disableAutoRechargeSettings', status: 'succeeded' }),
                showToastNotification(getAutoRechargeSettingsSuccessContent(intl)),
                getBillingProfile({ organizationName }),
              ),
              of(setRequestStatus({ request: 'disableAutoRechargeSettings', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError(() =>
            concat(
              of(
                setRequestStatus({ request: 'disableAutoRechargeSettings', status: 'failed' }),
                showToastNotification(getAutoRechargeSettingsErrorContent(intl)),
              ),
              of(setRequestStatus({ request: 'disableAutoRechargeSettings', status: 'idle' })).pipe(delay(1)),
            ),
          ),
        ),
      ),
    ),
  )

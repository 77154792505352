import { loadStripe, Stripe } from '@stripe/stripe-js'
import { STORYBOOK_STRIPE_API_KEY, STRIPE_API_KEY } from '../../../config'
import { showToastNotification } from '../../../features/notifications/notificationsSlice'
import { getLoadStripeErrorMessage } from '../../../notifications/clientToastNotificationContent/billing/getLoadStripeErrorMessage'
import { getCreateOrganizationWizardPaymentSummaryGeneralErrorContent } from '../../../notifications/clientToastNotificationContent/createOrganizationWizard'

export const stripePromise = (intl: any, isStorybook?: boolean) => {
  const stripeApiKey = isStorybook ? STORYBOOK_STRIPE_API_KEY : STRIPE_API_KEY
  return loadStripe(stripeApiKey as string).catch(() => showToastNotification(getLoadStripeErrorMessage(intl)))
}

export const stripeAppearanceVariables = {
  colorPrimary: '#53a626',
  colorBackground: '#ffffff',
  colorText: '#0a2133',
  colorDanger: '#ca3c24',
  fontFamily: 'Mallory, system-ui, sans-serif',
  spacingUnit: '4px',
  borderRadius: '4px',
}

/**
 * Confirms a card payment using the Stripe SDK via stripePromise.
 *
 * @param intl - The internationalization object for error messages.
 * @param paymentIntentClientSecret - The client secret for the PaymentIntent.
 * @returns A promise that resolves to the PaymentIntent result or throws an error.
 */
export const stripeConfirmCardPayment = async (intl: any, paymentIntentClientSecret: string): Promise<void> => {
  try {
    const stripe = await stripePromise(intl)

    if (!stripe || typeof (stripe as Stripe).confirmCardPayment !== 'function') {
      showToastNotification(getCreateOrganizationWizardPaymentSummaryGeneralErrorContent(intl))
      throw new Error('Stripe initialization failed.')
    }

    const result = await (stripe as Stripe).confirmCardPayment(paymentIntentClientSecret)

    if (result.hasOwnProperty('error') && result.error) {
      const errorMessage = result.error.message || 'Payment confirmation failed.'
      showToastNotification(getCreateOrganizationWizardPaymentSummaryGeneralErrorContent(intl))
      throw new Error(errorMessage)
    }
  } catch (error) {
    showToastNotification(getCreateOrganizationWizardPaymentSummaryGeneralErrorContent(intl))
    throw error
  }
}

import { FunctionComponent } from 'react'
import { ThreeColumnLayout } from '../../../../layouts/threeColumnLayout'
import { CreateOrganizationWizardPageSteps } from '../../models'
import { CreateOrganizationWizardProgressBar } from '../CreateOrganizationWizardProgressBar.tsx'
import { PaymentSummary } from '../PaymentSummary/components/PaymentSummaryMainContent'

export interface PaymentSummaryPageProps {
  /** The card brand */
  creditCardBrand?: string
  /** The expiration date of the card */
  creditCardExpirationDate: string
  /** The last four digits of the card number */
  creditCardLastFourDigits: string
  /** The amount of estimated tax for the payment in cents. */
  estimatedTax: number
  /** The amount of initial credit for Salad Technologies Cloud Usage in cents. */
  initialCredit: number
  /** A flag indicating if the request to confirm the payment is pending. */
  isConfirmPaymentSummaryPending: boolean
  /** A flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** A callback to handle navigation (Back) */
  onBack: () => void
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** A callback that when executed confirms the payment summary */
  onConfirmPaymentSummary: () => void
}

export const PaymentSummaryPage: FunctionComponent<PaymentSummaryPageProps> = ({
  creditCardBrand,
  creditCardExpirationDate,
  creditCardLastFourDigits,
  estimatedTax,
  initialCredit,
  isConfirmPaymentSummaryPending,
  isLeftColumnOpen,
  onBack,
  onCloseLeftDrawer,
  onConfirmPaymentSummary,
}) => {
  return (
    <ThreeColumnLayout
      LeftColumn={
        <CreateOrganizationWizardProgressBar currentPage={CreateOrganizationWizardPageSteps.PaymentSummary} />
      }
      MainColumn={
        <PaymentSummary
          creditCardBrand={creditCardBrand}
          creditCardExpirationDate={creditCardExpirationDate}
          creditCardLastFourDigits={creditCardLastFourDigits}
          estimatedTax={estimatedTax}
          isConfirmPaymentSummaryPending={isConfirmPaymentSummaryPending}
          onBack={onBack}
          onConfirmPaymentSummary={onConfirmPaymentSummary}
          initialCredit={initialCredit}
        />
      }
      isLeftColumnOpen={isLeftColumnOpen}
      isMainColumnWithoutLayoutStyles
      isMainColumnWithoutScrollStyles
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}

import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'

import {
  selectBillingProfileHasPaymentMethodError,
  selectIsBillingProfileOutOfCreditsBalance,
} from '../../features/billingProfile/billingProfileSelectors'
import { selectLatestInferenceEndpointJob } from '../../features/inferenceEndpointJobs/inferenceEndpointJobsSelectors'
import {
  createInferenceEndpointJob,
  stopPollingInferenceEndpointJob,
} from '../../features/inferenceEndpointJobs/inferenceEndpointJobsSlice'
import { configuresInferenceEndpointJobsEntityId } from '../../features/inferenceEndpointJobs/utils'
import {
  selectInferenceEndpoint,
  selectIsInferenceEndpointBillingInformationMissingModalShowing,
  selectIsInferenceEndpointExhaustedFreeTrialModalShowing,
  selectIsInferenceEndpointOutOfCreditsModalShowing,
} from '../../features/inferenceEndpoints/inferenceEndpointsSelectors'
import {
  getInferenceEndPointDetailsPageData,
  setInferenceEndpointIsBillingInformationMissingModalShowing,
  setInferenceEndpointIsExhaustedFreeTrialModalShowing,
  setInferenceEndpointIsOutOfCreditsModalShowing,
} from '../../features/inferenceEndpoints/inferenceEndpointsSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectProjects } from '../../features/projects/projectsSelectors'
import { selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { inferenceEndpointDetailsPageRoutePath } from '../../routes/routePaths'
import {
  getContainerGroupsPagePath,
  getInferenceEndpointsMarketplacePagePath,
  getOrganizationBillingPagePath,
} from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { InferenceEndpointDetailsPage } from './InferenceEndpointDetailsPage'
import { getInferenceEndpointJobsRequestPayload } from './components/utils'
import { InferenceEndpointPlaygroundFormValues } from './models'

export const ConnectedInferenceEndpointDetailsPage: FunctionComponent = () => {
  const { inferenceEndpointName = '', organizationName = '' } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const isGetInferenceEndpointDetailsPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'getInferenceEndpointDetailsPageData'),
  )
  const isCreateInferenceEndpointJobPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'createInferenceEndpointJob'),
  )
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))
  const inferenceEndpoint = useAppSelector((state) =>
    selectInferenceEndpoint(state, organizationName, inferenceEndpointName),
  )
  const isPaymentMethodError = useAppSelector((state) =>
    selectBillingProfileHasPaymentMethodError(state, organizationName),
  )
  const isBillingInformationMissingModalShowing = useAppSelector((state) =>
    selectIsInferenceEndpointBillingInformationMissingModalShowing(state, organizationName),
  )
  const isExhaustedFreeTrialModalShowing = useAppSelector((state) =>
    selectIsInferenceEndpointExhaustedFreeTrialModalShowing(state, organizationName),
  )
  const isOutOfCreditsModalShowing = useAppSelector((state) =>
    selectIsInferenceEndpointOutOfCreditsModalShowing(state, organizationName),
  )
  const inferenceEndpointJob = useAppSelector((state) =>
    selectLatestInferenceEndpointJob(
      state,
      configuresInferenceEndpointJobsEntityId(organizationName, inferenceEndpointName),
    ),
  )
  const isBillingProfileOutOfCreditsBalance = useAppSelector((state) =>
    selectIsBillingProfileOutOfCreditsBalance(state, organizationName),
  )
  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []
  const projectSelectOptions = projects.map((project) => {
    return {
      ...project,
      selected: false,
    }
  })

  const handleProjectChange = useCallback(
    (projectName: string) => {
      navigate(getContainerGroupsPagePath(organizationName, projectName))
    },
    [navigate, organizationName],
  )

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const handleSetIsBillingInformationMissingModalShowing = useCallback(
    (isBillingInformationMissingModalShowing: boolean) => {
      dispatch(
        setInferenceEndpointIsBillingInformationMissingModalShowing({
          organizationName,
          isBillingInformationMissingModalShowing,
        }),
      )
    },
    [dispatch, organizationName],
  )

  const handleSetIsExhaustedFreeTrialModalShowing = useCallback(
    (isExhaustedFreeTrialModalShowing: boolean) => {
      dispatch(
        setInferenceEndpointIsExhaustedFreeTrialModalShowing({
          organizationName,
          isExhaustedFreeTrialModalShowing,
        }),
      )
    },
    [dispatch, organizationName],
  )

  const handleSetIsOutOfCreditsModalShowing = useCallback(
    (isOutOfCreditsModalShowing: boolean) => {
      dispatch(
        setInferenceEndpointIsOutOfCreditsModalShowing({
          organizationName,
          isOutOfCreditsModalShowing,
        }),
      )
    },
    [dispatch, organizationName],
  )

  const onCreateInferenceEndpointJob = (values: InferenceEndpointPlaygroundFormValues) => {
    const { url } = values
    if (inferenceEndpoint) {
      const payload = getInferenceEndpointJobsRequestPayload(inferenceEndpoint, url)
      if (payload) {
        dispatch(
          createInferenceEndpointJob({
            organizationName,
            inferenceEndpointName: inferenceEndpoint.name,
            createInferenceEndpointJob: { input: payload },
          }),
        )
      }
    }
  }

  useEffect(() => {
    dispatch(getInferenceEndPointDetailsPageData({ organizationName, inferenceEndpointName }))
    dispatch(
      trackMixpanelPageViewedEvent({
        path: inferenceEndpointDetailsPageRoutePath,
        organizationName,
        resourceName: inferenceEndpointName,
      }),
    )

    return () => {
      dispatch(stopPollingInferenceEndpointJob())
    }
  }, [dispatch, inferenceEndpointName, organizationName])

  return isGetInferenceEndpointDetailsPending || inferenceEndpoint === undefined ? (
    <LoadingPage />
  ) : (
    <InferenceEndpointDetailsPage
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      inferenceEndpoint={inferenceEndpoint}
      inferenceEndpointJob={inferenceEndpointJob}
      inferenceEndpointMarketplacePath={getInferenceEndpointsMarketplacePagePath(organizationName)}
      isBillingInformationMissingModalShowing={isBillingInformationMissingModalShowing}
      isBillingProfileOutOfCreditsBalance={isBillingProfileOutOfCreditsBalance}
      isCreateInferenceEndpointJobPending={isCreateInferenceEndpointJobPending}
      isExhaustedFreeTrialModalShowing={isExhaustedFreeTrialModalShowing}
      isLeftColumnOpen={isLeftColumnOpen}
      isOutOfCreditsModalShowing={isOutOfCreditsModalShowing}
      isPaymentMethodError={isPaymentMethodError}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onCreateInferenceEndpointJob={onCreateInferenceEndpointJob}
      onEnterBillingInformation={() => navigate(getOrganizationBillingPagePath(organizationName))}
      onProjectChange={handleProjectChange}
      onSetBillingInformationMissingModalShowingState={handleSetIsBillingInformationMissingModalShowing}
      onSetExhaustedFreeTrialModalShowingState={handleSetIsExhaustedFreeTrialModalShowing}
      onSetOutOfCreditsModalShowingState={handleSetIsOutOfCreditsModalShowing}
      projects={projectSelectOptions}
    />
  )
}

import { createSelector } from '@reduxjs/toolkit'
import { CreateOrganizationWizardState } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { AppState } from '../../store'
import { createOrganizationWizardAdapter } from './createOrganizationWizardSlice'

const { selectById } = createOrganizationWizardAdapter.getSelectors((state: AppState) => state.createOrganizationWizard)

const createOrganizationWizardSelectors = createOrganizationWizardAdapter.getSelectors()

export const selectCreateOrganizationWizard = (
  state: AppState,
  id: string,
): CreateOrganizationWizardState | undefined => selectById(state, id)

export const selectCreateOrganizationWizardListWithReservedNames = createSelector(
  createOrganizationWizardSelectors.selectAll,
  (createOrganizationWizards) =>
    createOrganizationWizards.filter((wizard) => wizard.organizationReservedName !== undefined).map((wizard) => wizard),
)

import { defineMessages } from 'react-intl'

export const ManualCreditRequestPaymentSummaryPageRequiresCreditAmountErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'A credit amount to add to the organization must be selected before reviewing your payment summary.',
    id: 'oTResA3U',
    description:
      'The description shown in a toast notification message shown when a user is attempting to review their payment summary without providing a credit amount.',
  },
  title: {
    defaultMessage: 'Credit amount required',
    id: '6a4B6cSO',
    description:
      'The toast notification title shown when a user is attempting to review their payment summary without providing a credit amount.',
  },
})

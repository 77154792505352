import { FunctionComponent, useCallback, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import { LoadingPage } from '../../../../components/page/LoadingPage'
import { trackMixpanelEvent } from '../../../../features/analytics/analyticsSlice'
import { showStripeFormError } from '../../../../features/billing/billingSlice'
import { selectCreateOrganizationWizard } from '../../../../features/createOrganizationWizard/createOrganizationWizardSelector'
import {
  getAddPaymentMethodPageData,
  getAddPaymentMethodSetupStatus,
} from '../../../../features/createOrganizationWizard/createOrganizationWizardSlice'
import { selectIsLeftColumnShowing } from '../../../../features/navigationBar/navigationBarSelectors'
import { selectRequestStatus } from '../../../../features/requestStatus/requestStatusSelectors'
import { selectStripeSetupIntentClientSecret } from '../../../../features/stripeSetupIntent/stripeSetupIntentSelector'
import { stopPollingForStripeSetupIntent } from '../../../../features/stripeSetupIntent/stripeSetupIntentSlice'
import { setLeftColumnShowing } from '../../../../mockFeatures/mockNavigationBar/mockNavigationBarSlice'
import { CreateOrganizationWizardPath } from '../../../../routes/routePaths'
import { getCreateOrganizationWizardConfigureCreditSettingsPagePath } from '../../../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { AddPaymentMethodPage } from './AddPaymentMethodPage'

export const ConnectedAddPaymentMethodPage: FunctionComponent = () => {
  const { createOrganizationWizardId = '' } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const isGetAddPaymentMethodSetupStatusPending = useAppSelector(
    (state) => selectRequestStatus(state, 'getAddPaymentMethodSetupStatus') === 'pending',
  )
  const isGetAddPaymentMethodPageDataPending = useAppSelector(
    (state) => selectRequestStatus(state, 'getAddPaymentMethodPageData') === 'pending',
  )
  const clientSecret = useAppSelector((state) => selectStripeSetupIntentClientSecret(state))
  const createOrganizationWizard = useAppSelector((state) =>
    selectCreateOrganizationWizard(state, createOrganizationWizardId),
  )
  const isFreeTrialEnabled = createOrganizationWizard?.initialCredit === 0

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const handleShowStripeFormError = useCallback(
    (message?: string) => {
      dispatch(showStripeFormError({ message }))
    },
    [dispatch],
  )

  const handleOnSubmitStripeFormSucceeded = useCallback(() => {
    if (createOrganizationWizardId) {
      dispatch(getAddPaymentMethodSetupStatus({ createOrganizationWizardId }))
    }
  }, [dispatch, createOrganizationWizardId])

  const handleOnBack = useCallback(() => {
    navigate(getCreateOrganizationWizardConfigureCreditSettingsPagePath(createOrganizationWizardId))
  }, [createOrganizationWizardId, navigate])

  /*
  Workaround to prevent duplicate API calls in local development due to React Strict Mode's double rendering.
  The setup intent API call fails if triggered twice on page load, so useRef ensures it runs only once.
  */
  const hasAddPaymentMethodPageDataBeenCalled = useRef(false)

  useEffect(() => {
    if (!hasAddPaymentMethodPageDataBeenCalled.current) {
      dispatch(getAddPaymentMethodPageData({ createOrganizationWizardId }))
      dispatch(
        trackMixpanelEvent({
          event: 'Page Viewed',
          properties: { path: CreateOrganizationWizardPath.AddPaymentMethod, isFreeTrialEnabled },
        }),
      )
      hasAddPaymentMethodPageDataBeenCalled.current = true
    }
  }, [createOrganizationWizardId, dispatch, isFreeTrialEnabled])

  useEffect(() => {
    return () => {
      dispatch(stopPollingForStripeSetupIntent({ createOrganizationWizardId }))
    }
  }, [createOrganizationWizardId, dispatch])

  return isGetAddPaymentMethodPageDataPending ? (
    <LoadingPage />
  ) : (
    <AddPaymentMethodPage
      isGetAddPaymentMethodSetupStatusPending={isGetAddPaymentMethodSetupStatusPending}
      isLeftColumnOpen={isLeftColumnOpen}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onSubmitStripeFormError={handleShowStripeFormError}
      onSubmitStripeFormSucceeded={handleOnSubmitStripeFormSucceeded}
      onBack={handleOnBack}
      isFreeTrial={isFreeTrialEnabled}
      clientSecret={clientSecret}
    />
  )
}

import { defineMessages } from 'react-intl'

export const BillingMainContentMessages = defineMessages({
  billingInformationTabLabel: {
    defaultMessage: 'Billing Information',
    id: 'WHVYREor',
    description: 'The label for the billing information tab.',
  },
  creditsTabLabel: {
    defaultMessage: 'Credits',
    id: 'Kek9ujyL',
    description: 'The label for the credits tab.',
  },
  currentCreditAmount: {
    defaultMessage: 'Current Credit Amount: {current_credit_amount}',
    id: 'd082K3uh',
    description: 'The label for the current credit amount.',
  },
  description: {
    defaultMessage: 'Review previous invoice and manage your payment profiles',
    id: 'Jl6CmV1f',
    description: 'The description for the Billing page.',
  },
  enterprisePricingText: {
    defaultMessage: 'Interested in Enterprise Pricing? {talk_to_sales}',
    id: 'hMTS/EG0',
    description: 'The text for prompting users to contact sales for enterprise pricing.',
  },
  invoicesTabLabel: {
    defaultMessage: 'Invoices',
    id: 'pbgv+Z8t',
    description: 'The label for the invoices tab.',
  },
  prepaidBillingTransitionInformationDocumentationLinkText: {
    defaultMessage: 'outlined in our documentation',
    id: '3fOGmCBh',
    description: 'The text for the link to the documentation for the prepaid billing transition information.',
  },
  prepaidBillingTransitionInformationFaqLinkText: {
    defaultMessage: 'Prepaid Billing FAQs',
    id: '6dq7cVpt',
    description: 'The text for the FAQ link to the documentation for the prepaid billing transition information.',
  },
  prepaidBillingTransitionInformationBody: {
    defaultMessage: `To keep your workloads active, please follow the steps {documentation_link}. Starting February 1, 2025, organizations must maintain a positive credit balance. During January 2025, you'll need either a positive credit balance or a valid payment method on file to use SaladCloud and avoid automatic shutdowns. For more details, refer to our {faq_link}.`,
    id: 'oo5P9EI+',
    description: 'The message for the prepaid billing transition information.',
  },
  prepaidBillingTransitionInformationTitle: {
    defaultMessage: 'SaladCloud is transitioning to prepaid billing',
    id: 'AgEzToQD',
    description: 'The title for the prepaid billing transition information.',
  },
  returnToPreviousPageLinkText: {
    defaultMessage: 'Return to previous page',
    id: 'BQAF8CIV',
    description: 'The text for the link that returns the user back to the previous page they were viewing.',
  },
  talkToSales: {
    defaultMessage: 'Talk to Sales',
    id: 'C+zWAiDz',
    description: 'The text for the Talk to Sales link.',
  },
  title: {
    defaultMessage: '{organization_display_name} Billing',
    id: '4QWdjfQQ',
    description: 'The title of for the Billing page.',
  },
  usageTabLabel: {
    defaultMessage: 'Usage',
    id: 'DBpRd/4g',
    description: 'The label for the usage tab.',
  },
})

import { Elements } from '@stripe/react-stripe-js'
import { Stripe, StripeElementsOptions } from '@stripe/stripe-js'
import { useMemo, type FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../../../components/Button'
import { ErrorBoundary } from '../../../../../../components/page/ErrorBoundary'
import { BillingInformationMessages } from '../../../../../Billing/messages'
import { stripeAppearanceVariables, stripePromise } from '../../../../utils'
import { StripeForm } from '../StripeForm/StripeForm'

interface StripeFormWrapperProps {
  /** The client secret for the Stripe setup intent */
  clientSecret: string | undefined
  /** Flag to indicate if the payment method is being added */
  isGetAddPaymentMethodSetupStatusPending?: boolean
  /* Flag to indicate if this is being rendered in Storybook */
  isStorybookRender?: boolean
  /** The callback handler for cancelling the form */
  onBack: () => void
  /** The optional back button label */
  onBackButtonLabel?: string
  /** The callback handler for when the Stripe submit form hits an error */
  onSubmitStripeFormError: (message?: string) => void
  /** The callback handler for when the Stripe submit form succeeded */
  onSubmitStripeFormSucceeded: () => void
}

export const StripeFormWrapper: FunctionComponent<StripeFormWrapperProps> = ({
  clientSecret,
  isGetAddPaymentMethodSetupStatusPending,
  isStorybookRender,
  onBack,
  onBackButtonLabel,
  onSubmitStripeFormError,
  onSubmitStripeFormSucceeded,
}) => {
  const intl = useIntl()
  const stripeFormOptions: StripeElementsOptions = {
    appearance: {
      variables: stripeAppearanceVariables,
    },
    clientSecret: clientSecret,
    loader: 'never',
  }

  const stripeLoadElement = useMemo(
    () => stripePromise(intl, isStorybookRender) as Promise<Stripe | null>,
    [intl, isStorybookRender],
  )

  return (
    <ErrorBoundary
      fallback={
        <>
          <p className="my-3 text-blue-100">{intl.formatMessage(BillingInformationMessages.generalStripeFormError)}</p>
          <Button variant="red-filled-light" onClick={() => window.location.reload()}>
            {intl.formatMessage(BillingInformationMessages.reloadButton)}
          </Button>
        </>
      }
    >
      <Elements stripe={stripeLoadElement} options={stripeFormOptions}>
        <StripeForm
          clientSecret={clientSecret}
          isGetAddPaymentMethodSetupStatusPending={isGetAddPaymentMethodSetupStatusPending}
          isStorybookRender={isStorybookRender}
          onBack={onBack}
          onBackButtonLabel={onBackButtonLabel}
          onSubmitStripeFormError={onSubmitStripeFormError}
          onSubmitStripeFormSucceeded={onSubmitStripeFormSucceeded}
        />
      </Elements>
    </ErrorBoundary>
  )
}

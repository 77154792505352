import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { AutoRechargeSettingsSuccessMessages } from './messages/AutoRechargeSettingsSuccessMessages'

/**
 * Retrieves the content for a success `ToastNotification` when automatic recharge settings are updated successfully.
 *
 * @param intl The `IntlShape` object used to format messages.
 */
export const getAutoRechargeSettingsSuccessContent = (
  intl: IntlShape,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(AutoRechargeSettingsSuccessMessages.body),
    type: 'success',
    title: intl.formatMessage(AutoRechargeSettingsSuccessMessages.title),
  }
}

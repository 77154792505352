import { defineMessages } from 'react-intl'

export const AutoRechargeSettingsErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'Something went wrong while updating your automatic recharge settings. Try again, and if this issue persists, {contact_support}.',
    id: 'lw592jwf',
    description:
      'The description shown in a toast notification message when updating automatic recharge settings fails.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'ugLE+mxt',
    description:
      'The text shown for the contact support link in the automatic recharge settings error toast notification.',
  },
  title: {
    defaultMessage: 'Failed to Update Automatic Recharge Settings',
    id: '/bYsIaAX',
    description: 'The toast notification title shown when updating automatic recharge settings fails.',
  },
})

import { defineMessages } from 'react-intl'

export const UpdateManualCreditRequestAmountErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to add the request credit amount for your organization. If this issue persists {contact_support}.',
    id: 'fXBlzbD9',
    description:
      'The description shown in a toast notification message shown when we encounter an error updating the credit request amount manually.',
  },
  contactSupport: {
    defaultMessage: 'contact support',
    id: 'lTdmQdBP',
    description:
      'The text shown for the contact support link in the update manual credit request amount error toast notification.',
  },
  title: {
    defaultMessage: 'Error updating credit request amount',
    id: 'uGNOTzQ3',
    description:
      'The toast notification title shown when we encounter an error updating the credit request amount manually.',
  },
})

import { defineMessages } from 'react-intl'

export const LoadInvoiceDashboardErrorMessages = defineMessages({
  body: {
    defaultMessage:
      "We were unable to load your organization's invoice dashboard. If this issue persists {contact_support}.",
    id: 'MMoxqDKn',
    description:
      'The description shown in a toast notification message shown when we encounter an error loading the invoice dashboard of an organization.',
  },
  contactSupport: {
    defaultMessage: 'contact support',
    id: 'jtzfUIX8',
    description:
      'The text shown for the contact support link in the update load invoice dashboard error toast notification.',
  },
  title: {
    defaultMessage: 'Error loading invoice dashboard',
    id: 'FiH79IK6',
    description:
      'The toast notification title shown when we encounter an error loading the invoice dashboard of an organization.',
  },
})

import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { number, object } from 'yup'
import { AddCreditsMainContentMessages } from '../messages'
import { AddCreditFormValues } from '../models'

export const useAddCreditFormValidation = (): ObjectSchema<AddCreditFormValues> => {
  const intl = useIntl()

  const validationSchema = useMemo(
    () =>
      object({
        credit: number()
          .typeError(intl.formatMessage(AddCreditsMainContentMessages.creditAmountRequiredMessage))
          .required(intl.formatMessage(AddCreditsMainContentMessages.creditAmountRequiredMessage))
          .min(5, intl.formatMessage(AddCreditsMainContentMessages.customAmountMinMessage))
          .max(10000, intl.formatMessage(AddCreditsMainContentMessages.customAmountMaxMessage)),
      }),
    [intl],
  )

  return validationSchema
}

import type { CreateOrganizationWizardState } from '@saladtechnologies/openapi-cloud-portal-browser'
import { type FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import type {
  MyOrganization,
  MyOrganizationInvitation,
  MyOrganizationInvitationsList,
  MyOrganizationsList,
} from '../../../../features/myOrganizations/models'
import { RequestStatus } from '../../../../models'
import { OrganizationsMainContentMessages } from '../../messages'
import { CreateOrganizationWizardCard } from '../CreateOrganizationWizardCard'
import { OrganizationCard } from '../OrganizationCard/OrganizationCard'
import { OrganizationInvitationCard } from '../OrganizationInvitationCard/OrganizationInvitationCard'

interface OrganizationsMainContentProps {
  /** The list of create organization wizards with reserved names. */
  createOrganizationWizardListWithReservedNames: CreateOrganizationWizardState[]
  /** The flag indicating if a request to delete a create organization wizard is pending. */
  isDeleteCreateOrganizationWizardPending: boolean
  /** The flag indicating if a request to delete a create organization wizard is successful. */
  isDeleteCreateOrganizationWizardSuccessful: boolean
  /** The flag indicating if a request to delete an organization is pending. */
  isDeleteOrganizationPending: boolean
  /** The flag indicating if a request to delete an organization is successful. */
  isDeleteOrganizationSuccessful: boolean
  /**
   * The callback executed when the user accepts an invitation.
   *
   * @param invitationId The id of the invitation to accept.
   */
  onAcceptInvitation: (invitationId: string) => void
  /** The callback that starts the create organization wizard flow. */
  onCreateOrganizationWizard: () => void
  /**
   * The callback executed when the user declines an invitation.
   *
   * @param invitationId The id of the invitation to decline
   */
  onDeclineInvitation: (invitationId: string) => void
  /**
   * The callback executed when the user deletes a create organization wizard.
   *
   * @param createOrganizationWizardId The id of the create organization wizard that is being deleted.
   * @param createOrganizationWizardReservedName The reserved name of the create organization wizard that is being
   *   deleted.
   */
  onDeleteCreateOrganizationWizard: (
    createOrganizationWizardId: string,
    createOrganizationWizardReservedName: string,
  ) => void
  /**
   * The callback executed when the user deletes an organization.
   *
   * @param organizationName The name of the organization that is being deleted.
   */
  onDeleteOrganization: (organizationName: string) => void
  /** The list of a user's organizations. */
  organizations: MyOrganizationsList
  /** The list of a user's organization invitations. */
  organizationInvitations: MyOrganizationInvitationsList
}

export const OrganizationsMainContent: FunctionComponent<OrganizationsMainContentProps> = ({
  createOrganizationWizardListWithReservedNames,
  isDeleteCreateOrganizationWizardPending,
  isDeleteCreateOrganizationWizardSuccessful,
  isDeleteOrganizationPending,
  isDeleteOrganizationSuccessful,
  onAcceptInvitation,
  onCreateOrganizationWizard,
  onDeclineInvitation,
  onDeleteCreateOrganizationWizard,
  onDeleteOrganization,
  organizationInvitations,
  organizations,
}) => {
  const intl = useIntl()

  return (
    <>
      <h1 className="mb-3 text-3xl font-bold">{intl.formatMessage(OrganizationsMainContentMessages.title)}</h1>
      <p className="mb-4">{intl.formatMessage(OrganizationsMainContentMessages.description)}</p>
      <div className="flex gap-2">
        <Button variant="green-filled-light" iconClassName="fa-solid fa-plus" onClick={onCreateOrganizationWizard}>
          {intl.formatMessage(OrganizationsMainContentMessages.createOrganizationButtonLabel)}
        </Button>
      </div>
      <div className="mt-8">
        {organizationInvitations.map((invitation: MyOrganizationInvitation) => (
          <OrganizationInvitationCard
            isAcceptInvitationPending={invitation.acceptInvitationRequestStatus === RequestStatus.Loading}
            isDeclineInvitationPending={invitation.declineInvitationRequestStatus === RequestStatus.Loading}
            onAcceptInvitation={() => onAcceptInvitation(invitation.invitation.id)}
            onDeclineInvitation={() => onDeclineInvitation(invitation.invitation.id)}
            organizationInvitation={invitation.invitation}
            key={invitation.invitation.id}
          />
        ))}
        {createOrganizationWizardListWithReservedNames.map((createOrganizationWizard) => (
          <CreateOrganizationWizardCard
            createOrganizationWizardId={createOrganizationWizard.id}
            createOrganizationWizardReservedName={createOrganizationWizard.organizationReservedName as string}
            isDeleteCreateOrganizationWizardPending={isDeleteCreateOrganizationWizardPending}
            isDeleteCreateOrganizationWizardSuccessful={isDeleteCreateOrganizationWizardSuccessful}
            onDeleteCreateOrganizationWizard={() =>
              onDeleteCreateOrganizationWizard(
                createOrganizationWizard.id,
                createOrganizationWizard.organizationReservedName as string,
              )
            }
            key={createOrganizationWizard.id}
          />
        ))}
        {organizations.map((organization: MyOrganization) => (
          <OrganizationCard
            createdDate={organization.createdDate}
            isDeleteOrganizationPending={isDeleteOrganizationPending}
            isDeleteOrganizationSuccessful={isDeleteOrganizationSuccessful}
            organizationDisplayName={organization.displayName}
            onDeleteOrganization={onDeleteOrganization}
            organizationMemberCount={organization.memberCount}
            organizationName={organization.name}
            organizationProjectCount={organization.projectCount}
            switchToOrgPath={organization.switchToOrgPath}
            key={organization.name}
          />
        ))}
      </div>
    </>
  )
}

import { defineMessages } from 'react-intl'

export const CreateInferenceEndpointJobMetadataMaxLengthExceededContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to create your API endpoint job because the metadata you entered exceeded the max length. Please {contact_support} for further assistance.',
    id: '72tNWeLZ',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create an API endpoint job because the metadata exceeded the max length.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'DBiPt1ju',
    description: 'The text shown for the contact support link in the create API endpoint job error toast notification.',
  },
  title: {
    defaultMessage: 'Error creating API endpoint job',
    id: 'Euh8MBQ2',
    description:
      'The toast notification title shown when we encounter an error on their request to create an API endpoint job because the metadata exceeded the max length.',
  },
})

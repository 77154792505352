import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { trackMixpanelElementClickedEvent, trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectProjectDisplayName, selectProjects } from '../../features/projects/projectsSelectors'
import { deleteProject } from '../../features/projects/projectsSlice'
import { selectRequestStatus } from '../../features/requestStatus/requestStatusSelectors'
import { selectProductPageRoutePath } from '../../routes/routePaths'
import { getContainerGroupsPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { SelectProductPage } from './SelectProductPage'

export const ConnectedSelectProductPage: FunctionComponent = () => {
  const { organizationName = '', projectName = '' } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))
  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []
  const currentProjectDisplayName = useAppSelector((state) =>
    selectProjectDisplayName(state, organizationName, projectName),
  )
  const projectSelectOptions = projects.map((project) => {
    return {
      ...project,
      selected: project.name === projectName,
    }
  })
  const deleteProjectRequestStatus = useAppSelector((state) => selectRequestStatus(state, 'deleteProject'))

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const handleProjectChange = useCallback(
    (projectName?: string) => {
      if (projectName) {
        navigate(getContainerGroupsPagePath(organizationName, projectName))
      }
    },
    [navigate, organizationName],
  )

  const handleDeleteProject = useCallback(
    (projectName: string) => {
      dispatch(deleteProject({ organizationName, projectName }))
    },
    [dispatch, organizationName],
  )

  const onRecordMixpanelElementClickedEvent = useCallback(
    (label: string) => {
      dispatch(
        trackMixpanelElementClickedEvent({
          label,
          path: selectProductPageRoutePath,
          organizationName,
          projectName,
        }),
      )
    },
    [dispatch, organizationName, projectName],
  )

  useEffect(() => {
    const handlePopstate = (e: PopStateEvent) => {
      e.preventDefault()
      navigate(`/organizations`)
    }

    window.addEventListener('popstate', handlePopstate)
    return () => {
      setTimeout(() => {
        window.removeEventListener('popstate', handlePopstate)
      }, 0)
    }
  }, [navigate, organizationName])

  useEffect(() => {
    dispatch(trackMixpanelPageViewedEvent({ path: selectProductPageRoutePath, organizationName, projectName }))
  }, [dispatch, organizationName, projectName])

  return (
    <SelectProductPage
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      currentProject={{ name: projectName, displayName: currentProjectDisplayName ?? projectName }}
      isDeleteProjectPending={deleteProjectRequestStatus === 'pending'}
      isDeleteProjectSuccessful={deleteProjectRequestStatus === 'succeeded'}
      isLeftColumnOpen={isLeftColumnOpen}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onDeleteProject={handleDeleteProject}
      onProjectChange={handleProjectChange}
      onRecordMixpanelElementClickedEvent={onRecordMixpanelElementClickedEvent}
      projects={projectSelectOptions}
    />
  )
}

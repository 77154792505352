import { defineMessages } from 'react-intl'

export const ManualCreditRequestInvoiceFailedContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to process your request to add more credit to your organization at this time. Please try again. If this issue persists {contact_support}.',
    id: '8bWiEY07',
    description:
      'The description shown in a toast notification message shown when we encounter an error confirming the credit request amount manually.',
  },
  contactSupport: {
    defaultMessage: 'contact support',
    id: 'CeKFt2Ff',
    description:
      'The text shown for the contact support link in the failed to confirm manual credit request amount error toast notification.',
  },
  title: {
    defaultMessage: 'Error processing request to add credit',
    id: 'DxBE8YeH',
    description:
      'The toast notification title shown when we encounter an error confirming the credit request amount manually.',
  },
})

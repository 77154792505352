import { catchError, concat, delay, filter, from, of, switchMap } from 'rxjs'
import { BillingProfileAPI } from '../apiMethods'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import type { AppEpic } from '../store'
import { billingProfileAdded, getBillingProfile } from '../features/billingProfile/billingProfileSlice'

export const onGetBillingProfile: AppEpic = (action$, _state$) =>
  action$.pipe(
    filter(getBillingProfile.match),
    switchMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'getBillingProfile', status: 'pending' })),
        from(BillingProfileAPI.getBillingProfile({ organizationName })).pipe(
          switchMap((billingProfileResponse) => {
            return concat(
              of(
                billingProfileAdded({ organizationName, billingProfile: billingProfileResponse }),
                setRequestStatus({ request: 'getBillingProfile', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getBillingProfile', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError((_error) => {
            return concat(
              of(setRequestStatus({ request: 'getBillingProfile', status: 'failed' })),
              of(setRequestStatus({ request: 'getBillingProfile', status: 'idle' })).pipe(delay(1)),
            )
          }),
        ),
      ),
    ),
  )

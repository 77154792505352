import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelElementClickedEvent, trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { showStripeFormError } from '../../features/billing/billingSlice'
import {
  selectBillingCreditsDashboardEmbedUrl,
  selectBillingInvoiceDashboardEmbedUrl,
  selectBillingUsageDashboardEmbedUrl,
} from '../../features/billingDashboards/billingDashboardsSelectors'
import { selectBillingProfile } from '../../features/billingProfile/billingProfileSelectors'
import {
  createBillingProfileSetupIntents,
  disableAutoRechargeSettings,
  getBillingCustomerPortalNew,
  getNewBillingPageData,
  pollBillingProfileStateAfterRequestToAddPaymentMethod,
  removePaymentMethodNew,
  stopPollBillingProfileStateAfterRequestToAddPaymentMethod,
} from '../../features/billingProfile/billingProfileSlice'
import { selectBillingProfileCreditsBalanceAmount } from '../../features/billingProfileCreditsBalance/billingProfileCreditsBalanceSelectors'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectProjects } from '../../features/projects/projectsSelectors'
import {
  selectRequestStatusIsPending,
  selectRequestStatusIsSucceeded,
} from '../../features/requestStatus/requestStatusSelectors'
import { selectStripeSetupIntentClientSecret } from '../../features/stripeSetupIntent/stripeSetupIntentSelector'
import { clearStripeSetupIntent } from '../../features/stripeSetupIntent/stripeSetupIntentSlice'
import { organizationBillingPageRoutePath } from '../../routes/routePaths'
import {
  getBillingAutoRechargePagePath,
  getContainerGroupsPagePath,
  getManualCreditRequestAddCreditPage,
  getOrganizationBillingPagePath,
} from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { BillingPage } from './BillingPage'

export const ConnectedBillingPageNew: FunctionComponent = () => {
  const { organizationName = '' } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))
  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []
  const billingCreditsEmbedUrl = useAppSelector((state) =>
    selectBillingCreditsDashboardEmbedUrl(state, organizationName),
  )
  const billingInvoiceEmbedUrl = useAppSelector((state) =>
    selectBillingInvoiceDashboardEmbedUrl(state, organizationName),
  )
  const billingUsageEmbedUrl = useAppSelector((state) => selectBillingUsageDashboardEmbedUrl(state, organizationName))
  const billingProfile = useAppSelector((state) => selectBillingProfile(state, organizationName))
  const billingProfileClientSecret = useAppSelector((state) => selectStripeSetupIntentClientSecret(state))
  const billingProfileCreditsBalance = useAppSelector((state) =>
    selectBillingProfileCreditsBalanceAmount(state, organizationName),
  )
  const isCreateBillingProfileSetupIntentsPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'createBillingProfileSetupIntents'),
  )
  const isLoadBillingCustomerPortalPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'getBillingCustomerPortalNew'),
  )
  const isRemovePaymentMethodPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'removePaymentMethodNew'),
  )
  const isRemovePaymentMethodRequestStatusSucceeded = useAppSelector((state) =>
    selectRequestStatusIsSucceeded(state, 'removePaymentMethodNew'),
  )
  const isGetPaymentMethodSetupIntentStatusPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'pollBillingProfileStateAfterRequestToAddPaymentMethod'),
  )
  const isGetBillingPageDataStatusPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'getNewBillingPageData'),
  )
  const isEditAutoRechargeSettingsPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'editAutoRechargeSettings'),
  )
  const isDisableAutoRechargeOptOutSuccessful = useAppSelector((state) =>
    selectRequestStatusIsSucceeded(state, 'disableAutoRechargeSettings'),
  )
  const isDisableAutoRechargeOptOutPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'disableAutoRechargeSettings'),
  )

  const editAutomaticRechargeSettingsPath = getBillingAutoRechargePagePath(organizationName)
  const manualCreditRequestAddCreditPath = getManualCreditRequestAddCreditPage(organizationName)

  const projectSelectOptions = projects.map((project) => {
    return {
      ...project,
      selected: false,
    }
  })

  const handleCancelAddPaymentMethod = useCallback(() => {
    dispatch(stopPollBillingProfileStateAfterRequestToAddPaymentMethod())
    dispatch(clearStripeSetupIntent())
  }, [dispatch])

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const handleProjectChange = useCallback(
    (projectName: string) => {
      navigate(getContainerGroupsPagePath(organizationName, projectName))
    },
    [navigate, organizationName],
  )

  const handleCreateBillingProfileSetupIntents = useCallback(() => {
    dispatch(createBillingProfileSetupIntents({ organizationName }))
  }, [dispatch, organizationName])

  const handleGetBillingCustomerPortal = useCallback(() => {
    dispatch(
      getBillingCustomerPortalNew({ organizationName, redirectPath: getOrganizationBillingPagePath(organizationName) }),
    )
  }, [dispatch, organizationName])

  const handleRemovePaymentMethod = useCallback(() => {
    dispatch(removePaymentMethodNew({ organizationName }))
  }, [dispatch, organizationName])

  const handleSubmitBillingFormSucceeded = useCallback(() => {
    dispatch(pollBillingProfileStateAfterRequestToAddPaymentMethod({ organizationName }))
  }, [dispatch, organizationName])

  const handleShowStripeFormError = useCallback(
    (message?: string) => {
      dispatch(showStripeFormError({ message }))
    },
    [dispatch],
  )

  const handleNavigateToBillingAutoRechargePage = useCallback(() => {
    navigate(getBillingAutoRechargePagePath(organizationName))
  }, [navigate, organizationName])

  const handleConfirmAutoRechargeOptOut = useCallback(() => {
    dispatch(
      disableAutoRechargeSettings({
        organizationName,
      }),
    )
  }, [dispatch, organizationName])

  const onRecordMixpanelElementClickedEvent = useCallback(
    (label: string) => {
      dispatch(
        trackMixpanelElementClickedEvent({
          label,
          path: organizationBillingPageRoutePath,
          organizationName,
        }),
      )
    },
    [dispatch, organizationName],
  )

  useEffect(() => {
    dispatch(getNewBillingPageData({ organizationName }))

    return () => {
      dispatch(stopPollBillingProfileStateAfterRequestToAddPaymentMethod())
      dispatch(clearStripeSetupIntent())
    }
  }, [dispatch, organizationName])

  useEffect(() => {
    dispatch(trackMixpanelPageViewedEvent({ path: organizationBillingPageRoutePath, organizationName }))
  }, [dispatch, organizationName])

  return isGetBillingPageDataStatusPending ? (
    <LoadingPage />
  ) : (
    <BillingPage
      automaticRechargeSettings={billingProfile?.automaticRechargeSettings}
      billingCreditsEmbedUrl={billingCreditsEmbedUrl}
      billingInvoiceEmbedUrl={billingInvoiceEmbedUrl}
      billingUsageEmbedUrl={billingUsageEmbedUrl}
      creditAmount={billingProfileCreditsBalance}
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      editAutomaticRechargeSettingsPath={editAutomaticRechargeSettingsPath}
      isCreateBillingProfileSetupIntentsPending={isCreateBillingProfileSetupIntentsPending}
      isDisableAutoRechargeOptOutPending={isDisableAutoRechargeOptOutPending}
      isDisableAutoRechargeOptOutSuccessful={isDisableAutoRechargeOptOutSuccessful}
      isEditAutoRechargeSettingsPending={isEditAutoRechargeSettingsPending}
      isLeftColumnOpen={isLeftColumnOpen}
      isLoadBillingCustomerPortalPending={isLoadBillingCustomerPortalPending}
      isPaymentMethodVerificationInProgress={isGetPaymentMethodSetupIntentStatusPending}
      isRemovePaymentMethodPending={isRemovePaymentMethodPending}
      isRemovePaymentMethodSuccessful={isRemovePaymentMethodRequestStatusSucceeded}
      manualCreditRequestAddCreditPath={manualCreditRequestAddCreditPath}
      onCancelAddPaymentMethod={handleCancelAddPaymentMethod}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onConfirmAutoRechargeOptOut={handleConfirmAutoRechargeOptOut}
      onCreateBillingProfileSetupIntents={handleCreateBillingProfileSetupIntents}
      onManageBilling={handleGetBillingCustomerPortal}
      onNavigateToBillingAutoRechargePage={handleNavigateToBillingAutoRechargePage}
      onProjectChange={handleProjectChange}
      onRecordMixpanelElementClickedEvent={onRecordMixpanelElementClickedEvent}
      onRemovePaymentMethod={handleRemovePaymentMethod}
      onSubmitFormError={handleShowStripeFormError}
      onSubmitFormSucceeded={handleSubmitBillingFormSucceeded}
      paymentMethod={billingProfile?.paymentMethod}
      paymentMethodBillingClientSecret={billingProfileClientSecret}
      projects={projectSelectOptions}
    />
  )
}

import { filter, mergeMap, of } from 'rxjs'
import { showStripeFormError } from '../features/billing/billingSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { getGenericStripeFormError } from '../notifications/clientToastNotificationContent/billing'
import { AppEpic } from '../store'

export const onSubmitStripeFormError: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(showStripeFormError.match),
    mergeMap((action) => {
      const { message } = action.payload
      return of(showToastNotification(getGenericStripeFormError(intl, message)))
    }),
  )

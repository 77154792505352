import { BillingProfileState } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { EditAutoRechargeSettingsMainContent } from './components/EditAutoRechargeSettingsMainContent'
import { EditAutoRechargeSettingsFormValues } from './models/EditAutoRechargeSettingsFormValues'

export interface EditAutoRechargeSettingsPageProps {
  /* the users billing profile */
  billingProfile: BillingProfileState
  /** The organization's credit balance in cents. */
  creditBalance: number
  /** A flag indicating that the request to edit auto recharge settings is pending. */
  isEditAutoRechargeSettingsPending: boolean
  /** A callback to handle navigation (Back) */
  onBack: () => void
  /** A callback that when edit auto recharge settings is executed */
  onEditAutoRechargeSettings: (values: EditAutoRechargeSettingsFormValues) => void
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
}

export const EditAutoRechargeSettingsPage: FunctionComponent<EditAutoRechargeSettingsPageProps> = ({
  billingProfile,
  creditBalance,
  isEditAutoRechargeSettingsPending,
  isLeftColumnOpen,
  onBack,
  onCloseLeftDrawer,
  onEditAutoRechargeSettings,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={<LeftNavigationBar />}
      MainColumn={
        <EditAutoRechargeSettingsMainContent
          billingProfile={billingProfile}
          creditBalance={creditBalance}
          isEditAutoRechargeSettingsPending={isEditAutoRechargeSettingsPending}
          onBack={onBack}
          onEditAutoRechargeSettings={onEditAutoRechargeSettings}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}

import { defineMessages } from 'react-intl'

export const ManualCreditRequestProgressBarMessages = defineMessages({
  addToCreditBalance: {
    id: 'SuAgVs1U',
    defaultMessage: 'Add to Credit Balance',
    description: "The title of the manual credit request's add to credit balance page",
  },
  paymentSummary: {
    id: '+Qlf9iKT',
    defaultMessage: 'Payment Summary',
    description: "The title of the manual credit request's payment summary page",
  },
})

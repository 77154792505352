import { defineMessages } from 'react-intl'

export const EditAutoRechargeWarningModalMessages = defineMessages({
  backButtonLabel: {
    defaultMessage: 'Back',
    id: 'faJYB4DJ',
    description: 'The label for the back button.',
  },
  confirmButtonLabel: {
    defaultMessage: 'Confirm Payment',
    id: '3phleS9B',
    description: 'The label for the confirm button.',
  },
  contactSupportLink: {
    defaultMessage: 'contact SaladCloud support',
    id: '8xwQPEk6',
    description: 'Link to contact support.',
  },
  description: {
    defaultMessage: `By confirming these auto-recharge settings, if your organization has a negative balance or if your credit balance falls below the new threshold you've established, your card will automatically be recharged. This charge will cover any negative balance your organization has, in addition to the top-up amount you specified. If you have questions about the amount that will be billed, please {contact_support}.`,
    id: 'wbk5xHTe',
    description: 'The description for the warning modal.',
  },
  title: {
    defaultMessage: 'Warning: You Might Get Charged',
    id: '25cFlCMM',
    description: 'Title for the warning modal.',
  },
})

import type { FunctionComponent } from 'react'
import { ManualCreditRequestProgressBar } from '../../components/credits/ManualCreditRequestProgressBar'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { AddCreditMainContent } from './components/AddCreditMainContent'
import { AddCreditFormValues } from './models'

export interface ManualCreditRequestAddCreditPageProps {
  /** The current credit balance for the organization in cents. */
  currentCreditBalance: number
  /** The current credit request amount in cents. */
  currentCreditRequestAmount?: number
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The flag indicating that the request to add credit to the manual credit request workflow is pending. */
  isAddCreditRequestPending: boolean
  /** A callback that when executed when setting the requested credit to be added to the manual credit request workflow */
  onAddCredit: (values: AddCreditFormValues) => void
  /** A callback to handle navigation (Back) */
  onBack: () => void
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
}

export const ManualCreditRequestAddCreditPage: FunctionComponent<ManualCreditRequestAddCreditPageProps> = ({
  currentCreditBalance,
  currentCreditRequestAmount,
  isAddCreditRequestPending,
  isLeftColumnOpen,
  onAddCredit,
  onBack,
  onCloseLeftDrawer,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={<ManualCreditRequestProgressBar currentPage="add-credit" />}
      MainColumn={
        <AddCreditMainContent
          currentCreditBalance={currentCreditBalance}
          currentCreditRequestAmount={currentCreditRequestAmount}
          isPending={isAddCreditRequestPending}
          onAddCredit={onAddCredit}
          onBack={onBack}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}

import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { Tag } from '../../Tag'
import { Link } from '../../base'

interface ErrorPillProps {
  /** The label for the error pill. This is the text that will be displayed in the error pill. */
  label: string
  /** The URL to navigate to when the user clicks the error pill. If not provided, the error pill will not be a link. */
  url?: string
}

export const ErrorPill: FunctionComponent<ErrorPillProps> = ({ label, url }) => {
  const tagJSX = (
    <Tag color="red">
      <i className={classNames('fa-solid fa-triangle-exclamation mr-2 whitespace-pre')} />
      <span className="whitespace-pre uppercase">{label}</span>
    </Tag>
  )

  return url ? <Link url={url}>{tagJSX}</Link> : tagJSX
}

import { defineMessages } from 'react-intl'

export const GetSystemEventLogsContainerGroupNotFoundErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to retrieve this container group, was it deleted? If this was unexpected, please {contact_support}.',
    id: 'eaJEYTJT',
    description:
      'The description shown in a toast notification message shown when we are unable to find the container group.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: '54OfIsZC',
    description: 'The text shown for the contact support link in the create container group error toast notification.',
  },
  title: {
    defaultMessage: 'Container group logs not found',
    id: 'HU8DVNXA',
    description:
      'The toast notification title shown when we encounter an error and are unable to find the container group.',
  },
})

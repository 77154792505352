import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type StripeSetupIntent = {
  setupIntentId?: string
  clientSecret?: string
}

const initialState: StripeSetupIntent = {}

export const stripeSetupIntentSlice = createSlice({
  name: 'stripeSetupIntent',
  initialState,
  reducers: {
    stripeSetupIntentAdded: (_state, action: PayloadAction<StripeSetupIntent>) => {
      return action.payload
    },
    clearStripeSetupIntent: () => initialState,
    stopPollingForStripeSetupIntent(_state, _action: PayloadAction<{ createOrganizationWizardId: string }>) {},
  },
})

export const { clearStripeSetupIntent, stopPollingForStripeSetupIntent, stripeSetupIntentAdded } =
  stripeSetupIntentSlice.actions

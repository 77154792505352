import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { VerticalProgressBar } from '../../../components/block/VerticalProgressBar'
import { ManualCreditRequestProgressBarMessages } from './messages'

interface ManualCreditRequestProgressBarProps {
  /** The current page for the manual credit request workflow. */
  currentPage: 'add-credit' | 'payment-summary'
}

export const ManualCreditRequestProgressBar: FunctionComponent<ManualCreditRequestProgressBarProps> = ({
  currentPage,
}) => {
  const intl = useIntl()

  return (
    <div className="h-24 p-4">
      <VerticalProgressBar
        steps={[
          {
            label: intl.formatMessage(ManualCreditRequestProgressBarMessages.addToCreditBalance),
            status: currentPage === 'add-credit' ? 'current' : 'completed',
          },
          {
            label: intl.formatMessage(ManualCreditRequestProgressBarMessages.paymentSummary),
            status: currentPage === 'payment-summary' ? 'current' : 'completed',
          },
        ]}
      />
    </div>
  )
}

import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelEvent } from '../../features/analytics/analyticsSlice'
import { selectBillingProfile } from '../../features/billingProfile/billingProfileSelectors'
import {
  getManualCreditRequestAddCreditPageData,
  updateCreditRequestAmount,
} from '../../features/billingProfile/billingProfileSlice'
import { selectBillingProfileCreditsBalanceAmount } from '../../features/billingProfileCreditsBalance/billingProfileCreditsBalanceSelectors'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectRequestStatus } from '../../features/requestStatus/requestStatusSelectors'
import { ManuallyAddCreditWizardPath } from '../../routes/routePaths'
import { getOrganizationBillingPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { ManualCreditRequestAddCreditPage } from './ManualCreditRequestAddCreditPage'
import { AddCreditFormValues } from './models'

export const ConnectedManualCreditRequestAddCreditPage: FunctionComponent = () => {
  const { organizationName = '' } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const isGetPageDataLoading = useAppSelector(
    (state) => selectRequestStatus(state, 'getManualCreditRequestAddCreditPageData') === 'pending',
  )
  const isUpdateCreditRequestAmountLoading = useAppSelector(
    (state) => selectRequestStatus(state, 'updateCreditRequestAmount') === 'pending',
  )
  const billingProfile = useAppSelector((state) => selectBillingProfile(state, organizationName))
  const billingProfileCreditsBalance = useAppSelector((state) =>
    selectBillingProfileCreditsBalanceAmount(state, organizationName),
  )

  const handleUpdateCreditRequestAmount = useCallback(
    (values: AddCreditFormValues) => {
      dispatch(
        // The backend accepts the values in cents, so we need to multiply this by 100
        updateCreditRequestAmount({
          organizationName,
          creditRequest: values.credit * 100,
        }),
      )
    },
    [dispatch, organizationName],
  )

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const onBack = useCallback(() => {
    navigate(getOrganizationBillingPagePath(organizationName))
  }, [navigate, organizationName])

  useEffect(() => {
    dispatch(getManualCreditRequestAddCreditPageData({ organizationName }))
    dispatch(
      trackMixpanelEvent({
        event: 'Page Viewed',
        properties: { path: ManuallyAddCreditWizardPath.AddCredits },
      }),
    )
  }, [dispatch, organizationName])

  return isGetPageDataLoading ? (
    <LoadingPage />
  ) : (
    <ManualCreditRequestAddCreditPage
      currentCreditBalance={billingProfileCreditsBalance}
      currentCreditRequestAmount={billingProfile?.addCreditsAmount}
      onAddCredit={handleUpdateCreditRequestAmount}
      onBack={onBack}
      isAddCreditRequestPending={isUpdateCreditRequestAmountLoading}
      isLeftColumnOpen={isLeftColumnOpen}
      onCloseLeftDrawer={handleCloseLeftNavBar}
    />
  )
}

import {
  CreateOrganizationProblemType,
  CreateOrganizationWizardState,
  CreateOrganizationWizardStateStateEnum,
  OrganizationNameAvailabilityProblemType,
  ResponseError,
  UpdateCreateOrganizationWizardProblemType,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import {
  catchError,
  concat,
  delay,
  distinctUntilChanged,
  EMPTY,
  filter,
  from,
  mergeMap,
  of,
  repeat,
  switchMap,
  takeUntil,
  timer,
} from 'rxjs'
import { assertUnreachable, CreateOrganizationWizardAPI, OrganizationNameAvailabilityAPI } from '../apiMethods'
import {
  checkOrganizationNameAvailability,
  confirmPaymentSummary,
  createCreateOrganizationWizard,
  createOrganizationWizardAdded,
  createOrganizationWizardRemoved,
  deleteCreateOrganizationWizard,
  editCreateOrganizationWizard,
  getAddPaymentMethodPageData,
  getAddPaymentMethodSetupStatus,
  getCreateOrganizationWizard,
  stopPollingForCreateOrganizationWizardSubmission,
} from '../features/createOrganizationWizard/createOrganizationWizardSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setIsOrganizationNameAvailable } from '../features/organizationInformation/organizationInformation'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  stopPollingForStripeSetupIntent,
  stripeSetupIntentAdded,
} from '../features/stripeSetupIntent/stripeSetupIntentSlice'
import { getAddNewPaymentMethodGenericValidationError } from '../notifications/clientToastNotificationContent/billing'
import {
  getCreateOrganizationWizardConfigureCreditSettingsInvalidAutomaticRechargeAmountErrorContent,
  getCreateOrganizationWizardConfigureCreditSettingsInvalidAutomaticRechargeThresholdErrorContent,
  getCreateOrganizationWizardConfigureCreditSettingsInvalidInitialCreditErrorContent,
  getCreateOrganizationWizardCreateOrganizationGeneralErrorContent,
  getCreateOrganizationWizardDeleteGeneralErrorContent,
  getCreateOrganizationWizardDeleteSuccessContent,
  getCreateOrganizationWizardOrganizationNameAvailabilityGenericErrorContent,
  getCreateOrganizationWizardOrganizationNonUniqueNameContent,
  getCreateOrganizationWizardPageLoadGeneralErrorContent,
  getCreateOrganizationWizardPaymentSummaryGeneralErrorContent,
  getUnableToContinueInCreateOrganizationWizardGenericErrorContent,
} from '../notifications/clientToastNotificationContent/createOrganizationWizard'
import { getCreateOrganizationWizardGeneralErrorContent } from '../notifications/clientToastNotificationContent/createOrganizationWizard/getCreateOrganizationWizardGeneralErrorContent'
import {
  getCreateOrganizationGeneralErrorContent,
  getCreateOrganizationWithAlreadyUsedReferralCodeContent,
  getCreateOrganizationWithInvalidReferralCodeContent,
  getCreateOrganizationWithNonUniqueNameContent,
} from '../notifications/clientToastNotificationContent/organizations'
import { CreateOrganizationWizardPageSteps } from '../pages/CreateOrganizationWizard/models'
import { convertKeysToCamelCase } from '../pages/CreateOrganizationWizard/utils'
import { stripeConfirmCardPayment } from '../pages/CreateOrganizationWizard/utils/stripe'
import {
  getCreateOrganizationWizardAddPaymentMethodPagePath,
  getCreateOrganizationWizardConfigureCreditSettingsPagePath,
  getCreateOrganizationWizardInviteTeamMembersPagePath,
  getCreateOrganizationWizardOrganizationInformationPagePath,
  getCreateOrganizationWizardPaymentMethodSummaryPagePath,
} from '../routes/routes-utils'
import { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onCheckOrganizationNameAvailability: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(checkOrganizationNameAvailability.match),
    mergeMap(({ payload: { organizationName, turnstileSuccessToken } }) =>
      concat(
        of(setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'pending' })),
        from(
          OrganizationNameAvailabilityAPI.organizationNameAvailability({
            checkOrganizationNameAvailability: { name: organizationName, turnstileToken: turnstileSuccessToken },
          }),
        ).pipe(
          mergeMap(() =>
            concat(
              of(
                setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'succeeded' }),
                setIsOrganizationNameAvailable({ isOrganizationNameAvailable: true }),
              ),
              of(setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError((error: unknown) =>
            error instanceof ResponseError && error.response.status === 409
              ? concat(
                  of(
                    setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'failed' }),
                    setIsOrganizationNameAvailable({ isOrganizationNameAvailable: false }),
                  ),
                  of(setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'idle' })).pipe(delay(1)),
                )
              : error instanceof ResponseError && error.response.status === 400
                ? from(error.response.json()).pipe(
                    mergeMap((errorResponse) => {
                      const errorType = errorResponse.type as OrganizationNameAvailabilityProblemType | null

                      if (errorType != null) {
                        switch (errorType) {
                          case OrganizationNameAvailabilityProblemType.InvalidTurnstileToken:
                            return concat(
                              of(setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'failed' })),
                              of(
                                setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'idle' }),
                              ).pipe(delay(1)),
                              of(
                                showToastNotification(
                                  getCreateOrganizationWizardOrganizationNameAvailabilityGenericErrorContent(intl),
                                ),
                              ),
                            )
                          default:
                            return concat(
                              of(setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'failed' })),
                              of(
                                setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'idle' }),
                              ).pipe(delay(1)),
                            )
                        }
                      }

                      return concat(
                        of(setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'failed' })),
                        of(setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'idle' })).pipe(
                          delay(1),
                        ),
                        of(
                          showToastNotification(
                            getCreateOrganizationWizardOrganizationNameAvailabilityGenericErrorContent(intl),
                          ),
                        ),
                      )
                    }),
                  )
                : concat(
                    of(setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'failed' })),
                    of(setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'idle' })).pipe(
                      delay(1),
                    ),
                    of(
                      showToastNotification(
                        getCreateOrganizationWizardOrganizationNameAvailabilityGenericErrorContent(intl),
                      ),
                    ),
                  ),
          ),
        ),
      ),
    ),
  )

export const onCreateOrganizationWizard: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(createCreateOrganizationWizard.match),
    mergeMap(() => {
      return concat(
        of(
          setRequestStatus({
            request: 'createOrganizationWizard',
            status: 'pending',
          }),
        ),
        from(CreateOrganizationWizardAPI.createOrganizationWizard()).pipe(
          mergeMap((createOrganizationWizard) =>
            concat(
              of(
                createOrganizationWizardAdded(createOrganizationWizard),
                setRequestStatus({
                  request: 'createOrganizationWizard',
                  status: 'succeeded',
                }),
                navigateTo({
                  path: getCreateOrganizationWizardOrganizationInformationPagePath(createOrganizationWizard.id),
                }),
              ),
              of(
                setRequestStatus({
                  request: 'createOrganizationWizard',
                  status: 'idle',
                }),
              ).pipe(delay(1)),
            ).pipe(
              catchError(() => {
                return concat(
                  of(
                    setRequestStatus({
                      request: 'createOrganizationWizard',
                      status: 'failed',
                    }),
                    showToastNotification(getCreateOrganizationWizardCreateOrganizationGeneralErrorContent(intl)),
                  ),
                  of(
                    setRequestStatus({
                      request: 'createOrganizationWizard',
                      status: 'idle',
                    }),
                  ).pipe(delay(1)),
                )
              }),
            ),
          ),
        ),
      )
    }),
  )

export const onDeleteCreateOrganizationWizard: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(deleteCreateOrganizationWizard.match),
    mergeMap(({ payload: { createOrganizationWizardId, createOrganizationWizardReservedName } }) =>
      concat(
        of(setRequestStatus({ request: 'deleteCreateOrganizationWizard', status: 'pending' })),
        from(
          CreateOrganizationWizardAPI.deleteOrganizationWizard({
            createOrganizationWizardId,
          }),
        ).pipe(
          mergeMap(() => {
            return concat(
              of(
                createOrganizationWizardRemoved(createOrganizationWizardId),
                setRequestStatus({ request: 'deleteCreateOrganizationWizard', status: 'succeeded' }),
                showToastNotification(
                  getCreateOrganizationWizardDeleteSuccessContent(intl, createOrganizationWizardReservedName),
                ),
              ),
              of(setRequestStatus({ request: 'deleteCreateOrganizationWizard', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError((_error: ResponseError) => {
            return concat(
              of(
                setRequestStatus({ request: 'deleteCreateOrganizationWizard', status: 'failed' }),
                showToastNotification(getCreateOrganizationWizardDeleteGeneralErrorContent(intl)),
              ),
              of(setRequestStatus({ request: 'deleteCreateOrganizationWizard', status: 'idle' })).pipe(delay(1)),
            )
          }),
        ),
      ),
    ),
  )

export const onEditCreateOrganizationWizard: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(editCreateOrganizationWizard.match),
    mergeMap(({ payload: { createOrganizationWizard, currentRoute } }) =>
      concat(
        of(
          setRequestStatus({
            request: 'editCreateOrganizationWizard',
            status: 'pending',
          }),
        ),
        from(
          CreateOrganizationWizardAPI.updateOrganizationWizard({
            createOrganizationWizardId: createOrganizationWizard.id,
            updateCreateOrganizationWizard: createOrganizationWizard,
          }),
        ).pipe(
          mergeMap(() => {
            switch (currentRoute) {
              case CreateOrganizationWizardPageSteps.OrganizationInformation:
                return concat(
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'succeeded',
                    }),
                    navigateTo({
                      path: getCreateOrganizationWizardConfigureCreditSettingsPagePath(createOrganizationWizard.id),
                    }),
                  ),
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'idle',
                    }),
                  ).pipe(delay(1)),
                )
              case CreateOrganizationWizardPageSteps.ConfigureCreditSettings:
                return concat(
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'succeeded',
                    }),
                    navigateTo({
                      path: getCreateOrganizationWizardAddPaymentMethodPagePath(createOrganizationWizard.id),
                    }),
                  ),
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'idle',
                    }),
                  ).pipe(delay(1)),
                )

              case CreateOrganizationWizardPageSteps.PaymentSummary:
                return concat(
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'succeeded',
                    }),
                    navigateTo({
                      path: getCreateOrganizationWizardInviteTeamMembersPagePath(createOrganizationWizard.id),
                    }),
                  ),
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'idle',
                    }),
                  ).pipe(delay(1)),
                )
              case CreateOrganizationWizardPageSteps.AddPaymentMethod:
                return concat(
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'succeeded',
                    }),
                    navigateTo({
                      path:
                        createOrganizationWizard.initialCredit === 0
                          ? getCreateOrganizationWizardInviteTeamMembersPagePath(createOrganizationWizard.id)
                          : getCreateOrganizationWizardPaymentMethodSummaryPagePath(createOrganizationWizard.id),
                    }),
                  ),
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'idle',
                    }),
                  ).pipe(delay(1)),
                )
              default:
                return concat(
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'succeeded',
                    }),
                  ),
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'idle',
                    }),
                  ).pipe(delay(1)),
                )
            }
          }),
          catchError((error: unknown) => {
            return error instanceof ResponseError
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as UpdateCreateOrganizationWizardProblemType | null
                    if (errorType != null) {
                      switch (errorType) {
                        case UpdateCreateOrganizationWizardProblemType.CreateOrganizationInvalidAutomaticRechargeThresholdError:
                          return concat(
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(
                              showToastNotification(
                                getCreateOrganizationWizardConfigureCreditSettingsInvalidAutomaticRechargeThresholdErrorContent(
                                  intl,
                                ),
                              ),
                            ),
                          )
                        case UpdateCreateOrganizationWizardProblemType.CreateOrganizationInvalidInitialCreditError:
                          return concat(
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(
                              showToastNotification(
                                getCreateOrganizationWizardConfigureCreditSettingsInvalidInitialCreditErrorContent(
                                  intl,
                                ),
                              ),
                            ),
                          )
                        case UpdateCreateOrganizationWizardProblemType.CreateOrganizationWizardInvalidAutomaticRechargeAmount:
                          return concat(
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(
                              showToastNotification(
                                getCreateOrganizationWizardConfigureCreditSettingsInvalidAutomaticRechargeAmountErrorContent(
                                  intl,
                                ),
                              ),
                            ),
                          )
                        case UpdateCreateOrganizationWizardProblemType.OrganizationNameAlreadyReserved:
                          return concat(
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(
                              showToastNotification(getCreateOrganizationWizardOrganizationNonUniqueNameContent(intl)),
                            ),
                          )
                        case UpdateCreateOrganizationWizardProblemType.UnexpectedError:
                          return concat(
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(
                              showToastNotification(
                                getUnableToContinueInCreateOrganizationWizardGenericErrorContent(intl),
                              ),
                            ),
                          )
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                        of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(
                          delay(1),
                        ),
                        of(
                          showToastNotification(getUnableToContinueInCreateOrganizationWizardGenericErrorContent(intl)),
                        ),
                      )
                    }
                  }),
                )
              : concat(
                  of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                  of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(delay(1)),
                  of(showToastNotification(getUnableToContinueInCreateOrganizationWizardGenericErrorContent(intl))),
                )
          }),
        ),
      ),
    ),
  )

export const onGetCreateOrganizationWizard: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getCreateOrganizationWizard.match),
    mergeMap(({ payload: { createOrganizationWizardId } }) =>
      concat(
        of(setRequestStatus({ request: 'getCreateOrganizationWizard', status: 'pending' })),
        from(CreateOrganizationWizardAPI.getOrganizationWizard({ createOrganizationWizardId })).pipe(
          mergeMap((createOrganizationWizard) => {
            const formattedCreateOrganizationWizard = convertKeysToCamelCase(
              createOrganizationWizard,
            ) as CreateOrganizationWizardState

            return concat(
              of(
                createOrganizationWizardAdded(formattedCreateOrganizationWizard),
                setRequestStatus({ request: 'getCreateOrganizationWizard', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getCreateOrganizationWizard', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'getCreateOrganizationWizard', status: 'failed' })),
              of(setRequestStatus({ request: 'getCreateOrganizationWizard', status: 'idle' })).pipe(delay(1)),
              of(showToastNotification(getCreateOrganizationWizardGeneralErrorContent(intl))),
            ),
          ),
        ),
      ),
    ),
  )

export const onConfirmPaymentSummary: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(confirmPaymentSummary.match),
    mergeMap(({ payload: { createOrganizationWizardId, organizationReservedName } }) => {
      let isStripeCardPaymentConfirmed = false

      return concat(
        of(setRequestStatus({ request: 'confirmPaymentSummary', status: 'pending' })),
        /* By sending an empty object to the updateOrganizationWizard method, we are signaling to the backend that the user has confirmed the payment summary.
          After we need to poll the backend to check if the organization creation has been completed */
        from(
          CreateOrganizationWizardAPI.updateOrganizationWizard({
            createOrganizationWizardId,
            updateCreateOrganizationWizard: {},
          }),
        ).pipe(
          mergeMap(() =>
            timer(0, 1000).pipe(
              mergeMap(() =>
                from(CreateOrganizationWizardAPI.getOrganizationWizard({ createOrganizationWizardId })).pipe(
                  distinctUntilChanged((prev, curr) => prev.state === curr.state),
                  mergeMap((createOrganizationWizard) => {
                    const { paymentMethodRequiredActionDetails, state: updatedState } = createOrganizationWizard

                    if (updatedState === CreateOrganizationWizardStateStateEnum.Completed) {
                      return concat(
                        of(
                          setRequestStatus({ request: 'confirmPaymentSummary', status: 'succeeded' }),
                          navigateTo({
                            path: getCreateOrganizationWizardInviteTeamMembersPagePath(createOrganizationWizardId),
                          }),
                        ),
                        of(setRequestStatus({ request: 'confirmPaymentSummary', status: 'idle' })).pipe(delay(1)),
                        of(stopPollingForCreateOrganizationWizardSubmission({ createOrganizationWizardId })),
                      )
                    }

                    if (updatedState === CreateOrganizationWizardStateStateEnum.SetupIntentFailed) {
                      return concat(
                        of(
                          setRequestStatus({ request: 'confirmPaymentSummary', status: 'failed' }),
                          showToastNotification(getCreateOrganizationWizardPaymentSummaryGeneralErrorContent(intl)),
                        ),
                        of(setRequestStatus({ request: 'confirmPaymentSummary', status: 'idle' })).pipe(delay(1)),
                        of(stopPollingForCreateOrganizationWizardSubmission({ createOrganizationWizardId })),
                      )
                    }

                    if (
                      updatedState === CreateOrganizationWizardStateStateEnum.PaymentMethodActionRequired &&
                      !isStripeCardPaymentConfirmed
                    ) {
                      if (!paymentMethodRequiredActionDetails?.paymentIntentClientSecret) {
                        return concat(
                          of(setRequestStatus({ request: 'confirmPaymentSummary', status: 'failed' })),
                          of(showToastNotification(getCreateOrganizationWizardPaymentSummaryGeneralErrorContent(intl))),
                          of(setRequestStatus({ request: 'confirmPaymentSummary', status: 'idle' })).pipe(delay(1)),
                        )
                      }

                      isStripeCardPaymentConfirmed = true

                      return from(
                        stripeConfirmCardPayment(intl, paymentMethodRequiredActionDetails.paymentIntentClientSecret),
                      ).pipe(
                        mergeMap(() => {
                          return EMPTY
                        }),
                        catchError(() => {
                          isStripeCardPaymentConfirmed = false
                          return concat(
                            of(setRequestStatus({ request: 'confirmPaymentSummary', status: 'failed' })),
                            of(
                              showToastNotification(getCreateOrganizationWizardPaymentSummaryGeneralErrorContent(intl)),
                            ),
                            of(setRequestStatus({ request: 'confirmPaymentSummary', status: 'idle' })).pipe(delay(1)),
                          )
                        }),
                      )
                    }

                    if (updatedState === CreateOrganizationWizardStateStateEnum.MissingPaymentMethodAddress) {
                      return concat(
                        of(
                          setRequestStatus({ request: 'confirmPaymentSummary', status: 'failed' }),
                          showToastNotification(getCreateOrganizationWizardPaymentSummaryGeneralErrorContent(intl)),
                        ),
                        of(setRequestStatus({ request: 'confirmPaymentSummary', status: 'idle' })).pipe(delay(1)),
                        of(stopPollingForCreateOrganizationWizardSubmission({ createOrganizationWizardId })),
                      )
                    }

                    return of(createOrganizationWizardAdded(createOrganizationWizard))
                  }),
                  catchError((error: unknown) =>
                    error instanceof ResponseError && error.response.status === 400
                      ? from(error.response.json()).pipe(
                          mergeMap((errorResponse) => {
                            const errorType = errorResponse.type as CreateOrganizationProblemType | null
                            if (errorType != null) {
                              switch (errorType) {
                                case CreateOrganizationProblemType.InvalidReferralCode:
                                  return concat(
                                    of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                                    of(
                                      setRequestStatus({
                                        request: 'createOrganizationAndProject',
                                        status: 'idle',
                                      }),
                                    ).pipe(delay(1)),
                                    of(
                                      showToastNotification(getCreateOrganizationWithInvalidReferralCodeContent(intl)),
                                    ),
                                  )
                                case CreateOrganizationProblemType.NameConflict:
                                  return concat(
                                    of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                                    of(
                                      setRequestStatus({
                                        request: 'createOrganizationAndProject',
                                        status: 'idle',
                                      }),
                                    ).pipe(delay(1)),
                                    of(
                                      showToastNotification(
                                        getCreateOrganizationWithNonUniqueNameContent(intl, organizationReservedName),
                                      ),
                                    ),
                                  )
                                case CreateOrganizationProblemType.ReferralCodeAlreadyUsed:
                                  return concat(
                                    of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                                    of(
                                      setRequestStatus({
                                        request: 'createOrganizationAndProject',
                                        status: 'idle',
                                      }),
                                    ).pipe(delay(1)),
                                    of(
                                      showToastNotification(
                                        getCreateOrganizationWithAlreadyUsedReferralCodeContent(intl),
                                      ),
                                    ),
                                  )
                                case CreateOrganizationProblemType.ReferralCodeExpired:
                                  return concat(
                                    of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                                    of(
                                      setRequestStatus({
                                        request: 'createOrganizationAndProject',
                                        status: 'idle',
                                      }),
                                    ).pipe(delay(1)),
                                    of(
                                      showToastNotification(getCreateOrganizationWithInvalidReferralCodeContent(intl)),
                                    ),
                                  )
                                case CreateOrganizationProblemType.ReferralCodeInvalidEmail:
                                  return concat(
                                    of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                                    of(
                                      setRequestStatus({
                                        request: 'createOrganizationAndProject',
                                        status: 'idle',
                                      }),
                                    ).pipe(delay(1)),
                                    of(showToastNotification(getCreateOrganizationGeneralErrorContent(intl))),
                                  )
                                default:
                                  assertUnreachable(errorType)
                              }
                            }
                            return concat(
                              of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                              of(
                                setRequestStatus({
                                  request: 'createOrganizationAndProject',
                                  status: 'idle',
                                }),
                              ).pipe(delay(1)),
                              of(showToastNotification(getCreateOrganizationGeneralErrorContent(intl))),
                            )
                          }),
                        )
                      : concat(
                          of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                          of(
                            setRequestStatus({
                              request: 'createOrganizationAndProject',
                              status: 'idle',
                            }),
                          ).pipe(delay(1)),
                          of(showToastNotification(getCreateOrganizationGeneralErrorContent(intl))),
                        ),
                  ),
                ),
              ),
            ),
          ),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'confirmPaymentSummary', status: 'failed' })),
              of(showToastNotification(getCreateOrganizationWizardPaymentSummaryGeneralErrorContent(intl))),
              of(setRequestStatus({ request: 'confirmPaymentSummary', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          takeUntil(action$.pipe(filter(stopPollingForCreateOrganizationWizardSubmission.match))),
        ),
        of(setRequestStatus({ request: 'confirmPaymentSummary', status: 'idle' })).pipe(delay(1)),
      )
    }),
  )

export const onGetAddPaymentMethodPageData: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getAddPaymentMethodPageData.match),
    mergeMap(({ payload: { createOrganizationWizardId } }) =>
      concat(
        of(setRequestStatus({ request: 'getAddPaymentMethodPageData', status: 'pending' })),
        from(
          Promise.all([
            CreateOrganizationWizardAPI.getOrganizationWizard({ createOrganizationWizardId }),
            CreateOrganizationWizardAPI.createOrganizationWizardSetupIntents({ createOrganizationWizardId }),
          ]),
        ).pipe(
          mergeMap(([createOrganizationWizard, setupIntentResponse]) =>
            concat(
              of(
                createOrganizationWizardAdded(createOrganizationWizard),
                stripeSetupIntentAdded({
                  setupIntentId: setupIntentResponse.setupIntentId,
                  clientSecret: setupIntentResponse.clientSecret,
                }),
                setRequestStatus({ request: 'getAddPaymentMethodPageData', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getAddPaymentMethodPageData', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'getAddPaymentMethodPageData', status: 'failed' })),
              of(setRequestStatus({ request: 'getAddPaymentMethodPageData', status: 'idle' })).pipe(delay(1)),
              of(showToastNotification(getCreateOrganizationWizardPageLoadGeneralErrorContent(intl))),
            ),
          ),
        ),
      ),
    ),
  )

const pollGetOrganizationWizardUtil = (createOrganizationWizardId: string) =>
  of(null).pipe(
    switchMap(() =>
      from(CreateOrganizationWizardAPI.getOrganizationWizard({ createOrganizationWizardId })).pipe(
        switchMap((createOrganizationWizard) => {
          const formattedCreateOrganizationWizard = convertKeysToCamelCase(
            createOrganizationWizard,
          ) as CreateOrganizationWizardState
          return of(createOrganizationWizardAdded(formattedCreateOrganizationWizard))
        }),
      ),
    ),
    repeat({ delay: () => timer(1000) }),
  )

export const onGetAddPaymentMethodSetupStatus: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getAddPaymentMethodSetupStatus.match),
    mergeMap(({ payload: { createOrganizationWizardId } }) => {
      return concat(
        of(setRequestStatus({ request: 'getAddPaymentMethodSetupStatus', status: 'pending' })),
        pollGetOrganizationWizardUtil(createOrganizationWizardId).pipe(
          distinctUntilChanged((prev, curr) => prev.payload.state === curr.payload.state),
          switchMap((pollingAction) => {
            const updatedState = pollingAction.payload.state

            if (updatedState === CreateOrganizationWizardStateStateEnum.ReadyForSubmission) {
              return concat(
                of(pollingAction),
                of(
                  navigateTo({
                    path: getCreateOrganizationWizardPaymentMethodSummaryPagePath(createOrganizationWizardId),
                  }),
                ),
                of(setRequestStatus({ request: 'getAddPaymentMethodSetupStatus', status: 'succeeded' })),
                of(setRequestStatus({ request: 'getAddPaymentMethodSetupStatus', status: 'idle' })).pipe(delay(1)),
              )
            }

            if (updatedState === CreateOrganizationWizardStateStateEnum.Completed) {
              return concat(
                of(pollingAction),
                of(
                  navigateTo({
                    path: getCreateOrganizationWizardInviteTeamMembersPagePath(createOrganizationWizardId),
                  }),
                ),
                of(setRequestStatus({ request: 'getAddPaymentMethodSetupStatus', status: 'succeeded' })),
                of(setRequestStatus({ request: 'getAddPaymentMethodSetupStatus', status: 'idle' })).pipe(delay(1)),
              )
            }

            if (updatedState === CreateOrganizationWizardStateStateEnum.SetupIntentFailed) {
              return concat(
                of(pollingAction),
                of(showToastNotification(getAddNewPaymentMethodGenericValidationError(intl))),
                of(setRequestStatus({ request: 'getAddPaymentMethodSetupStatus', status: 'failed' })),
                of(setRequestStatus({ request: 'getAddPaymentMethodSetupStatus', status: 'idle' })).pipe(delay(1)),
              )
            }

            if (updatedState === CreateOrganizationWizardStateStateEnum.MissingPaymentMethodAddress) {
              return concat(
                of(pollingAction),
                of(showToastNotification(getAddNewPaymentMethodGenericValidationError(intl))),
                of(setRequestStatus({ request: 'getAddPaymentMethodSetupStatus', status: 'failed' })),
                of(setRequestStatus({ request: 'getAddPaymentMethodSetupStatus', status: 'idle' })).pipe(delay(1)),
              )
            }

            return of(pollingAction)
          }),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'getAddPaymentMethodSetupStatus', status: 'failed' })),
              of(showToastNotification(getAddNewPaymentMethodGenericValidationError(intl))),
              of(setRequestStatus({ request: 'getAddPaymentMethodSetupStatus', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          takeUntil(action$.pipe(filter(stopPollingForStripeSetupIntent.match))),
        ),
        of(setRequestStatus({ request: 'getAddPaymentMethodSetupStatus', status: 'idle' })).pipe(delay(1)),
      )
    }),
  )

import { defineMessages } from 'react-intl'

export const UnableToLoadBillingProfileErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      "We were unable to load your organization's billing profile information that is needed to continue. If this issue persists {contact_support}.",
    id: '+C4H0Lv0',
    description:
      'The description shown in a toast notification message shown when we encounter an error loading the billing profile of an organization.',
  },
  contactSupport: {
    defaultMessage: 'contact support',
    id: 'ADugRALJ',
    description:
      'The text shown for the contact support link in the update load billing profile error toast notification.',
  },
  title: {
    defaultMessage: 'Error loading billing profile',
    id: 'cd2wG2q+',
    description:
      'The toast notification title shown when we encounter an error loading the billing profile of an organization.',
  },
})

import type { FunctionComponent } from 'react'
import { ManualCreditRequestProgressBar } from '../../components/credits/ManualCreditRequestProgressBar'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { ManualCreditRequestChangePaymentMethodMainContent } from './components/ManualCreditRequestChangePaymentMethodMainContent'

export interface ManualCreditRequestChangePaymentMethodPageProps {
  /** Client secret for Stripe setup intent */
  clientSecret: string | undefined
  /** Indicates if payment is in progress */
  isGetManualCreditRequestChangePaymentMethodSetupStatusPending: boolean
  /** Flag to indicate if this component is being rendered in Storybook */
  isStorybookRender?: boolean
  /** The callback handler for when the user clicks the back button */
  onBack: () => void
  /** Callback for when the form submission error occurs */
  onSubmitStripeFormError: (message?: string) => void
  /** Callback for when the form submission succeeds */
  onSubmitStripeFormSucceeded: () => void
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
}

export const ManualCreditRequestChangePaymentMethodPage: FunctionComponent<
  ManualCreditRequestChangePaymentMethodPageProps
> = ({
  clientSecret,
  isGetManualCreditRequestChangePaymentMethodSetupStatusPending,
  isLeftColumnOpen,
  isStorybookRender,
  onBack,
  onCloseLeftDrawer,
  onSubmitStripeFormError,
  onSubmitStripeFormSucceeded,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={<ManualCreditRequestProgressBar currentPage="payment-summary" />}
      MainColumn={
        <ManualCreditRequestChangePaymentMethodMainContent
          clientSecret={clientSecret}
          isGetManualCreditRequestChangePaymentMethodSetupStatusPending={
            isGetManualCreditRequestChangePaymentMethodSetupStatusPending
          }
          isStorybookRender={isStorybookRender}
          onBack={onBack}
          onSubmitStripeFormError={onSubmitStripeFormError}
          onSubmitStripeFormSucceeded={onSubmitStripeFormSucceeded}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}

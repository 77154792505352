import { defineMessages } from 'react-intl'

export const ContainerGroupsPageMainContentMessages = defineMessages({
  billingProfileOutOfCreditsErrorPillLabel: {
    defaultMessage: 'Out of credits',
    id: 'ZAdQZ6Fd',
    description: 'The label for the billing profile out of credits error pill button.',
  },
  title: {
    defaultMessage: 'Container Groups',
    id: 'cJhC2ZN9',
    description: 'The title of for the Container Groups page.',
  },
  description: {
    defaultMessage:
      'Deploy your own custom containerized applications on SaladCloud. Containers give you full control over your deployment, allowing deeper integrations with your existing applications and pipelines.',
    id: 'VfT6+gxc',
    description: 'The description about deploying container groups.',
  },
  deployContainerGroupButtonLabel: {
    defaultMessage: 'Deploy a Container Group',
    id: '3zzJ4ePL',
    description: 'The label for the deploy container group button.',
  },
  learnMoreButtonLabel: {
    defaultMessage: 'Learn More',
    id: '0hhqHlKm',
    description: 'The label for the learn more button.',
  },
  paymentMethodErrorPillLabel: {
    defaultMessage: 'Payment method error',
    id: 'Wn5/sQlP',
    description: 'The label for the payment method error pill button.',
  },
})

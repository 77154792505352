import { defineMessages } from 'react-intl'

export const OutOfCreditsModalMessages = defineMessages({
  cancelButtonLabel: {
    defaultMessage: 'Cancel',
    id: 'yTqEAGWs',
    description: 'The label for the cancel button',
  },
  descriptionPartOne: {
    defaultMessage: `You’ve exhausted all the credits for your organization. To run more workloads, you need you recharge your credit balance.`,
    id: '9qf2aPuv',
    description: 'The description for the Out of Credits modal',
  },
  descriptionPartTwo: {
    defaultMessage: `Click the button below to visit your billing information, where you can add credits or configure automatic recharge for your organization.`,
    id: 'vo1IKiyP',
    description: 'The description for the Out of Credits modal',
  },
  title: {
    defaultMessage: `You're Out of Credits!`,
    id: 'LqxKVplc',
    description: 'The title for the Out of Credits modal',
  },
  ViewBillingInformationButtonLabel: {
    defaultMessage: 'View Billing Information',
    id: '874FZ1Ri',
    description: 'The label for the View Billing Information button',
  },
})

import { defineMessages } from 'react-intl'

export const LoadAddPaymentMethodFormErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'We were not able to load the payment method form. Please refresh the page and if this issue persists please {contact_support}.',
    id: 'XRTyLROs',
    description:
      'The description shown in a toast notification message shown when we encounter an error loading the payment form.',
  },
  contactSupport: {
    defaultMessage: 'contact support',
    id: 'TVrL1BNr',
    description: 'The text shown for the contact support link in the load payment form error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to load payment method form',
    id: 'oWDlgFRz',
    description: 'The toast notification title shown when we encounter an error loading the payment form.',
  },
})

import type { RamOption, StorageOption } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import type { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import type { ContainerGroupDetailsList } from '../../components/containerGroups/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { getContainerGroupsPagePath, getCreateContainerGroupPagePath } from '../../routes/routes-utils'
import { ContainerGroupsPageMainContent } from './components/ContainerGroupsPageMainContent'

export interface ContainerGroupsPageProps {
  /** The container groups. */
  containerGroups: ContainerGroupDetailsList
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The current project that the user is in. */
  currentProject: { name: string; displayName: string }
  /** The flag indicating if the billing profile has exhausted credits balance. */
  isBillingProfileOutOfCreditsBalance: boolean
  /** The callback executed when the user clicks the contact support button. */
  isDeleteProjectPending: boolean
  /** The flag indicating that the request to delete the project is successful. */
  isDeleteProjectSuccessful: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The flag indicating if there is a payment method error. */
  isPaymentMethodError: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback called when the user deletes the project. */
  onDeleteProject: (projectName: string) => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   */
  onProjectChange: (projectName: string) => void
  /** The callback called when the user clicks the "Learn More" button. */
  onLearnMore: () => void
  /** An array of the current available projects. If empty, we will default to no select bar being displayed */
  projects: ProjectSelectOptions
  /** The list of available ram options to choose from. */
  ramOptions: RamOption[] | undefined
  /** The list of available storage options to choose from. */
  storageOptions: StorageOption[] | undefined
}

export const ContainerGroupsPage: FunctionComponent<ContainerGroupsPageProps> = ({
  containerGroups,
  currentOrganization,
  currentProject,
  isBillingProfileOutOfCreditsBalance,
  isDeleteProjectPending,
  isDeleteProjectSuccessful,
  isLeftColumnOpen,
  isPaymentMethodError,
  onCloseLeftDrawer,
  onDeleteProject,
  onLearnMore,
  onProjectChange,
  projects,
  ramOptions,
  storageOptions,
}) => {
  const deployNewContainerGroupPath = getCreateContainerGroupPagePath(currentOrganization.name, currentProject.name)
  const containerCommonPath = getContainerGroupsPagePath(currentOrganization.name, currentProject.name)

  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={
        <LeftNavigationBar
          currentOrganization={currentOrganization}
          currentProject={currentProject}
          isDeleteProjectPending={isDeleteProjectPending}
          isDeleteProjectSuccessful={isDeleteProjectSuccessful}
          onDeleteProject={onDeleteProject}
          onProjectChange={onProjectChange}
          projects={projects}
        />
      }
      MainColumn={
        <ContainerGroupsPageMainContent
          containerCommonPath={containerCommonPath}
          containerGroups={containerGroups}
          deployNewContainerGroupPath={deployNewContainerGroupPath}
          isBillingProfileOutOfCreditsBalance={isBillingProfileOutOfCreditsBalance}
          isPaymentMethodError={isPaymentMethodError}
          onLearnMore={onLearnMore}
          organizationName={currentOrganization.name}
          ramOptions={ramOptions}
          storageOptions={storageOptions}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}

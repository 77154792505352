import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { useEffect, type FunctionComponent } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Link } from '../../../../../../components/base'
import { Button } from '../../../../../../components/Button'
import { TextField } from '../../../../../../components/TextField'
import { useFixedForm } from '../../../../../../hooks'
import { getOrganizationsPagePath } from '../../../../../../routes/routes-utils'
import { CreateOrganizationWizardPageMessages, InviteTeamMembersMessages } from '../../../../messages'
import { InviteTeamMembersFormValues } from '../../../../models'
import { useInviteTeamMembersFormValidation } from '../../useInviteTeamMembersFormValidation'

export interface InviteTeamMembersProps {
  /** The flag indicating if the callback to invite a team member is pending */
  isInviteTeamMemberPending: boolean
  /** The flag indicating that the request to invite a team member is successful. */
  isInviteTeamMemberSuccessful: boolean
  /** The callback handler for getting started. */
  onGetStarted: () => void
  /** The callback handler for inviting a team member. */
  onInviteTeamMember: (email: string) => void
}

export const InviteTeamMembers: FunctionComponent<InviteTeamMembersProps> = ({
  isInviteTeamMemberPending,
  isInviteTeamMemberSuccessful,
  onGetStarted,
  onInviteTeamMember,
}) => {
  const intl = useIntl()
  const InviteTeamMembersValidationScheme = useInviteTeamMembersFormValidation()
  const emailFieldName = 'email'
  const { control, formState, handleSubmit, reset, watch } = useFixedForm<InviteTeamMembersFormValues>({
    resolver: yupResolver(InviteTeamMembersValidationScheme),
    defaultValues: { [emailFieldName]: '' },
    onSubmit: ({ email }) => {
      email && onInviteTeamMember(email)
    },
  })
  const email = watch('email')

  const handleGetStarted = (email: string) => {
    email && onInviteTeamMember(email)
    onGetStarted()
  }

  useEffect(() => {
    if (isInviteTeamMemberSuccessful) {
      reset()
    }
  }, [isInviteTeamMemberSuccessful, reset])

  return (
    <div className="relative h-screen w-full">
      <div className="h-full overflow-auto px-6 pt-8">
        <div className="w-full max-w-2xl">
          <div className="mb-6">
            <Link url={getOrganizationsPagePath()}>
              <span className={classNames('fa-solid fa-arrow-left mr-2')} />
              {intl.formatMessage(CreateOrganizationWizardPageMessages.returnToOrganizationsLinkText)}
            </Link>
          </div>
          <h1 className="mb-6 text-3xl font-bold">{intl.formatMessage(InviteTeamMembersMessages.title)}</h1>
          <p className="mb-3 text-2xl font-bold">
            {intl.formatMessage(InviteTeamMembersMessages.inviteYourTeamHeader)}
          </p>
          <form onSubmit={handleSubmit}>
            <p className="mb-8 text-base">{intl.formatMessage(InviteTeamMembersMessages.inviteYourTeamSubheader)}</p>
            <Controller
              name={emailFieldName}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  {...fieldState}
                  defaultValue={field.value}
                  helperText={fieldState.error?.message}
                  isFullWidth
                  label={intl.formatMessage(InviteTeamMembersMessages.emailLabel)}
                />
              )}
            />
            <div className="mt-6 flex w-full gap-4">
              <div className="flex-1">
                <Button
                  isDisabled={!formState.isDirty}
                  isFullWidth
                  isLoading={isInviteTeamMemberPending}
                  type="submit"
                  variant="green-outlined"
                >
                  {intl.formatMessage(InviteTeamMembersMessages.inviteTeamMemberButtonLabel)}
                </Button>
              </div>
              <div className="flex-1">
                <Button type="button" onClick={() => handleGetStarted(email)} isFullWidth>
                  {email
                    ? intl.formatMessage(InviteTeamMembersMessages.inviteTeamAndGetStartedButtonLabel)
                    : intl.formatMessage(InviteTeamMembersMessages.skipAndGetStartedButtonLabel)}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

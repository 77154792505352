import { IntlShape } from 'react-intl'
import type { RadioCardOptions } from '../../../components/forms/RadioCards/models'
import { AddCreditsMainContentMessages } from './../messages'

/**
 * Retrieves the radio card options for the Add Credit form.
 *
 * @param intl The react-intl object.
 */
export const getCreditRequestCardOptions = (intl: IntlShape): RadioCardOptions => [
  {
    content: (
      <p className="text-center font-bold text-green-80">
        {intl.formatMessage(AddCreditsMainContentMessages.creditOption5)}
      </p>
    ),
    value: 5,
  },
  {
    content: (
      <p className="text-center font-bold text-green-80">
        {intl.formatMessage(AddCreditsMainContentMessages.creditOption100)}
      </p>
    ),
    value: 100,
  },
  {
    content: (
      <p className="text-center font-bold text-green-80">
        {intl.formatMessage(AddCreditsMainContentMessages.creditOption1000)}
      </p>
    ),
    value: 1000,
  },
  {
    content: (
      <p className="text-center font-bold text-green-80">
        {intl.formatMessage(AddCreditsMainContentMessages.creditOptionOther)}
      </p>
    ),
    value: 'other',
  },
]

/**
 * Returns a flag indicating if the submit button should be disabled for the form.
 *
 * @param formValues The form values.
 */
export const shouldSubmitButtonBeEnabled = (initialCredit?: string | number): boolean => {
  const numberValue = Number(initialCredit)
  // TODO: Eventually this logic of form validation should be moved to the form validation schema. This would require
  // not having "initialCredit" be used in two different fields.
  const validInitialCredit = numberValue >= 5 && numberValue <= 10000
  const isValid = validInitialCredit
  return isValid
}

/**
 * Returns a flag indicating if the credit value matches one of our preselected options a user could choose from.
 *
 * @param credit The form values.
 */
export const setCreditRadioCardOption = (credit?: string | number): string | number => {
  const creditValue = Number(credit)
  if (creditValue === 5 || creditValue === 100 || creditValue === 1000) {
    return creditValue
  }

  return 'other'
}

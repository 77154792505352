import { defineMessages } from 'react-intl'

export const AddPaymentMethodSucceededMessages = defineMessages({
  body: {
    defaultMessage: 'The payment method has been successfully updated.',
    id: 'ClVBLkEV',
    description:
      'The description shown in a toast notification message when the request to add a payment method succeeds.',
  },
  title: {
    defaultMessage: 'Payment Method Successfully Updated',
    id: 'YEO2kI36',
    description: 'The toast notification title shown when the request to add a payment method succeeds.',
  },
})

import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { GenericStripeFormErrorMessages } from './messages'

/**
 * Retrieves the content for a toast notification when an error occurs while adding a payment method.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 * @param stripeMessage The error message from Stripe.
 */
export const getGenericStripeFormError = (
  intl: IntlShape,
  stripeMessage?: string,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(GenericStripeFormErrorMessages.body, {
      stripe_message: stripeMessage || intl.formatMessage(GenericStripeFormErrorMessages.defaultStripeMessage),
    }),
    type: 'error',
    title: intl.formatMessage(GenericStripeFormErrorMessages.title),
  }
}

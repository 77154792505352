import { InferenceEndpoint, InferenceEndpointJob } from '@saladtechnologies/openapi-cloud-portal-browser'
import classNames from 'classnames'
import { type FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../components/TextField'
import { Card, Link, Markdown } from '../../../../components/base'
import { ErrorPill } from '../../../../components/block/ErrorPill'
import { OutOfCreditsModal } from '../../../../components/modals/OutOfCreditsModal'
import { createToastNotification } from '../../../../notifications'
import { getApiUrlCopiedToKeyboardSucceededContent } from '../../../../notifications/clientToastNotificationContent/inferenceEndpoints'
import { getOrganizationBillingPagePath } from '../../../../routes/routes-utils'
import { BillingInformationMissingModal } from '../../../CreateContainerGroup/components/BillingInformationMissingModal'
import { InferenceEndpointDetailsMainContentMessages } from '../../messages'
import { InferenceEndpointPlaygroundFormValues } from '../../models'
import { ExhaustedFreeTrialModal } from '../ExhaustedFreeTrialModal'
import { InferenceEndpointPlayground } from '../InferenceEndpointPlayground/InferenceEndpointPlayground'
import { hasValidInferenceEndpointJobSchema } from '../utils'

interface InferenceEndpointDetailsMainContentProps {
  /** The Inference Endpoint */
  inferenceEndpoint: InferenceEndpoint
  /** The inference endpoint job associated with the inference endpoint. */
  inferenceEndpointJob?: InferenceEndpointJob
  /** The path to the Inference Endpoint Marketplace */
  inferenceEndpointMarketplacePath: string
  /** The flag indicating if the billing information missing modal is showing. */
  isBillingInformationMissingModalShowing?: boolean
  /** The flag indicating if the billing profile has exhausted credits balance. */
  isBillingProfileOutOfCreditsBalance: boolean
  /** The flag indicating if the create inference endpoint job is pending. */
  isCreateInferenceEndpointJobPending: boolean
  /** The flag indicating if the exhausted free trial modal is showing. */
  isExhaustedFreeTrialModalShowing?: boolean
  /** The flag indicating if the out of credits modal is showing. */
  isOutOfCreditsModalShowing?: boolean
  /** The flag indicating if there is a payment method error. */
  isPaymentMethodError: boolean
  /** The callback executed when the user submits the playground form. */
  onCreateInferenceEndpointJob: (values: InferenceEndpointPlaygroundFormValues) => void
  /** The callback executed when the user needs to enter their billing information. */
  onEnterBillingInformation: () => void
  /** The callback executed when there is an update to the billing information required modal showing state */
  onSetBillingInformationMissingModalShowingState: (showing: boolean) => void
  /** The callback executed when there is an update to the exhausted free trial modal showing state */
  onSetExhaustedFreeTrialModalShowingState: (showing: boolean) => void
  /** The callback executed when there is an update to the out of credits modal showing state */
  onSetOutOfCreditsModalShowingState: (showing: boolean) => void
  /** The current organization name. */
  organizationName: string
}

export const InferenceEndpointDetailsMainContent: FunctionComponent<InferenceEndpointDetailsMainContentProps> = ({
  inferenceEndpoint,
  inferenceEndpointJob,
  inferenceEndpointMarketplacePath,
  isBillingInformationMissingModalShowing,
  isBillingProfileOutOfCreditsBalance,
  isCreateInferenceEndpointJobPending,
  isExhaustedFreeTrialModalShowing,
  isOutOfCreditsModalShowing,
  isPaymentMethodError,
  onCreateInferenceEndpointJob,
  onEnterBillingInformation,
  onSetBillingInformationMissingModalShowingState,
  onSetExhaustedFreeTrialModalShowingState,
  onSetOutOfCreditsModalShowingState,
  organizationName,
}) => {
  const intl = useIntl()
  const { description, displayName, endpointUrl, iconImage, priceDescription, readme } = inferenceEndpoint

  const handleCopyAccessInferenceEndpointUrl = () => {
    navigator.clipboard.writeText(endpointUrl)
    createToastNotification(getApiUrlCopiedToKeyboardSucceededContent(intl))
  }

  const isShowingPlayground = hasValidInferenceEndpointJobSchema(inferenceEndpoint)
  const isBillingError = isBillingProfileOutOfCreditsBalance || isPaymentMethodError

  return (
    <div className="w-full">
      <div className="mb-8">
        <Link url={inferenceEndpointMarketplacePath}>
          <span className={classNames('fa-solid fa-arrow-left mr-2')} />
          {intl.formatMessage(InferenceEndpointDetailsMainContentMessages.backToApiEndpointsMarketplaceButtonLabel)}
        </Link>
      </div>
      <div className="mb-4 flex justify-between">
        <img src={iconImage} alt={displayName} className="size-64 object-cover" />
        <div>
          <div className="mx-4 mb-4 flex items-center gap-4">
            <h1 className="text-3xl font-bold text-neutral-100">{displayName}</h1>
            {isBillingError && (
              <ErrorPill
                url={getOrganizationBillingPagePath(organizationName)}
                label={intl.formatMessage(
                  isBillingProfileOutOfCreditsBalance
                    ? InferenceEndpointDetailsMainContentMessages.billingProfileOutOfCreditsErrorPillLabel
                    : InferenceEndpointDetailsMainContentMessages.paymentMethodErrorPillLabel,
                )}
              />
            )}
          </div>
          <div className="mx-4 flex justify-between">
            <div className="w-2/3">
              <p className="mb-6 text-base">{description}</p>
              <TextField
                defaultValue={endpointUrl}
                isFullWidth
                label={intl.formatMessage(InferenceEndpointDetailsMainContentMessages.apiUrlLabel)}
                leftIconClassName={'fa-solid fa-lock'}
                leftIconColor="text-blue-80"
                onRightIconClick={() => handleCopyAccessInferenceEndpointUrl()}
                readonly
                rightIconClassName="fa-solid fa-clone"
                rightIconColor="text-blue-80"
              />
            </div>
            <div className="mb-14 w-1/4 content-end text-right">
              <p className="text-2xl font-bold text-green-70">{priceDescription}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        {isShowingPlayground && (
          <div className="col-span-12 flex flex-col md:order-2 md:col-span-6">
            <InferenceEndpointPlayground
              inferenceEndpoint={inferenceEndpoint}
              inferenceEndpointJob={inferenceEndpointJob}
              isCreateInferenceEndpointJobPending={isCreateInferenceEndpointJobPending}
              onCreateInferenceEndpointJob={onCreateInferenceEndpointJob}
            />
          </div>
        )}
        <div
          className={classNames('col-span-12 flex flex-col', {
            'md:col-span-12': !isShowingPlayground,
            'md:order-1 md:col-span-6': isShowingPlayground,
          })}
        >
          <Card classes="flex-grow">
            <Markdown markdown={readme} />
          </Card>
        </div>
      </div>
      {isBillingInformationMissingModalShowing && (
        <BillingInformationMissingModal
          onCloseWindow={() => onSetBillingInformationMissingModalShowingState(false)}
          onEnterBillingInformation={onEnterBillingInformation}
        />
      )}
      {isExhaustedFreeTrialModalShowing && (
        <ExhaustedFreeTrialModal
          onCloseWindow={() => onSetExhaustedFreeTrialModalShowingState(false)}
          onViewBillingInfo={onEnterBillingInformation}
        />
      )}
      {isOutOfCreditsModalShowing && (
        <OutOfCreditsModal
          onCancel={() => onSetOutOfCreditsModalShowingState(false)}
          onViewBillingInformation={onEnterBillingInformation}
        />
      )}
    </div>
  )
}

export default InferenceEndpointDetailsMainContent

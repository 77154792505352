import { FunctionComponent } from 'react'
import { ThreeColumnLayout } from '../../../../layouts/threeColumnLayout'
import { CreateOrganizationWizardPageSteps, CreditSettingsFormValues } from '../../models'
import { CreateOrganizationWizardProgressBar } from '../CreateOrganizationWizardProgressBar.tsx'
import { ConfigureCreditSettings } from './components/ConfigureCreditSettings'

export interface ConfigureCreditSettingsPageProps {
  /** The default values for the credit settings form. */
  creditSettingsDefaultValues?: CreditSettingsFormValues
  /** The flag indicating that the request to edit the credit settings is pending. */
  isEditCreateOrganizationWizardPending: boolean
  /** A flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** A callback to handle navigation (Back) */
  onBack: () => void
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** A callback that when executed configures the credit settings */
  onConfigureCreditSettings: (values: CreditSettingsFormValues) => void
}

export const ConfigureCreditSettingsPage: FunctionComponent<ConfigureCreditSettingsPageProps> = ({
  creditSettingsDefaultValues,
  isEditCreateOrganizationWizardPending,
  isLeftColumnOpen,
  onBack,
  onCloseLeftDrawer,
  onConfigureCreditSettings,
}) => {
  return (
    <ThreeColumnLayout
      LeftColumn={
        <CreateOrganizationWizardProgressBar currentPage={CreateOrganizationWizardPageSteps.ConfigureCreditSettings} />
      }
      MainColumn={
        <ConfigureCreditSettings
          creditSettingsDefaultValues={creditSettingsDefaultValues}
          isEditCreateOrganizationWizardPending={isEditCreateOrganizationWizardPending}
          onBack={onBack}
          onConfigureCreditSettings={onConfigureCreditSettings}
        />
      }
      isLeftColumnOpen={isLeftColumnOpen}
      isMainColumnWithoutLayoutStyles
      isMainColumnWithoutScrollStyles
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}

import { yupResolver } from '@hookform/resolvers/yup'
import { BillingProfileState } from '@saladtechnologies/openapi-cloud-portal-browser'
import { FunctionComponent, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Link } from '../../../../components/base'
import { Button } from '../../../../components/Button'
import { Checkbox } from '../../../../components/Checkbox'
import { TextField } from '../../../../components/TextField'
import { useFixedForm } from '../../../../hooks'
import { EditAutoRechargeSettingsMessages } from '../../Messages'
import { EditAutoRechargeSettingsFormValues } from '../../models/EditAutoRechargeSettingsFormValues'
import { useEditAutoRechargeSettingsFormValidation } from '../../utils/useEditAutoRechargeSettingsFormValidation'
import { EditAutoRechargeWarningModal } from '../EditAutoRechargeWarningModal'

export interface EditAutoRechargeSettingsMainContentProps {
  /** The organization's billing profile. */
  billingProfile: BillingProfileState
  /** The organization's credit balance in cents. */
  creditBalance: number
  /** A flag indicating that the request to edit auto recharge settings is pending. */
  isEditAutoRechargeSettingsPending: boolean
  /** A callback to handle navigation (Back) */
  onBack: () => void
  /** A callback that when edit auto recharge settings is executed */
  onEditAutoRechargeSettings: (values: EditAutoRechargeSettingsFormValues) => void
}

export const EditAutoRechargeSettingsMainContent: FunctionComponent<EditAutoRechargeSettingsMainContentProps> = ({
  billingProfile,
  creditBalance,
  isEditAutoRechargeSettingsPending,
  onBack,
  onEditAutoRechargeSettings,
}) => {
  const intl = useIntl()
  const [isEditAutoRechargeWarningModalOpen, setIsEditAutoRechargeWarningModalOpen] = useState(false)
  const { automaticRechargeSettings } = billingProfile
  const { control, handleSubmit, watch } = useFixedForm<EditAutoRechargeSettingsFormValues>({
    resolver: yupResolver(useEditAutoRechargeSettingsFormValidation()),
    onSubmit: (values) => onEditAutoRechargeSettings(values),
    defaultValues: {
      enabled: automaticRechargeSettings?.enabled ?? true,
      rechargeAmount: automaticRechargeSettings?.rechargeAmount
        ? automaticRechargeSettings.rechargeAmount / 100
        : undefined,
      rechargeThreshold: automaticRechargeSettings?.rechargeThreshold
        ? automaticRechargeSettings.rechargeThreshold / 100
        : undefined,
    },
    mode: 'onChange',
  })
  const creditBalanceInDollars = creditBalance / 100
  const formattedCurrentCreditBalance = `${intl.formatNumber(creditBalanceInDollars, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`

  const autoRechargeEnabled = watch('enabled')
  const rechargeThreshold = watch('rechargeThreshold')
  const rechargeAmount = watch('rechargeAmount')

  const handleEditAutoRechargeSettings = () => {
    onEditAutoRechargeSettings({
      enabled: autoRechargeEnabled,
      rechargeThreshold,
      rechargeAmount,
    })
  }

  return (
    <div className="relative h-screen w-full">
      <div className="h-full overflow-auto px-6 pt-8">
        <div className="w-full max-w-2xl">
          <h1 className="mb-4 text-2xl font-bold">{intl.formatMessage(EditAutoRechargeSettingsMessages.title)}</h1>
          <p className="mb-8 text-base">
            {intl.formatMessage(EditAutoRechargeSettingsMessages.description, {
              learn_more_link: (
                <Link url="https://docs.salad.com/products/sce/billing">
                  {intl.formatMessage(EditAutoRechargeSettingsMessages.learnMoreLink)}
                </Link>
              ),
            })}
          </p>

          <p className="mb-8 text-base text-neutral-100">
            {intl.formatMessage(EditAutoRechargeSettingsMessages.currentCreditBalance, {
              current_credit_amount: <span className="text-green-70">{formattedCurrentCreditBalance}</span>,
            })}
          </p>

          <form onSubmit={handleSubmit}>
            <h2 className="mb-2 text-lg font-semibold">
              {intl.formatMessage(EditAutoRechargeSettingsMessages.autoRechargeTitle)}
            </h2>

            <div className="mb-8">
              <Controller
                name="enabled"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    isChecked={field.value}
                    onChange={(checked) => field.onChange(checked)}
                    label={intl.formatMessage(EditAutoRechargeSettingsMessages.autoRechargeToggleLabel)}
                    name="enabled"
                    id="enabled"
                  />
                )}
              />
            </div>

            <Controller
              name="rechargeThreshold"
              control={control}
              render={({ field, fieldState }) => (
                <div className="mb-4">
                  <TextField
                    {...field}
                    {...fieldState}
                    defaultValue={field.value}
                    isDisabled={!autoRechargeEnabled}
                    label={intl.formatMessage(EditAutoRechargeSettingsMessages.rechargeThresholdLabel)}
                    helperText={intl.formatMessage(EditAutoRechargeSettingsMessages.rechargeThresholdHelperText)}
                    leftIconClassName="fa-solid fa-dollar-sign text-neutral-90"
                    type="number"
                  />
                </div>
              )}
            />

            <Controller
              name="rechargeAmount"
              control={control}
              render={({ field, fieldState }) => (
                <div className="mb-6">
                  <TextField
                    {...field}
                    {...fieldState}
                    defaultValue={field.value}
                    isDisabled={!autoRechargeEnabled}
                    label={intl.formatMessage(EditAutoRechargeSettingsMessages.rechargeAmountLabel)}
                    helperText={intl.formatMessage(EditAutoRechargeSettingsMessages.rechargeAmountHelperText)}
                    leftIconClassName="fa-solid fa-dollar-sign text-neutral-90"
                    type="number"
                  />
                </div>
              )}
            />

            <div className="mt-6 flex w-full gap-12">
              <div className="flex-1">
                <Button isFullWidth variant="green-outlined" type="button" onClick={onBack}>
                  {intl.formatMessage(EditAutoRechargeSettingsMessages.cancelLabel)}
                </Button>
              </div>
              <div className="flex-1">
                {autoRechargeEnabled ? (
                  <Button isFullWidth type="button" onClick={() => setIsEditAutoRechargeWarningModalOpen(true)}>
                    {intl.formatMessage(EditAutoRechargeSettingsMessages.saveLabel)}
                  </Button>
                ) : (
                  <Button isFullWidth type="submit" isLoading={isEditAutoRechargeSettingsPending}>
                    {intl.formatMessage(EditAutoRechargeSettingsMessages.saveLabel)}
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>

      {isEditAutoRechargeWarningModalOpen && (
        <EditAutoRechargeWarningModal
          isEditAutoRechargeSettingsPending={isEditAutoRechargeSettingsPending}
          onClose={() => setIsEditAutoRechargeWarningModalOpen(false)}
          onEditAutoRechargeSettings={handleEditAutoRechargeSettings}
        />
      )}
    </div>
  )
}

import { defineMessages } from 'react-intl'

export const ManualCreditRequestChangePaymentMethodSucceededMessages = defineMessages({
  body: {
    defaultMessage: 'The payment method has been successfully updated.',
    id: 'uhS2pFYG',
    description:
      'The description shown in a toast notification message when the manual credit request payment succeeds.',
  },
  title: {
    defaultMessage: 'Payment Method Successfully Updated',
    id: 'RjTNxK5F',
    description: 'The toast notification title shown when the manual credit request payment succeeds.',
  },
})

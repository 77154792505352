import { defineMessages } from 'react-intl'

export const AddPaymentMethodErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'There was an error adding your payment method. Please check your payment method information and try again, or use a different payment method.. Please try again, if the issue persists, please {contact_support}.',
    id: 'jbtGdDMp',
    description:
      'The description shown in a toast notification message shown when we encounter an error when adding the payment method.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'jS4o1+rA',
    description: 'The text shown for the contact support link in the add payment method error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to add payment method',
    id: 'HWUq7/IB',
    description: 'The toast notification title shown when we encounter an error when adding a payment method.',
  },
})

import { defineMessages } from 'react-intl'

export const AddPaymentMethodMessages = defineMessages({
  backLabel: {
    defaultMessage: 'Back',
    id: 'faJYB4DJ',
    description: 'The label for the back button.',
  },
  billingTermsLink: {
    defaultMessage: 'billing terms',
    id: 'PFp7AQ+u',
    description: 'Text for the billing terms link.',
  },
  continueLabel: {
    defaultMessage: 'Continue',
    id: 'Fx/3D0UA',
    description: 'The label for the continue button.',
  },
  freeTrialDescription: {
    defaultMessage: `You're signing up for a free trial with SaladCloud! Rest assured, your card won't be charged right away. It's only used to verify your account and securely save your payment method for future use. Payments, handled through Stripe, will follow your chosen recharge settings. Learn more in our {see_our_billing_terms_link}.`,
    id: 'xQoe5myl',
    description: 'The description explaining the payment process with Stripe.',
  },
  nonFreeTrialDescription: {
    defaultMessage:
      'Enter your credit card details below. This card will be stored in your account and can be deleted anytime. SaladCloud uses Stripe as our payment provider. Learn more in our {billing_terms_link}',
    id: 'DtcN9UUO',
    description: 'The description explaining the payment process with Stripe.',
  },
  seeOurBillingTermsLink: {
    defaultMessage: 'see our billing terms',
    id: 'KxVe+JLC',
    description: 'Text for the billing terms link.',
  },
  stripeText: {
    defaultMessage: 'Stripe Forms',
    id: 'Yo18c028',
    description: 'Text indicating that the payment method is powered by Stripe.',
  },
  title: {
    defaultMessage: 'Add Payment Method',
    id: 'BXJS3qhn',
    description: 'The title for the Add Payment Method page.',
  },
})

import type { RamOption, StorageOption } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ErrorPill } from '../../../../components/block/ErrorPill'
import type { ContainerGroupDetailsList } from '../../../../components/containerGroups/models'
import { getOrganizationBillingPagePath } from '../../../../routes/routes-utils'
import { ContainerGroupsPageMainContentMessages } from '../../messages'
import { ContainerGroupsDeploymentsList } from '../ContainerGroupsDeploymentsList'
import { NoContainerGroupsDeployed } from '../NoContainerGroupsDeployed'

interface ContainerGroupsPageMainContentProps {
  /** The path for opening container. */
  containerCommonPath: string
  /** The container groups. */
  containerGroups: ContainerGroupDetailsList
  /** The path for the "Deploy a Container Group" button. */
  deployNewContainerGroupPath: string
  /** The flag indicating if the billing profile has exhausted credits balance. */
  isBillingProfileOutOfCreditsBalance: boolean
  /** The flag indicating if there is a payment method error. */
  isPaymentMethodError: boolean
  /** The callback called when the user clicks the "Learn More" button. */
  onLearnMore: () => void
  /** The current organization name. */
  organizationName: string
  /** The list of available ram options to choose from. */
  ramOptions: RamOption[] | undefined
  /** The list of available storage options to choose from. */
  storageOptions: StorageOption[] | undefined
}

export const ContainerGroupsPageMainContent: FunctionComponent<ContainerGroupsPageMainContentProps> = ({
  containerCommonPath,
  containerGroups,
  deployNewContainerGroupPath,
  isBillingProfileOutOfCreditsBalance,
  isPaymentMethodError,
  onLearnMore,
  organizationName,
  ramOptions,
  storageOptions,
}) => {
  const intl = useIntl()

  const isBillingError = isBillingProfileOutOfCreditsBalance || isPaymentMethodError

  return (
    <div className="w-full">
      <div className="mb-4 flex items-center gap-4">
        <h1 className="text-3xl font-bold text-neutral-100">
          {intl.formatMessage(ContainerGroupsPageMainContentMessages.title)}
        </h1>
        {isBillingError && (
          <ErrorPill
            label={intl.formatMessage(
              isBillingProfileOutOfCreditsBalance
                ? ContainerGroupsPageMainContentMessages.billingProfileOutOfCreditsErrorPillLabel
                : ContainerGroupsPageMainContentMessages.paymentMethodErrorPillLabel,
            )}
            url={getOrganizationBillingPagePath(organizationName)}
          />
        )}
      </div>
      <p className="mb-4 text-base">{intl.formatMessage(ContainerGroupsPageMainContentMessages.description)}</p>
      {containerGroups.length > 0 ? (
        <ContainerGroupsDeploymentsList
          containerCommonPath={containerCommonPath}
          containerGroupsList={containerGroups}
          deployNewContainerGroupPath={deployNewContainerGroupPath}
          onLearnMore={onLearnMore}
          ramOptions={ramOptions}
          storageOptions={storageOptions}
        />
      ) : (
        <NoContainerGroupsDeployed
          onLearnMore={onLearnMore}
          deployNewContainerGroupPath={deployNewContainerGroupPath}
        />
      )}
    </div>
  )
}

import { defineMessages } from 'react-intl'

export const GenericStripeFormErrorMessages = defineMessages({
  body: {
    defaultMessage: '{stripe_message}',
    id: 'Uog5RBDr',
    description:
      'The description in a toast notification message shown when a Stripe form error occurs during the payment method setup. Includes an optional Stripe error message.',
  },
  title: {
    defaultMessage: 'Payment Method Error',
    id: '9jMqmMYZ',
    description: 'The toast notification title shown when a Stripe form error occurs.',
  },
  defaultStripeMessage: {
    defaultMessage: 'An unexpected error occurred.',
    id: 'sMfD2RgK',
    description: 'Fallback message used when no Stripe error message is provided.',
  },
})

import {
  BillingProfileStateStateEnum,
  CreateBillingPortalSessionProblemType,
  ResponseError,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, delay, filter, from, mergeMap, of, retry, switchMap, takeUntil, timer } from 'rxjs'
import { assertUnreachable, BillingAPI, BillingProfileAPI, OrganizationsAPI, ProjectsAPI } from '../apiMethods'
import {
  billingDashboardsEmbedUrlAdded,
  getBillingCreditsDashboardEmbedUrlNew,
  getBillingInvoiceDashboardEmbedUrlNew,
  getBillingUsageDashboardEmbedUrlNew,
} from '../features/billingDashboards/billingDashboardsSlice'
import {
  billingProfileAdded,
  createBillingProfileSetupIntents,
  getBillingCustomerPortalNew,
  getNewBillingPageData,
  pollBillingProfileStateAfterRequestToAddPaymentMethod,
  removePaymentMethodNew,
  stopPollBillingProfileStateAfterRequestToAddPaymentMethod,
} from '../features/billingProfile/billingProfileSlice'
import { billingProfileCreditsBalanceAdded } from '../features/billingProfileCreditsBalance/billingProfileCreditsBalanceSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationAdded } from '../features/organizations/organizationsSlice'
import { projectsAddedToOrganization } from '../features/projects/projectsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { clearStripeSetupIntent, stripeSetupIntentAdded } from '../features/stripeSetupIntent/stripeSetupIntentSlice'
import {
  getAddPaymentMethodError,
  getAddPaymentMethodSucceeded,
  getLoadAddPaymentMethodFormError,
  getLoadCreditsDashboardError,
  getLoadCustomBillingPortalError,
  getLoadCustomBillingPortalNotAuthorizedError,
  getLoadCustomBillingPortalNotReadyError,
  getLoadCustomBillingPortalOrganizationNotFoundError,
  getLoadInvoiceDashboardError,
  getLoadUsageDashboardError,
  getPaymentMethodUpdatedWithoutBillingProfileUpdated,
  getRemovePaymentMethodError,
  getUnableToLoadBillingPageDataError,
} from '../notifications/clientToastNotificationContent/billingPage'
import { AppEpic } from '../store'

export const onGetNewBillingPageData: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getNewBillingPageData.match),
    mergeMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'getNewBillingPageData', status: 'pending' })),
        from(
          Promise.all([
            OrganizationsAPI.getOrganization({ organizationName }),
            ProjectsAPI.listProjects({ organizationName }),
            BillingProfileAPI.getBillingProfile({ organizationName }),
            BillingProfileAPI.getBillingProfileCreditsBalance({ organizationName }),
          ]),
        ).pipe(
          mergeMap(
            ([
              organizationResponse,
              projectsResponse,
              billingProfileResponse,
              billingProfileCreditsBalanceResponse,
            ]) => {
              return concat(
                of(
                  organizationAdded(organizationResponse),
                  projectsAddedToOrganization({ organizationName, projects: projectsResponse.items }),
                  billingProfileAdded({
                    organizationName,
                    billingProfile: billingProfileResponse,
                  }),
                  billingProfileCreditsBalanceAdded({
                    organizationName,
                    billingProfileCreditsBalance: billingProfileCreditsBalanceResponse,
                  }),
                  getBillingInvoiceDashboardEmbedUrlNew({ organizationName }),
                  getBillingUsageDashboardEmbedUrlNew({ organizationName }),
                  getBillingCreditsDashboardEmbedUrlNew({ organizationName }),
                  setRequestStatus({ request: 'getNewBillingPageData', status: 'succeeded' }),
                ),
                of(setRequestStatus({ request: 'getNewBillingPageData', status: 'idle' })).pipe(delay(1)),
              )
            },
          ),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'getNewBillingPageData', status: 'failed' })), // TODO: What happens here? Should we navigate away?
              of(setRequestStatus({ request: 'getNewBillingPageData', status: 'idle' })).pipe(delay(1)),
              of(showToastNotification(getUnableToLoadBillingPageDataError(intl))),
            ),
          ),
        ),
      ),
    ),
  )

export const onCreateBillingProfileSetupIntents: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(createBillingProfileSetupIntents.match),
    mergeMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'createBillingProfileSetupIntents', status: 'pending' })),
        from(BillingProfileAPI.createOrganizationSetupIntents({ organizationName })).pipe(
          mergeMap((setupIntentResponse) => {
            return concat(
              of(
                stripeSetupIntentAdded({
                  setupIntentId: setupIntentResponse.setupIntentId,
                  clientSecret: setupIntentResponse.clientSecret,
                }),
                setRequestStatus({ request: 'createBillingProfileSetupIntents', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'createBillingProfileSetupIntents', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'createBillingProfileSetupIntents', status: 'failed' })),
              of(setRequestStatus({ request: 'createBillingProfileSetupIntents', status: 'idle' })).pipe(delay(1)),
              of(showToastNotification(getLoadAddPaymentMethodFormError(intl))),
            ),
          ),
        ),
      ),
    ),
  )

export const onPollBillingProfileStateAfterRequestToAddPaymentMethod: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(pollBillingProfileStateAfterRequestToAddPaymentMethod.match),
    switchMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'pollBillingProfileStateAfterRequestToAddPaymentMethod', status: 'pending' })),
        timer(0, 1000).pipe(
          switchMap(() =>
            from(BillingProfileAPI.getBillingProfile({ organizationName })).pipe(
              mergeMap((response) => {
                if (response.state === BillingProfileStateStateEnum.SetupIntentSucceeded) {
                  return concat(
                    of(
                      billingProfileAdded({
                        organizationName,
                        billingProfile: response,
                      }),
                      clearStripeSetupIntent(),
                      showToastNotification(getAddPaymentMethodSucceeded(intl)),
                      setRequestStatus({
                        request: 'pollBillingProfileStateAfterRequestToAddPaymentMethod',
                        status: 'succeeded',
                      }),
                    ),
                    of(
                      setRequestStatus({
                        request: 'pollBillingProfileStateAfterRequestToAddPaymentMethod',
                        status: 'idle',
                      }),
                    ).pipe(delay(1)),
                    of(stopPollBillingProfileStateAfterRequestToAddPaymentMethod()),
                  )
                }

                if (response.state === BillingProfileStateStateEnum.SetupIntentFailed) {
                  return concat(
                    of(
                      clearStripeSetupIntent(),
                      billingProfileAdded({
                        organizationName,
                        billingProfile: response,
                      }),
                      showToastNotification(getAddPaymentMethodError(intl)),
                    ),
                    of(
                      setRequestStatus({
                        request: 'pollBillingProfileStateAfterRequestToAddPaymentMethod',
                        status: 'failed',
                      }),
                    ),
                    of(
                      setRequestStatus({
                        request: 'pollBillingProfileStateAfterRequestToAddPaymentMethod',
                        status: 'idle',
                      }),
                    ).pipe(delay(1)),
                    of(stopPollBillingProfileStateAfterRequestToAddPaymentMethod()),
                  )
                }

                return of(
                  billingProfileAdded({
                    organizationName,
                    billingProfile: response,
                  }),
                )
              }),
              catchError(() => of()), // TODO: Not sure what we want to do on error scenario here. Maybe show a toast?
            ),
          ),
          takeUntil(action$.pipe(filter(stopPollBillingProfileStateAfterRequestToAddPaymentMethod.match))),
        ),
      ),
    ),
  )

export const onGetCreditsDashboardUrlNew: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getBillingCreditsDashboardEmbedUrlNew.match),
    mergeMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrlNew', status: 'pending' })),
        from(BillingAPI.createCreditsDashboardSession({ organizationName })).pipe(
          mergeMap(({ embedUrl }) => {
            return concat(
              of(
                billingDashboardsEmbedUrlAdded({
                  organizationName,
                  creditsEmbedUrl: embedUrl,
                }),
                setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrlNew', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrlNew', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrlNew', status: 'failed' })),
              of(setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrlNew', status: 'idle' })).pipe(delay(1)),
              of(showToastNotification(getLoadCreditsDashboardError(intl))),
            ),
          ),
        ),
      ),
    ),
  )

export const onGetUsageDashboardUrlNew: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getBillingUsageDashboardEmbedUrlNew.match),
    mergeMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrlNew', status: 'pending' })),
        from(BillingAPI.createUsageDashboardSession({ organizationName })).pipe(
          mergeMap(({ embedUrl }) => {
            return concat(
              of(
                billingDashboardsEmbedUrlAdded({
                  organizationName,
                  usageEmbedUrl: embedUrl,
                }),
                setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrlNew', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrlNew', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrlNew', status: 'failed' })),
              of(setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrlNew', status: 'idle' })).pipe(delay(1)),
              of(showToastNotification(getLoadUsageDashboardError(intl))),
            ),
          ),
        ),
      ),
    ),
  )

export const onGetInvoiceDashboardUrlNew: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getBillingInvoiceDashboardEmbedUrlNew.match),
    mergeMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrlNew', status: 'pending' })),
        from(BillingAPI.createInvoiceDashboardSession({ organizationName })).pipe(
          mergeMap(({ embedUrl }) => {
            return concat(
              of(
                billingDashboardsEmbedUrlAdded({
                  organizationName,
                  invoiceEmbedUrl: embedUrl,
                }),
                setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrlNew', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrlNew', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrlNew', status: 'failed' })),
              of(setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrlNew', status: 'idle' })).pipe(delay(1)),
              of(showToastNotification(getLoadInvoiceDashboardError(intl))),
            ),
          ),
        ),
      ),
    ),
  )

export const onGetBillingCustomerPortalNew: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getBillingCustomerPortalNew.match),
    mergeMap((action) =>
      concat(
        of(setRequestStatus({ request: 'getBillingCustomerPortalNew', status: 'pending' })),
        from(
          BillingAPI.createBillingPortalSession({
            organizationName: action.payload.organizationName,
            createBillingPortalSession: {
              redirectPath: action.payload.redirectPath,
            },
          }),
        ).pipe(
          mergeMap((createBillingPortalSessionResponse) => {
            window.location.href = createBillingPortalSessionResponse.redirectUrl
            return concat(
              of(setRequestStatus({ request: 'getBillingCustomerPortalNew', status: 'succeeded' })),
              of(setRequestStatus({ request: 'getBillingCustomerPortalNew', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError((error: unknown) => {
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as CreateBillingPortalSessionProblemType | null
                    if (errorType != null) {
                      switch (errorType) {
                        case CreateBillingPortalSessionProblemType.CustomerInformationNotReadyYet:
                          return concat(
                            of(
                              showToastNotification(getLoadCustomBillingPortalNotReadyError(intl)),
                              setRequestStatus({ request: 'getBillingCustomerPortalNew', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getBillingCustomerPortalNew', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                        case CreateBillingPortalSessionProblemType.NotAuthorizedForThisOrganization:
                          return concat(
                            of(
                              showToastNotification(getLoadCustomBillingPortalNotAuthorizedError(intl)),
                              setRequestStatus({ request: 'getBillingCustomerPortalNew', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getBillingCustomerPortalNew', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                        case CreateBillingPortalSessionProblemType.OrganizationNotFound:
                          return concat(
                            of(
                              showToastNotification(getLoadCustomBillingPortalOrganizationNotFoundError(intl)),
                              setRequestStatus({ request: 'getBillingCustomerPortalNew', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getBillingCustomerPortalNew', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          showToastNotification(getLoadCustomBillingPortalError(intl)),
                          setRequestStatus({ request: 'getBillingCustomerPortalNew', status: 'failed' }),
                        ),
                        of(setRequestStatus({ request: 'getBillingCustomerPortalNew', status: 'idle' })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    showToastNotification(getLoadCustomBillingPortalError(intl)),
                    setRequestStatus({ request: 'getBillingCustomerPortalNew', status: 'failed' }),
                  ),
                  of(setRequestStatus({ request: 'getBillingCustomerPortalNew', status: 'idle' })).pipe(delay(1)),
                )
          }),
        ),
      ),
    ),
  )

export const onRemovePaymentMethodNew: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(removePaymentMethodNew.match),
    mergeMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'removePaymentMethodNew', status: 'pending' })),
        from(BillingProfileAPI.deleteOrganizationPaymentMethod({ organizationName })).pipe(
          mergeMap(() =>
            from(BillingProfileAPI.getBillingProfile({ organizationName })).pipe(
              retry(3),
              mergeMap((billingProfileResponse) =>
                concat(
                  of(
                    billingProfileAdded({
                      organizationName,
                      billingProfile: billingProfileResponse,
                    }),
                    setRequestStatus({ request: 'removePaymentMethodNew', status: 'succeeded' }),
                  ),
                  of(setRequestStatus({ request: 'removePaymentMethodNew', status: 'idle' })).pipe(delay(1)),
                ),
              ),
              catchError(() =>
                concat(
                  of(setRequestStatus({ request: 'removePaymentMethodNew', status: 'failed' })),
                  of(setRequestStatus({ request: 'removePaymentMethodNew', status: 'idle' })).pipe(delay(1)),
                  of(showToastNotification(getPaymentMethodUpdatedWithoutBillingProfileUpdated(intl))),
                ),
              ),
            ),
          ),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'removePaymentMethodNew', status: 'failed' })),
              of(setRequestStatus({ request: 'removePaymentMethodNew', status: 'idle' })).pipe(delay(1)),
              of(showToastNotification(getRemovePaymentMethodError(intl))),
            ),
          ),
        ),
      ),
    ),
  )

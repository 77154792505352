import { defineMessages } from 'react-intl'

export const createOrganizationWizardOrganizationNameAvailabilityGenericErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to update your organization name. Please try again and if this issue persists {contact_support}.',
    id: 'rcry0ql8',
    description:
      'The description shown in a toast notification message when we encounter an error updating the organization name.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'jLPey0qR',
    description:
      'The text shown for the contact support link in the organization name update error toast notification.',
  },
  title: {
    defaultMessage: 'Something Went Wrong',
    id: 'YpTnhyPF',
    description: 'The toast notification title shown when we encounter an error updating the organization name.',
  },
})

import classNames from 'classnames'
import { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Link } from '../../../../../../components/base'
import { Button } from '../../../../../../components/Button'
import { getOrganizationsPagePath } from '../../../../../../routes/routes-utils'
import { CreateOrganizationWizardPageMessages, PaymentSummaryMessages } from '../../../../messages'
import { PaymentSummaryPaymentMethod } from '../PaymentSummaryPaymentMethod'

export interface PaymentSummaryProps {
  /* The card brand */
  creditCardBrand?: string
  /* the expiration date of the card */
  creditCardExpirationDate: string
  /* the four digits of the card number */
  creditCardLastFourDigits: string
  /** The amount of estimated tax for the payment in cents. */
  estimatedTax: number
  /** The amount of initial credit for SaladCloud Technologies Usage in cents. */
  initialCredit: number
  /** A flag indicating that the request to confirm the payment is pending. */
  isConfirmPaymentSummaryPending: boolean
  /** The callback handler for when the user clicks the back button. */
  onBack: () => void
  /** The callback handler for when the user clicks the confirm button. */
  onConfirmPaymentSummary: () => void
}

export const PaymentSummary: FunctionComponent<PaymentSummaryProps> = ({
  creditCardBrand,
  creditCardExpirationDate,
  creditCardLastFourDigits,
  estimatedTax,
  initialCredit,
  isConfirmPaymentSummaryPending,
  onBack,
  onConfirmPaymentSummary,
}) => {
  const intl = useIntl()
  const initialCreditInDollars = initialCredit / 100
  const estimatedTaxInDollars = estimatedTax / 100
  const estimatedTotal = initialCreditInDollars + estimatedTaxInDollars

  return (
    <div className="relative h-screen w-full">
      <div className="h-full overflow-auto px-6 pt-8">
        <div className="w-full max-w-2xl">
          <div className="mb-6">
            <Link url={getOrganizationsPagePath()}>
              <span className={classNames('fa-solid fa-arrow-left mr-2')} />
              {intl.formatMessage(CreateOrganizationWizardPageMessages.returnToOrganizationsLinkText)}
            </Link>
          </div>
          <h1 className="mb-3 text-3xl font-bold">{intl.formatMessage(PaymentSummaryMessages.title)}</h1>
          <p className="mb-10 text-base">
            {intl.formatMessage(PaymentSummaryMessages.description, {
              billing_terms_link: (
                <Link url="https://salad.com/terms/saladcloud">
                  {intl.formatMessage(PaymentSummaryMessages.billingTermsLink)}
                </Link>
              ),
            })}
          </p>

          <div className="mb-6">
            <div className="mb-2 flex justify-between text-neutral-60">
              <span>{intl.formatMessage(PaymentSummaryMessages.descriptionLabel)}</span>
              <span>{intl.formatMessage(PaymentSummaryMessages.totalLabel)}</span>
            </div>

            <hr className="border-neutral-50" />

            <div className="my-2 flex justify-between">
              <span>{intl.formatMessage(PaymentSummaryMessages.saladCreditLabel)}</span>
              <span>{intl.formatNumber(initialCreditInDollars, { style: 'currency', currency: 'USD' })}</span>
            </div>

            <hr className="mb-4 border-neutral-50" />

            <div className="mb-2 flex justify-end">
              <span className="mr-2 text-neutral-60">
                {intl.formatMessage(PaymentSummaryMessages.estimatedTaxLabel)}
              </span>
              <span>{intl.formatNumber(estimatedTaxInDollars, { style: 'currency', currency: 'USD' })}</span>
            </div>
            <div className="mb-8 flex justify-end">
              <span className="mr-2 text-neutral-60">
                {intl.formatMessage(PaymentSummaryMessages.estimatedTotalLabel)}
              </span>
              <span>{intl.formatNumber(estimatedTotal, { style: 'currency', currency: 'USD' })}</span>
            </div>
            <div className="mb-4">
              <span className="text-base font-medium">
                {intl.formatMessage(PaymentSummaryMessages.paymentMethodLabel)}
              </span>
            </div>

            <PaymentSummaryPaymentMethod
              creditCardBrand={creditCardBrand}
              creditCardExpirationDate={creditCardExpirationDate}
              creditCardLastFourDigits={creditCardLastFourDigits}
            />
          </div>

          {isConfirmPaymentSummaryPending && <p>{intl.formatMessage(PaymentSummaryMessages.processingPayment)}</p>}

          <div className="mt-6 flex w-full gap-12">
            <div className="flex-1">
              <Button
                variant="green-outlined"
                type="button"
                isDisabled={isConfirmPaymentSummaryPending}
                isFullWidth
                onClick={onBack}
              >
                {intl.formatMessage(PaymentSummaryMessages.backLabel)}
              </Button>
            </div>

            <div className="flex-1">
              <Button
                isDisabled={isConfirmPaymentSummaryPending}
                isLoading={isConfirmPaymentSummaryPending}
                type="submit"
                isFullWidth
                onClick={onConfirmPaymentSummary}
              >
                {intl.formatMessage(PaymentSummaryMessages.confirmLabel)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * Configures the system event logs loads entity ID based on the organization name, project name, and container group
 * name.
 *
 * @param organizationName The organization name
 * @param projectName The project name
 * @param containerGroupName The container group name
 */
export const configureSystemEventLogsEntityId = (
  organizationName: string,
  projectName: string,
  containerGroupName: string,
) => `${organizationName}:${projectName}:${containerGroupName}`

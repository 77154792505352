import { defineMessages } from 'react-intl'

export const ManualCreditRequestChangePaymentMethodGenericErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'There was an error adding your payment method. Please check your payment method information and try again, or use a different payment method.',
    id: 'D2KpvQD0',
    description:
      'The description shown in a toast notification message when we encounter an error processing a manual credit request.',
  },
  title: {
    defaultMessage: 'Payment Processing Error',
    id: '/NCs90Do',
    description: 'The toast notification title shown when we encounter an error processing a manual credit request.',
  },
})

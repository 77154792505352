import { defineMessages } from 'react-intl'

export const GetSystemEventLogsOrganizationNotFoundErrorMessages = defineMessages({
  body: {
    defaultMessage: 'Unable to retrieve Organization. If this is unexpected, please {contact_support}.',
    id: 'GnfJII1M',
    description: 'The description shown in a toast notification message shown when the organization can not be found.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: '54OfIsZC',
    description: 'The text shown for the contact support link in the create container group error toast notification.',
  },
  title: {
    defaultMessage: 'Organization not found',
    id: '0QU+zghh',
    description: 'The toast notification title shown when the organization can not be found.',
  },
})

import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { HelpScoutSupportLink } from '../../../../components/block/HelpScoutSupportLink'
import { Modal } from '../../../../components/block/Modal'
import { EditAutoRechargeWarningModalMessages } from '../../Messages'

export interface EditAutoRechargeWarningModalProps {
  /** The flag indicating that the request to change a user's edit auto recharge settings is pending. */
  isEditAutoRechargeSettingsPending: boolean
  /** The callback executed when the user clicks the `Back` button. */
  onClose: () => void
  /** A callback executed when edit auto recharge settings is executed */
  onEditAutoRechargeSettings: () => void
}

export const EditAutoRechargeWarningModal: FunctionComponent<EditAutoRechargeWarningModalProps> = ({
  isEditAutoRechargeSettingsPending,
  onClose,
  onEditAutoRechargeSettings,
}) => {
  const intl = useIntl()

  return (
    <Modal onClose={onClose} title={intl.formatMessage(EditAutoRechargeWarningModalMessages.title)}>
      <h1 className="mb-8 text-2xl font-bold">{intl.formatMessage(EditAutoRechargeWarningModalMessages.title)}</h1>
      <p className="mb-8">
        {intl.formatMessage(EditAutoRechargeWarningModalMessages.description, {
          contact_support: (
            <HelpScoutSupportLink
              linkedText={intl.formatMessage(EditAutoRechargeWarningModalMessages.contactSupportLink)}
              classNames="text-blue-90 underline"
            />
          ),
        })}
      </p>
      <div className="flex max-w-md gap-6">
        <Button variant="green-outlined" onClick={onClose} isFullWidth>
          {intl.formatMessage(EditAutoRechargeWarningModalMessages.backButtonLabel)}
        </Button>
        <Button
          variant="green-filled"
          onClick={onEditAutoRechargeSettings}
          isLoading={isEditAutoRechargeSettingsPending}
          type="button"
          isFullWidth
        >
          {intl.formatMessage(EditAutoRechargeWarningModalMessages.confirmButtonLabel)}
        </Button>
      </div>
    </Modal>
  )
}

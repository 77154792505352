import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelEvent } from '../../features/analytics/analyticsSlice'
import { selectBillingProfile } from '../../features/billingProfile/billingProfileSelectors'
import {
  confirmManualCreditRequestPayment,
  getManualCreditRequestPaymentSummaryPageData,
  stopPollBillingProfileStateAfterPaymentConfirmation,
} from '../../features/billingProfile/billingProfileSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectRequestStatus } from '../../features/requestStatus/requestStatusSelectors'
import { ManuallyAddCreditWizardPath } from '../../routes/routePaths'
import {
  getManualCreditRequestAddCreditPage,
  getManualCreditRequestChangePaymentMethodPage,
} from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { ManualCreditRequestPaymentSummaryPage } from './ManualCreditRequestPaymentSummaryPage'

export const ConnectedManualCreditRequestPaymentSummaryPage: FunctionComponent = () => {
  const { organizationName = '' } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const isGetPageDataLoading = useAppSelector(
    (state) => selectRequestStatus(state, 'getManualCreditRequestPaymentSummaryPageData') === 'pending',
  )
  const isConfirmManualCreditRequestPaymentPending = useAppSelector(
    (state) => selectRequestStatus(state, 'confirmManualCreditRequestPayment') === 'pending',
  )
  const isPollBillingProfileStateAfterPaymentConfirmation = useAppSelector(
    (state) => selectRequestStatus(state, 'pollBillingProfileStateAfterPaymentConfirmation') === 'pending',
  )
  const billingProfile = useAppSelector((state) => selectBillingProfile(state, organizationName))
  const billingProfilePaymentMethod = billingProfile?.paymentMethod
  const creditRequest = billingProfile?.addCreditsAmount

  const expirationDate = `${billingProfilePaymentMethod?.expirationMonth}/${billingProfilePaymentMethod?.expirationYear}`

  const handleConfirmManualCreditRequestPayment = useCallback(() => {
    dispatch(confirmManualCreditRequestPayment({ organizationName, creditRequest: creditRequest as number }))
  }, [dispatch, creditRequest, organizationName])

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const onBack = useCallback(() => {
    navigate(getManualCreditRequestAddCreditPage(organizationName))
  }, [navigate, organizationName])

  const onViewChangePaymentMethodPage = useCallback(() => {
    navigate(getManualCreditRequestChangePaymentMethodPage(organizationName))
  }, [navigate, organizationName])

  useEffect(() => {
    dispatch(getManualCreditRequestPaymentSummaryPageData({ organizationName }))
    dispatch(
      trackMixpanelEvent({
        event: 'Page Viewed',
        properties: { path: ManuallyAddCreditWizardPath.PaymentSummary },
      }),
    )

    return () => {
      dispatch(stopPollBillingProfileStateAfterPaymentConfirmation())
    }
  }, [dispatch, organizationName])

  return isGetPageDataLoading || billingProfile?.addCreditsAmount == null ? (
    <LoadingPage />
  ) : (
    <ManualCreditRequestPaymentSummaryPage
      creditCardBrand={billingProfilePaymentMethod?.brand}
      creditCardExpirationDate={expirationDate}
      creditCardLastFourDigits={billingProfilePaymentMethod?.lastFour as string}
      estimatedTax={billingProfile?.estimatedTax as number}
      creditAmount={creditRequest as number}
      isConfirmManualCreditRequestPaymentSummaryPending={
        isConfirmManualCreditRequestPaymentPending || isPollBillingProfileStateAfterPaymentConfirmation
      }
      isLeftColumnOpen={isLeftColumnOpen}
      onBack={onBack}
      onConfirmManualCreditRequestPaymentSummary={handleConfirmManualCreditRequestPayment}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onViewChangePaymentMethodPage={onViewChangePaymentMethodPage}
    />
  )
}

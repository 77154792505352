import { defineMessages } from 'react-intl'

export const CreateInferenceEndpointJobWebhookNotAbsoluteUrlContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to create your API endpoint job because the webhook you entered was not an absolute URL. Please {contact_support} for further assistance.',
    id: 'n35EKakt',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create an API endpoint job because the webhook was not the absolute url.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'DBiPt1ju',
    description: 'The text shown for the contact support link in the create API endpoint job error toast notification.',
  },
  title: {
    defaultMessage: 'Error creating API endpoint job',
    id: 'uWpu0RyS',
    description:
      'The toast notification title shown when we encounter an error on their request to create an API endpoint job because the webhook was not the absolute url.',
  },
})

import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { selectBillingProfile } from '../../features/billingProfile/billingProfileSelectors'
import {
  editAutoRechargeSettings,
  getEditAutoRechargeSettingsPageData,
} from '../../features/billingProfile/billingProfileSlice'
import { selectBillingProfileCreditsBalanceAmount } from '../../features/billingProfileCreditsBalance/billingProfileCreditsBalanceSelectors'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { selectRequestStatus } from '../../features/requestStatus/requestStatusSelectors'
import { setLeftColumnShowing } from '../../mockFeatures/mockNavigationBar/mockNavigationBarSlice'
import { billingAutoRechargePageRoutePath } from '../../routes/routePaths'
import { useAppDispatch, useAppSelector } from '../../store'
import { EditAutoRechargeSettingsPage } from './EditAutoRechargeSettingsPage'
import { EditAutoRechargeSettingsFormValues } from './models/EditAutoRechargeSettingsFormValues'
import { getUpdateAutomaticRechargeSettingsValues } from './utils/getUpdateAutomaticRechargeSettingsValues'

export const ConnectedEditAutoRechargeSettingsPage: FunctionComponent = () => {
  const { organizationName = '' } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const isEditAutoRechargeSettingsPending = useAppSelector(
    (state) => selectRequestStatus(state, 'editAutoRechargeSettings') === 'pending',
  )
  const isGetEditAutoRechargeSettingsPageData = useAppSelector(
    (state) => selectRequestStatus(state, 'getEditAutoRechargeSettingsPageData') === 'pending',
  )
  const billingProfile = useAppSelector((state) => selectBillingProfile(state, organizationName))
  const billingProfileCreditsBalance = useAppSelector((state) =>
    selectBillingProfileCreditsBalanceAmount(state, organizationName),
  )
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const handleEditAutoRechargeSettings = useCallback(
    (updateAutomaticRechargeSettings: EditAutoRechargeSettingsFormValues) => {
      dispatch(
        editAutoRechargeSettings({
          organizationName,
          updateAutomaticRechargeSettings: getUpdateAutomaticRechargeSettingsValues(updateAutomaticRechargeSettings),
        }),
      )
    },
    [dispatch, organizationName],
  )

  useEffect(() => {
    dispatch(getEditAutoRechargeSettingsPageData({ organizationName }))
    dispatch(trackMixpanelPageViewedEvent({ path: billingAutoRechargePageRoutePath }))
  }, [dispatch, organizationName])

  return isGetEditAutoRechargeSettingsPageData || !billingProfile ? (
    <LoadingPage />
  ) : (
    <EditAutoRechargeSettingsPage
      billingProfile={billingProfile}
      creditBalance={billingProfileCreditsBalance}
      isEditAutoRechargeSettingsPending={isEditAutoRechargeSettingsPending}
      isLeftColumnOpen={isLeftColumnOpen}
      onBack={() => navigate(-1)}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onEditAutoRechargeSettings={handleEditAutoRechargeSettings}
    />
  )
}

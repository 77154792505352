import type { FunctionComponent } from 'react'
import { ManualCreditRequestProgressBar } from '../../components/credits/ManualCreditRequestProgressBar'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { ManualCreditRequestPaymentSummaryMainContent } from './components/ManualCreditRequestPaymentSummaryMainContent'

export interface ManualCreditRequestPaymentSummaryPageProps {
  /* The card brand */
  creditCardBrand?: string
  /* The expiration date of the card */
  creditCardExpirationDate: string
  /* The four digits of the card number */
  creditCardLastFourDigits: string
  /** The amount of estimated tax for the payment in cents. */
  estimatedTax: number
  /** The credit amount in cents */
  creditAmount: number
  /** A flag indicating that the request to confirm the payment is pending. */
  isConfirmManualCreditRequestPaymentSummaryPending: boolean
  /** The callback handler for when the user clicks the back button. */
  onBack: () => void
  /** The callback handler for when the user clicks the confirm button. */
  onConfirmManualCreditRequestPaymentSummary: () => void
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback handler for when the user needs to change their payment method. */
  onViewChangePaymentMethodPage: () => void
}

export const ManualCreditRequestPaymentSummaryPage: FunctionComponent<ManualCreditRequestPaymentSummaryPageProps> = ({
  creditAmount,
  creditCardBrand,
  creditCardExpirationDate,
  creditCardLastFourDigits,
  estimatedTax,
  isConfirmManualCreditRequestPaymentSummaryPending,
  isLeftColumnOpen,
  onBack,
  onCloseLeftDrawer,
  onConfirmManualCreditRequestPaymentSummary,
  onViewChangePaymentMethodPage,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={<ManualCreditRequestProgressBar currentPage="payment-summary" />}
      MainColumn={
        <ManualCreditRequestPaymentSummaryMainContent
          creditCardBrand={creditCardBrand}
          creditCardExpirationDate={creditCardExpirationDate}
          creditCardLastFourDigits={creditCardLastFourDigits}
          estimatedTax={estimatedTax}
          creditAmount={creditAmount}
          isConfirmManualCreditRequestPaymentSummaryPending={isConfirmManualCreditRequestPaymentSummaryPending}
          onBack={onBack}
          onConfirmManualCreditRequestPaymentSummary={onConfirmManualCreditRequestPaymentSummary}
          onViewChangePaymentMethodPage={onViewChangePaymentMethodPage}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}

import { concat, delay, EMPTY, filter, from, mergeMap, of } from 'rxjs'
import { BillingProfileAPI, InferenceEndpointsAPI, OrganizationsAPI, ProjectsAPI } from '../apiMethods'
import { billingProfileAdded } from '../features/billingProfile/billingProfileSlice'
import {
  inferenceEndpointJobsAdded,
  startPollingInferenceEndpointJob,
} from '../features/inferenceEndpointJobs/inferenceEndpointJobsSlice'
import { findLatestInferenceEndpointJob } from '../features/inferenceEndpointJobs/utils'
import {
  getInferenceEndPointDetailsPageData,
  inferenceEndpointAddedToOrganization,
  setInferenceEndpointIsBillingInformationMissingModalShowing,
  setInferenceEndpointIsExhaustedFreeTrialModalShowing,
  setInferenceEndpointIsOutOfCreditsModalShowing,
} from '../features/inferenceEndpoints/inferenceEndpointsSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationAdded } from '../features/organizations/organizationsSlice'
import { projectsAddedToOrganization } from '../features/projects/projectsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { getInferenceEndpointDetailsGeneralErrorContent } from '../notifications/clientToastNotificationContent/inferenceEndpoints'
import { isInferenceEndpointJobRunningOrPending } from '../pages/InferenceEndpointDetails/utils'
import { getInferenceEndpointsMarketplacePagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onGetInferenceEndpointDetailsPageData: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getInferenceEndPointDetailsPageData.match),
    mergeMap(({ payload: { inferenceEndpointName, organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'getInferenceEndpointDetailsPageData', status: 'pending' })),
        of(
          setInferenceEndpointIsBillingInformationMissingModalShowing({
            organizationName,
            isBillingInformationMissingModalShowing: false,
          }),
          setInferenceEndpointIsExhaustedFreeTrialModalShowing({
            organizationName,
            isExhaustedFreeTrialModalShowing: false,
          }),
          setInferenceEndpointIsOutOfCreditsModalShowing({
            organizationName,
            isOutOfCreditsModalShowing: false,
          }),
        ),
        from(
          Promise.allSettled([
            InferenceEndpointsAPI.getInferenceEndpoint({
              organizationName,
              inferenceEndpointName,
            }),
            OrganizationsAPI.getOrganization({
              organizationName,
            }),
            ProjectsAPI.listProjects({
              organizationName,
            }),
            InferenceEndpointsAPI.getInferenceEndpointJobs({
              organizationName,
              inferenceEndpointName,
              page: 1,
              pageSize: 10,
            }),
            BillingProfileAPI.getBillingProfile({
              organizationName,
            }),
          ]),
        ).pipe(
          mergeMap(
            ([
              inferenceEndpointsResponse,
              organizationResponse,
              projectsResponse,
              inferenceEndpointJobsResponse,
              billingProfileResponse,
            ]) => {
              const hasAnyRequiredDataRequestsFailed =
                inferenceEndpointsResponse.status === 'rejected' ||
                organizationResponse.status === 'rejected' ||
                projectsResponse.status === 'rejected' ||
                inferenceEndpointJobsResponse.status === 'rejected'

              if (hasAnyRequiredDataRequestsFailed) {
                return concat(
                  of(
                    setRequestStatus({ request: 'getInferenceEndpointDetailsPageData', status: 'failed' }),
                    showToastNotification(getInferenceEndpointDetailsGeneralErrorContent(intl)),
                    navigateTo({ path: getInferenceEndpointsMarketplacePagePath(organizationName) }),
                  ),
                  of(setRequestStatus({ request: 'getInferenceEndpointDetailsPageData', status: 'idle' })).pipe(
                    delay(1),
                  ),
                )
              } else {
                const latestInferenceEndpointJob = inferenceEndpointJobsResponse
                  ? findLatestInferenceEndpointJob(inferenceEndpointJobsResponse.value.items)
                  : null

                const isPollingInferenceEndpointJobRunningOrPending =
                  latestInferenceEndpointJob &&
                  isInferenceEndpointJobRunningOrPending(latestInferenceEndpointJob.status)

                return concat(
                  of(
                    setRequestStatus({ request: 'getInferenceEndpointDetailsPageData', status: 'succeeded' }),
                    inferenceEndpointAddedToOrganization({
                      inferenceEndpoint: inferenceEndpointsResponse.value,
                      organizationName,
                    }),
                    organizationAdded(organizationResponse.value),
                    projectsAddedToOrganization({
                      organizationName: organizationResponse.value.name,
                      projects: projectsResponse.value.items,
                    }),
                  ),
                  billingProfileResponse.status === 'fulfilled'
                    ? of(
                        billingProfileAdded({ organizationName, billingProfile: billingProfileResponse.value }),
                        setInferenceEndpointIsOutOfCreditsModalShowing({
                          organizationName,
                          isOutOfCreditsModalShowing:
                            billingProfileResponse.value.hasCreditsBalance === false &&
                            !billingProfileResponse.value.creditsNotRequired,
                        }),
                      )
                    : EMPTY,
                  inferenceEndpointJobsResponse
                    ? of(
                        inferenceEndpointJobsAdded({
                          inferenceEndpointJobs: inferenceEndpointJobsResponse.value.items,
                          inferenceEndpointName,
                          organizationName,
                        }),
                      )
                    : EMPTY,
                  isPollingInferenceEndpointJobRunningOrPending
                    ? of(
                        startPollingInferenceEndpointJob({
                          organizationName,
                          inferenceEndpointName,
                          inferenceEndpointJobId: latestInferenceEndpointJob.id,
                        }),
                      )
                    : EMPTY,
                  of(setRequestStatus({ request: 'getInferenceEndpointDetailsPageData', status: 'idle' })).pipe(
                    delay(1),
                  ),
                )
              }
            },
          ),
        ),
      ),
    ),
  )

import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { createOrganizationWizardDeleteSuccessContentMessages } from './messages'

/**
 * Retrieves the content needed for a success `ToastNotification` that is shown when the user successfully deletes a
 * pending create organization wizard.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 * @param createOrganizationWizardReservedName The create organization wizard reserved name.
 */
export const getCreateOrganizationWizardDeleteSuccessContent = (
  intl: IntlShape,
  createOrganizationWizardReservedName: string,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(createOrganizationWizardDeleteSuccessContentMessages.body, {
      create_organization_wizard_reserved_name: createOrganizationWizardReservedName,
    }),
    type: 'success',
    title: createOrganizationWizardDeleteSuccessContentMessages.title.defaultMessage,
  }
}

import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { EmptyResult } from '../../../../components/page/EmptyResult'
import { NoOrganizationsAvailableMessages } from '../../messages'

export interface NoOrganizationsAvailableProps {
  /** The callback that starts the create organization wizard flow. */
  onCreateOrganizationWizard: () => void
}

export const NoOrganizationsAvailable: FunctionComponent<NoOrganizationsAvailableProps> = ({
  onCreateOrganizationWizard,
}) => {
  const intl = useIntl()
  return (
    <EmptyResult
      buttonIconClassName="fas fa-plus"
      buttonLabel={intl.formatMessage(NoOrganizationsAvailableMessages.createNewOrganizationButtonLabel)}
      mainMessage={intl.formatMessage(NoOrganizationsAvailableMessages.mainMessage)}
      onButtonClick={onCreateOrganizationWizard}
      subMessage={intl.formatMessage(NoOrganizationsAvailableMessages.subMessage)}
    />
  )
}

import { defineMessages } from 'react-intl'

export const LoadUsageDashboardErrorMessages = defineMessages({
  body: {
    defaultMessage:
      "We were unable to load your organization's usage dashboard. If this issue persists {contact_support}.",
    id: '5PCo+fKp',
    description:
      'The description shown in a toast notification message shown when we encounter an error loading the usage dashboard of an organization.',
  },
  contactSupport: {
    defaultMessage: 'contact support',
    id: '/Xay6pwr',
    description:
      'The text shown for the contact support link in the update load usage dashboard error toast notification.',
  },
  title: {
    defaultMessage: 'Error loading usage dashboard',
    id: 'pCloObBL',
    description:
      'The toast notification title shown when we encounter an error loading the usage dashboard of an organization.',
  },
})

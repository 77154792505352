import { defineMessages } from 'react-intl'

export const createOrganizationWizardPageLoadGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to load your current page of the organization wizard. Please try again by refreshing the page and if this issue persists {contact_support}.',
    id: 'j3Py2s9Z',
    description:
      'The description shown in a toast notification message when we encounter an error on trying to load a page that is part of the create organization wizard.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'MMeSel/1',
    description:
      'The text shown for the contact support link when getting a page load error in the create organization wizard.',
  },
  title: {
    defaultMessage: 'Something Went Wrong',
    id: 'T1rxMMEy',
    description:
      'The toast notification title shown when we encounter an error on trying to load a page that is part of the create organization wizard.',
  },
})

import { defineMessages } from 'react-intl'

export const AddCreditsMainContentMessages = defineMessages({
  backLabel: {
    defaultMessage: 'Back',
    id: 'faJYB4DJ',
    description: 'The label for the back button.',
  },
  contactSalesLinkText: {
    defaultMessage: 'Talk to sales',
    id: 'BivOsfjm',
    description: 'Text shown for the link for talking to the sales team.',
  },
  continueLabel: {
    defaultMessage: 'Continue',
    id: 'Fx/3D0UA',
    description: 'The label for the continue button.',
  },
  creditAmountInvalidMessage: {
    defaultMessage: 'Invalid credit amount',
    id: 'e2hEVURt',
    description: 'Message shown when the credit amount is invalid.',
  },
  creditAmountLabel: {
    defaultMessage: 'Amount to Add',
    id: '5qxAJwWt',
    description: 'The label for the credit amount field.',
  },
  creditAmountRequiredMessage: {
    defaultMessage: 'You must enter an amount between $5 and $10,000.',
    id: 'Ft6OfQgE',
    description: 'Message shown when the credit amount is required.',
  },
  creditOption100: {
    defaultMessage: '$100',
    id: 'rvCnxEvp',
    description: 'Option for $100 in credit options.',
  },
  creditOption1000: {
    defaultMessage: '$1000',
    id: 'yMla1uXs',
    description: 'Option for $1000 in credit options.',
  },
  creditOption5: {
    defaultMessage: '$5',
    id: 'gDZM8s88',
    description: 'Option for $5 in credit options.',
  },
  creditOptionOther: {
    defaultMessage: 'Other',
    id: 'XyE1GoTZ',
    description: 'Option for "Other" in credit options.',
  },
  currentCreditAmount: {
    defaultMessage: 'Current Credit Amount: {credit_amount}',
    id: 'oakkDlDj',
    description: 'The current credit amount of the organization.',
  },
  customAmountHelperText: {
    defaultMessage: 'Enter an amount between $5 and $10,000.',
    id: 'JzJ/CJYJ',
    description: 'The helper text for the custom amount field.',
  },
  customAmountLabel: {
    defaultMessage: 'Custom Credit Amount',
    id: '3k35fkmV',
    description: 'The label for the custom amount field.',
  },
  customAmountRequiredMessage: {
    defaultMessage: 'You must enter an amount between $5 and $10,000.',
    id: 'UuHeNR1V',
    description: 'Message shown when the custom credit amount is required.',
  },
  customAmountMinMessage: {
    defaultMessage: 'Minimum custom credit amount is $5',
    id: 'bewcDCBE',
    description: 'Message shown when custom credit amount is less than $5.',
  },
  customAmountMaxMessage: {
    defaultMessage: 'Maximum custom credit amount is $10,000',
    id: 'lM7o91Od',
    description: 'Message shown when custom credit amount is more than $10,000.',
  },
  customAmountPositiveMessage: {
    defaultMessage: 'Custom credit amount must be a positive number',
    id: 'h9JNVIgk',
    description: 'Message shown when custom credit amount is not positive.',
  },
  description: {
    defaultMessage:
      'SaladCloud operates on a prepaid service credit system, charged by usage. Enter an amount below to perform a one-time manual credit top-up on your account. {learn_more_link}.',
    id: 'F4iQnjVI',
    description: "The description explaining how a user can manually add credits to their organization's account.",
  },
  enterprisePricingInquiry: {
    defaultMessage: 'Interested in enterprise pricing? {contact_sales_link}.',
    id: 'xPgovWUv',
    description: 'Text letting a user know how to inquire about enterprise pricing.',
  },
  learnMoreLink: {
    defaultMessage: 'Learn more here',
    id: 'KY7W82RD',
    description: 'Text for the "learn more here" link.',
  },
  title: {
    defaultMessage: 'Add to Credit Balance',
    id: 'CrQmCRQ+',
    description: 'The title for the Add Credits page that is part of the manual credit request workflow.',
  },
})

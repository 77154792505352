import type { AppState } from '../../store'
import { billingProfileAdapter } from './billingProfileSlice'

const billingProfileSelectors = billingProfileAdapter.getSelectors()

export const selectBillingProfile = (state: AppState, organizationName: string) =>
  billingProfileSelectors.selectById(state.billingProfile, organizationName)?.billingProfile

export const selectIsBillingProfileOutOfCreditsBalance = (state: AppState, organizationName: string) => {
  return (
    billingProfileSelectors.selectById(state.billingProfile, organizationName)?.billingProfile.hasCreditsBalance ===
      false &&
    !billingProfileSelectors.selectById(state.billingProfile, organizationName)?.billingProfile.creditsNotRequired
  )
}

export const selectBillingProfilePaymentMethod = (state: AppState, organizationName: string) =>
  billingProfileSelectors.selectById(state.billingProfile, organizationName)?.billingProfile.paymentMethod

export const selectBillingProfileHasPaymentMethodError = (state: AppState, organizationName: string) =>
  billingProfileSelectors.selectById(state.billingProfile, organizationName)?.billingProfile.hasPaymentMethodError ===
  true

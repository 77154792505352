import type { AutomaticRechargeSettings, BillingPaymentMethod } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import type { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { BillingMainContent } from './components/BillingMainContent'

export interface BillingPageProps {
  /** The automatic recharge settings. */
  automaticRechargeSettings?: AutomaticRechargeSettings
  /** The billing credits dashboard embed url. */
  billingCreditsEmbedUrl: string | undefined
  /** The invoice dashboard embed url. */
  billingInvoiceEmbedUrl: string | undefined
  /** The billing usage dashboard embed url. */
  billingUsageEmbedUrl: string | undefined
  /** The current credit amount in cents. */
  creditAmount: number
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The path for editing automatic recharge settings. */
  editAutomaticRechargeSettingsPath: string
  /** The flag indicating if the request to create the billing profile setup intents is pending. */
  isCreateBillingProfileSetupIntentsPending: boolean
  /** The flag indicating if the request to disable auto recharge settings is pending. */
  isDisableAutoRechargeOptOutPending: boolean
  /** The flag indicating if the request to disable auto recharge settings is successful. */
  isDisableAutoRechargeOptOutSuccessful: boolean
  /** The flag indicating if the request to edit auto recharge settings is pending. */
  isEditAutoRechargeSettingsPending: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The flag indicating if the request to load billing customer portal is pending. */
  isLoadBillingCustomerPortalPending: boolean
  /** The flag indicating if the request to remove payment method is pending. */
  isRemovePaymentMethodPending: boolean
  /** The flag indicating if the request to remove payment method was successful. */
  isRemovePaymentMethodSuccessful: boolean
  /** The flag indicating if the request to verify payment method is pending. */
  isPaymentMethodVerificationInProgress: boolean
  /** The path for manually adding credit. */
  manualCreditRequestAddCreditPath: string
  /** The callback executed when the user clicks cancel for the add payment method form. */
  onCancelAddPaymentMethod: () => void
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback executed when the user confirms the auto recharge opt out. */
  onConfirmAutoRechargeOptOut: () => void
  /** A callback that when executed creates a setup intent in order to add/update their payment method. */
  onCreateBillingProfileSetupIntents: () => void
  /** A callback that when executed directs the user to their Metronome account where they could view their statements. */
  onManageBilling: () => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   */
  onProjectChange: (projectName: string) => void
  /**
   * A callback that when executed records a mixpanel event for the element that was clicked.
   *
   * @param label The label of the element that was clicked
   */
  onRecordMixpanelElementClickedEvent: (label: string) => void
  /** The callback that navigates users to the auto recharge page. */
  onNavigateToBillingAutoRechargePage: () => void
  /** The callback executed when the user clicks the remove payment method button. */
  onRemovePaymentMethod: () => void
  /** The callback handler for when the Stripe submit form hits an error. */
  onSubmitFormError: (message?: string, status?: string) => void
  /** The callback handler for when the Stripe submit form succeeded. */
  onSubmitFormSucceeded: () => void
  /** The billing payment method. */
  paymentMethod: BillingPaymentMethod | undefined
  /** The billing payment method. */
  paymentMethodBillingClientSecret: string | undefined
  /** An array of the current available projects. If empty, we will default to no select bar being displayed */
  projects: ProjectSelectOptions
}

export const BillingPage: FunctionComponent<BillingPageProps> = ({
  automaticRechargeSettings,
  billingCreditsEmbedUrl,
  billingInvoiceEmbedUrl,
  billingUsageEmbedUrl,
  creditAmount,
  currentOrganization,
  editAutomaticRechargeSettingsPath,
  isCreateBillingProfileSetupIntentsPending,
  isDisableAutoRechargeOptOutPending,
  isDisableAutoRechargeOptOutSuccessful,
  isEditAutoRechargeSettingsPending,
  isLeftColumnOpen,
  isLoadBillingCustomerPortalPending,
  isPaymentMethodVerificationInProgress,
  isRemovePaymentMethodPending,
  isRemovePaymentMethodSuccessful,
  manualCreditRequestAddCreditPath,
  onCancelAddPaymentMethod,
  onCloseLeftDrawer,
  onConfirmAutoRechargeOptOut,
  onCreateBillingProfileSetupIntents,
  onManageBilling,
  onNavigateToBillingAutoRechargePage,
  onProjectChange,
  onRecordMixpanelElementClickedEvent,
  onRemovePaymentMethod,
  onSubmitFormError,
  onSubmitFormSucceeded,
  paymentMethod,
  paymentMethodBillingClientSecret,
  projects,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={
        <LeftNavigationBar
          currentOrganization={currentOrganization}
          currentProject={undefined}
          isProjectSelectPlaceholderOptionSelectable={true}
          onProjectChange={onProjectChange}
          projects={projects}
        />
      }
      MainColumn={
        <BillingMainContent
          automaticRechargeSettings={automaticRechargeSettings}
          billingCreditsEmbedUrl={billingCreditsEmbedUrl}
          billingInvoiceEmbedUrl={billingInvoiceEmbedUrl}
          billingUsageEmbedUrl={billingUsageEmbedUrl}
          creditAmount={creditAmount}
          editAutomaticRechargeSettingsPath={editAutomaticRechargeSettingsPath}
          isCreateBillingProfileSetupIntentsPending={isCreateBillingProfileSetupIntentsPending}
          isDisableAutoRechargeOptOutPending={isDisableAutoRechargeOptOutPending}
          isDisableAutoRechargeOptOutSuccessful={isDisableAutoRechargeOptOutSuccessful}
          isEditAutoRechargeSettingsPending={isEditAutoRechargeSettingsPending}
          isLoadBillingCustomerPortalPending={isLoadBillingCustomerPortalPending}
          isPaymentMethodVerificationInProgress={isPaymentMethodVerificationInProgress}
          isRemovePaymentMethodPending={isRemovePaymentMethodPending}
          isRemovePaymentMethodSuccessful={isRemovePaymentMethodSuccessful}
          manualCreditRequestAddCreditPath={manualCreditRequestAddCreditPath}
          onCancelAddPaymentMethod={onCancelAddPaymentMethod}
          onConfirmAutoRechargeOptOut={onConfirmAutoRechargeOptOut}
          onCreateBillingProfileSetupIntents={onCreateBillingProfileSetupIntents}
          onManageBilling={onManageBilling}
          onNavigateToBillingAutoRechargePage={onNavigateToBillingAutoRechargePage}
          onRecordMixpanelElementClickedEvent={onRecordMixpanelElementClickedEvent}
          onRemovePaymentMethod={onRemovePaymentMethod}
          onSubmitFormError={onSubmitFormError}
          onSubmitFormSucceeded={onSubmitFormSucceeded}
          organizationDisplayName={currentOrganization.displayName}
          paymentMethod={paymentMethod}
          paymentMethodBillingClientSecret={paymentMethodBillingClientSecret}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { BillingProfileCreditsBalance } from '@saladtechnologies/openapi-cloud-portal-browser'

export interface BillingProfileCreditsBalanceProps {
  organizationName: string
  billingProfileCreditsBalance: BillingProfileCreditsBalance
}

export const billingProfileCreditsBalanceAdapter = createEntityAdapter<BillingProfileCreditsBalanceProps, string>({
  selectId: (item) => item.organizationName,
  sortComparer: (a, b) => a.organizationName.localeCompare(b.organizationName),
})

export const billingProfileCreditsBalanceSlice = createSlice({
  name: 'billingProfileCreditsBalanceSlice',
  initialState: billingProfileCreditsBalanceAdapter.getInitialState(),
  reducers: {
    billingProfileCreditsBalanceAdded: billingProfileCreditsBalanceAdapter.upsertOne,
  },
})

export const { billingProfileCreditsBalanceAdded } = billingProfileCreditsBalanceSlice.actions

import { defineMessages } from 'react-intl'

export const AutoRechargeSettingsSuccessMessages = defineMessages({
  body: {
    defaultMessage: 'Your automatic recharge settings have been updated successfully.',
    id: 'l8J62Grv',
    description:
      'The description shown in a toast notification message when automatic recharge settings are updated successfully.',
  },
  title: {
    defaultMessage: 'Automatic Recharge Settings Updated Successfully',
    id: 'nR1E0e7a',
    description: 'The toast notification title shown when automatic recharge settings are updated successfully.',
  },
})

import { defineMessages } from 'react-intl'

export const PaymentSummaryBackWarningModalMessages = defineMessages({
  cancelButtonLabel: {
    defaultMessage: 'Cancel',
    id: '7PSldInI',
    description: 'The label for the cancel button in the back warning modal',
  },
  description: {
    defaultMessage: 'If you leave this page, you will need to reenter your payment information.',
    id: 'b2XMMbqS',
    description: 'The message warning the user that leaving the page will cause data loss.',
  },
  goBackButtonLabel: {
    defaultMessage: 'Go Back',
    id: 'qgaz7D8I',
    description: 'The label for the button allowing the user to go back',
  },
  title: {
    defaultMessage: 'Are You Sure You Want To Go Back?',
    id: 'qL6MVAgN',
    description: 'The title of the warning modal when navigating away from the payment summary page',
  },
})

import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { BillingProfileState, UpdateAutomaticRechargeSettings } from '@saladtechnologies/openapi-cloud-portal-browser'

export interface BillingProfileProps {
  organizationName: string
  billingProfile: BillingProfileState
}

export const billingProfileAdapter = createEntityAdapter<BillingProfileProps, string>({
  selectId: (item) => item.organizationName,
  sortComparer: (a, b) => a.organizationName.localeCompare(b.organizationName),
})

export const billingProfileSlice = createSlice({
  name: 'billingProfile',
  initialState: billingProfileAdapter.getInitialState(),
  reducers: {
    billingProfileAdded: billingProfileAdapter.upsertOne,
    confirmManualCreditRequestPayment(
      _state,
      _action: PayloadAction<{ organizationName: string; creditRequest: number }>,
    ) {},
    createBillingProfileSetupIntents(_state, _action: PayloadAction<{ organizationName: string }>) {},
    disableAutoRechargeSettings(_state, _action: PayloadAction<{ organizationName: string }>) {},
    editAutoRechargeSettings(
      _state,
      _action: PayloadAction<{
        organizationName: string
        updateAutomaticRechargeSettings: UpdateAutomaticRechargeSettings
      }>,
    ) {},
    getBillingCustomerPortalNew(_state, _action: PayloadAction<{ organizationName: string; redirectPath: string }>) {},
    getBillingProfile(_state, _action: PayloadAction<{ organizationName: string }>) {},
    getEditAutoRechargeSettingsPageData(_state, _action: PayloadAction<{ organizationName: string }>) {},
    getManualCreditRequestAddCreditPageData(_state, _action: PayloadAction<{ organizationName: string }>) {},
    getManualCreditRequestChangePaymentMethodPageData(_state, _action: PayloadAction<{ organizationName: string }>) {},
    getManualCreditRequestChangePaymentMethodSetupStatus(
      _state,
      _action: PayloadAction<{ organizationName: string }>,
    ) {},
    getManualCreditRequestPaymentSummaryPageData(_state, _action: PayloadAction<{ organizationName: string }>) {},
    getNewBillingPageData(_state, _action: PayloadAction<{ organizationName: string }>) {},
    pollBillingProfileStateAfterPaymentConfirmation(
      _state,
      _action: PayloadAction<{ organizationName: string; creditRequest: number }>,
    ) {},
    pollBillingProfileStateAfterRequestToAddPaymentMethod(
      _state,
      _action: PayloadAction<{ organizationName: string }>,
    ) {},
    removePaymentMethodNew(_state, _action: PayloadAction<{ organizationName: string }>) {},
    stopPollBillingProfileStateAfterPaymentConfirmation() {},
    stopPollBillingProfileStateAfterRequestToAddPaymentMethod() {},
    stopPollingForManualCreditChangePaymentMethod() {},
    updateCreditRequestAmount(_state, _action: PayloadAction<{ organizationName: string; creditRequest: number }>) {},
  },
})

export const {
  billingProfileAdded,
  confirmManualCreditRequestPayment,
  createBillingProfileSetupIntents,
  disableAutoRechargeSettings,
  editAutoRechargeSettings,
  getBillingCustomerPortalNew,
  getBillingProfile,
  getEditAutoRechargeSettingsPageData,
  getManualCreditRequestAddCreditPageData,
  getManualCreditRequestChangePaymentMethodPageData,
  getManualCreditRequestChangePaymentMethodSetupStatus,
  getManualCreditRequestPaymentSummaryPageData,
  getNewBillingPageData,
  pollBillingProfileStateAfterPaymentConfirmation,
  pollBillingProfileStateAfterRequestToAddPaymentMethod,
  removePaymentMethodNew,
  stopPollBillingProfileStateAfterPaymentConfirmation,
  stopPollBillingProfileStateAfterRequestToAddPaymentMethod,
  stopPollingForManualCreditChangePaymentMethod,
  updateCreditRequestAmount,
} = billingProfileSlice.actions

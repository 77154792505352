import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import {
  QueryContainerGroupSystemLogsRequest,
  SystemLogsItemsInner,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import { configureSystemEventLogsEntityId } from './utils'

export interface SystemEventLogsProps {
  containerGroupName: string
  organizationName: string
  projectName: string
  systemEventLogs: SystemLogsItemsInner[]
}

export const systemEventLogsAdapter = createEntityAdapter<SystemEventLogsProps, string>({
  selectId: (item) =>
    configureSystemEventLogsEntityId(item.organizationName, item.projectName, item.containerGroupName),
  sortComparer: (a, b) => a.containerGroupName.localeCompare(b.containerGroupName),
})

export const systemEventLogsSlice = createSlice({
  name: 'systemEventLogsDetails',
  initialState: systemEventLogsAdapter.getInitialState(),
  reducers: {
    getSystemEventLogs(_state, _action: PayloadAction<QueryContainerGroupSystemLogsRequest>) {},
    systemEventLogsAdded: systemEventLogsAdapter.upsertOne,
    startPollingSystemEventLogs(
      _state,
      _action: PayloadAction<{ containerGroupName: string; organizationName: string; projectName: string }>,
    ) {},
    stopPollingSystemEventLogs() {},
  },
})

export const { getSystemEventLogs, startPollingSystemEventLogs, stopPollingSystemEventLogs, systemEventLogsAdded } =
  systemEventLogsSlice.actions

import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { ManualCreditRequestSuccessfulContentMessages } from './messages'

/**
 * Retrieves the content needed for a success `ToastNotification` that is shown when the user has added credit to their
 * organization manually.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getManualCreditRequestSuccessful = (
  intl: IntlShape,
  creditAmount: number,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  const creditAmountInDollars = creditAmount / 100
  return {
    body: intl.formatMessage(ManualCreditRequestSuccessfulContentMessages.body, {
      credit_amount: (
        <span className="font-bold text-green-80">
          {intl.formatNumber(creditAmountInDollars, { style: 'currency', currency: 'USD' })}
        </span>
      ),
    }),
    type: 'success',
    title: intl.formatMessage(ManualCreditRequestSuccessfulContentMessages.title),
  }
}

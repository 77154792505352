import { defineMessages } from 'react-intl'

export const ManualCreditRequestChangePaymentMethodMainContentMessages = defineMessages({
  backLabel: {
    defaultMessage: 'Back',
    id: 'faJYB4DJ',
    description: 'The label for the back button.',
  },
  billingTermsLink: {
    defaultMessage: 'billing terms',
    id: 'PFp7AQ+u',
    description: 'Text for the billing terms link.',
  },
  continueLabel: {
    defaultMessage: 'Continue',
    id: 'Fx/3D0UA',
    description: 'The label for the continue button.',
  },
  description: {
    defaultMessage:
      'Enter your credit card details below. This card will be stored in your account and can be deleted anytime. SaladCloud uses Stripe as our payment provider. Learn more in our {billing_terms_link}.',
    id: 'C3txjkER',
    description: 'The description explaining the payment process with Stripe.',
  },
  stripeText: {
    defaultMessage: 'Stripe Forms',
    id: 'Yo18c028',
    description: 'Text indicating that the payment method is powered by Stripe.',
  },
  title: {
    defaultMessage: 'Change Payment Method',
    id: 'PkXP8oRT',
    description: 'The title for the Add Payment Method page.',
  },
})

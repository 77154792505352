import { EditAutoRechargeSettingsFormValues } from '../models/EditAutoRechargeSettingsFormValues'

export const getUpdateAutomaticRechargeSettingsValues = (
  EditAutoRechargeSettingsFormValues: EditAutoRechargeSettingsFormValues,
) => {
  const { rechargeAmount, rechargeThreshold } = EditAutoRechargeSettingsFormValues
  return EditAutoRechargeSettingsFormValues.enabled
    ? // The backend accepts the values in cents, so we need to multiply the values by 100
      {
        enabled: true,
        ...(rechargeAmount && { rechargeAmount: rechargeAmount * 100 }),
        ...(rechargeThreshold && { rechargeThreshold: rechargeThreshold * 100 }),
      }
    : {
        enabled: false,
      }
}

import { SystemLogsItemsInner } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Link } from '../../../../../components/base'
import { Table } from '../../../../../components/block/Table'
import { TableColumnWidth, TableSortingOrder } from '../../../../../components/block/Table/models'
import { FeaturePreviewPill } from '../../../../../components/featurePreview/FeaturePreviewPill'
import { formatToDateTime } from '../../../../../utils'
import { SystemEventLogsMessages } from './messages/SystemEventLogsMessages'

interface SystemEventLogsProps {
  /** The system event logs array of items */
  systemEventLogs: SystemLogsItemsInner[]
  /** The status of the system event logs request if pending */
  isGetSystemEventLogsPending: boolean
}

export const SystemEventLogs: FunctionComponent<SystemEventLogsProps> = ({
  isGetSystemEventLogsPending,
  systemEventLogs,
}) => {
  const intl = useIntl()

  const headers = [
    {
      key: SystemEventLogsMessages.eventLabel.id,
      label: intl.formatMessage(SystemEventLogsMessages.eventLabel),
      columnWidth: TableColumnWidth.FiveTwelfths,
    },
    {
      key: SystemEventLogsMessages.machineIdLabel.id,
      label: intl.formatMessage(SystemEventLogsMessages.machineIdLabel),
      columnWidth: TableColumnWidth.TwoTwelfths,
    },
    {
      key: SystemEventLogsMessages.createTimeLabel.id,
      label: intl.formatMessage(SystemEventLogsMessages.createTimeLabel),
      initialSort: true,
      direction: TableSortingOrder.Ascending,
      columnWidth: TableColumnWidth.TwoTwelfths,
    },
    {
      key: SystemEventLogsMessages.gpuTypeLabel.id,
      label: intl.formatMessage(SystemEventLogsMessages.gpuTypeLabel),
      columnWidth: TableColumnWidth.TwoTwelfths,
    },
    {
      key: SystemEventLogsMessages.versionLabel.id,
      label: intl.formatMessage(SystemEventLogsMessages.versionLabel),
      columnWidth: TableColumnWidth.OneTwelfth,
    },
  ]

  const formattedSystemEventLogs = useMemo(() => {
    return systemEventLogs?.map((log) => ({
      [SystemEventLogsMessages.eventLabel.id]: {
        value: log.message,
      },
      [SystemEventLogsMessages.machineIdLabel.id]: {
        value: log.machineId,
      },
      [SystemEventLogsMessages.createTimeLabel.id]: {
        sortWith: new Date(log.createTime),
        value: formatToDateTime(intl, log.createTime),
      },
      [SystemEventLogsMessages.gpuTypeLabel.id]: {
        value: log.gpuClassName,
      },
      [SystemEventLogsMessages.versionLabel.id]: {
        value: log.version,
      },
    }))
  }, [systemEventLogs, intl])

  return (
    <>
      <div className="mt-4">
        <FeaturePreviewPill />
      </div>
      <div className="my-4 font-bold">{intl.formatMessage(SystemEventLogsMessages.recentContainerEventsHeader)}</div>
      <div className="my-4">
        {intl.formatMessage(SystemEventLogsMessages.systemEventLogsDescriptionText, {
          our_documentation_here: (
            <Link url="https://docs.salad.com/products/sce/container-groups/system-events">
              {intl.formatMessage(SystemEventLogsMessages.ourDocumentationHereLinkText)}
            </Link>
          ),
        })}
      </div>
      <div className="py-2">
        <Table isLoading={isGetSystemEventLogsPending} items={formattedSystemEventLogs} headers={headers} />
      </div>
    </>
  )
}

import { defineMessages } from 'react-intl'

export const InferenceEndpointDetailsMainContentMessages = defineMessages({
  apiUrlLabel: {
    defaultMessage: 'API URL',
    id: 'OwJQzr5a',
    description: 'The label for the API URL field',
  },
  backToApiEndpointsMarketplaceButtonLabel: {
    defaultMessage: 'Back to API Endpoints Marketplace',
    id: 'LS3ZUJBH',
    description: 'The copy for the back to API Endpoints marketplace button label',
  },
  billingProfileOutOfCreditsErrorPillLabel: {
    defaultMessage: 'Out of credits',
    id: 'ZAdQZ6Fd',
    description: 'The label for the billing profile out of credits error pill button.',
  },
  enterAudioUrl: {
    defaultMessage: 'Enter an audio URL',
    id: 'aBXeNvIo',
    description: 'The text for the enter audio URL',
  },
  errorNoOutputAvailable: {
    defaultMessage: 'Api Endpoint Succeeded with No output available',
    id: 'VtALrAop',
    description: 'The error message for no output available',
  },
  enterUrlPlaceholder: {
    defaultMessage: 'https://example.com/audio.mp3',
    id: '4kdFgdIQ',
    description: 'The placeholder for the enter URL',
  },
  jsonOutputPreviewNote: {
    defaultMessage: 'Note: This output is only a preview. Press the download button to view the full transcription.',
    id: '46zzontY',
    description: 'The label for the try downloading again',
  },
  output: {
    defaultMessage: 'Output',
    id: 'AhsgMXEj',
    description: 'The copy for the output',
  },
  runModel: {
    defaultMessage: 'Run Model',
    id: 'b8h6zjtf',
    description: 'The text for the run model button',
  },
  tryItOutButtonLabel: {
    defaultMessage: 'Try it out',
    id: '9TsWG0wL',
    description: 'The label for the try it out button',
  },
  urlRequired: {
    defaultMessage: 'URL is required',
    id: 'p3VknEVH',
    description: 'The text for the URL is required',
  },
  urlInvalid: {
    defaultMessage: 'Enter a valid URL',
    id: 'TomuhzW4',
    description: 'The text for the URL is invalid',
  },
  paymentMethodErrorPillLabel: {
    defaultMessage: 'Payment method error',
    id: 'Wn5/sQlP',
    description: 'The label for the payment method error pill button.',
  },
})

import { defineMessages } from 'react-intl'

export const LoadCreditsDashboardErrorMessages = defineMessages({
  body: {
    defaultMessage:
      "We were unable to load your organization's credits dashboard. If this issue persists {contact_support}.",
    id: 'ch/M8bx1',
    description:
      'The description shown in a toast notification message shown when we encounter an error loading the credits dashboard of an organization.',
  },
  contactSupport: {
    defaultMessage: 'contact support',
    id: '63+E1ii6',
    description:
      'The text shown for the contact support link in the update load credits dashboard error toast notification.',
  },
  title: {
    defaultMessage: 'Error loading credits dashboard',
    id: 'xoME/6o4',
    description:
      'The toast notification title shown when we encounter an error loading the credits dashboard of an organization.',
  },
})

import { catchError, concat, delay, filter, from, mergeMap, of } from 'rxjs'
import { BillingProfileAPI } from '../apiMethods'
import {
  billingProfileAdded,
  editAutoRechargeSettings,
  getEditAutoRechargeSettingsPageData,
} from '../features/billingProfile/billingProfileSlice'
import { billingProfileCreditsBalanceAdded } from '../features/billingProfileCreditsBalance/billingProfileCreditsBalanceSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  getAutoRechargeSettingsErrorContent,
  getAutoRechargeSettingsSuccessContent,
} from '../notifications/clientToastNotificationContent/billingProfile'
import { getOrganizationBillingPagePath } from '../routes/routes-utils'
import { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onEditAutoRechargeSettings: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(editAutoRechargeSettings.match),
    mergeMap(({ payload: { organizationName, updateAutomaticRechargeSettings } }) =>
      concat(
        of(setRequestStatus({ request: 'editAutoRechargeSettings', status: 'pending' })),
        from(
          BillingProfileAPI.updateAutomaticRechargeSettings({
            organizationName,
            updateAutomaticRechargeSettings,
          }),
        ).pipe(
          mergeMap(() =>
            concat(
              of(
                setRequestStatus({ request: 'editAutoRechargeSettings', status: 'succeeded' }),
                showToastNotification(getAutoRechargeSettingsSuccessContent(intl)),
                navigateTo({ path: getOrganizationBillingPagePath(organizationName) }),
              ),
              of(setRequestStatus({ request: 'editAutoRechargeSettings', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError(() =>
            concat(
              of(
                setRequestStatus({ request: 'editAutoRechargeSettings', status: 'failed' }),
                showToastNotification(getAutoRechargeSettingsErrorContent(intl)),
              ),
              of(setRequestStatus({ request: 'editAutoRechargeSettings', status: 'idle' })).pipe(delay(1)),
            ),
          ),
        ),
      ),
    ),
  )

export const onGetEditAutoRechargeSettingsPageData: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getEditAutoRechargeSettingsPageData.match),
    mergeMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'getEditAutoRechargeSettingsPageData', status: 'pending' })),
        from(
          Promise.all([
            BillingProfileAPI.getBillingProfile({ organizationName }),
            BillingProfileAPI.getBillingProfileCreditsBalance({ organizationName }),
          ]),
        ).pipe(
          mergeMap(([billingProfileResponse, billingProfileCreditsBalanceResponse]) =>
            concat(
              of(
                billingProfileAdded({
                  organizationName,
                  billingProfile: billingProfileResponse,
                }),
                billingProfileCreditsBalanceAdded({
                  organizationName,
                  billingProfileCreditsBalance: billingProfileCreditsBalanceResponse,
                }),
                setRequestStatus({ request: 'getEditAutoRechargeSettingsPageData', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getEditAutoRechargeSettingsPageData', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError(() =>
            concat(
              of(
                setRequestStatus({ request: 'getEditAutoRechargeSettingsPageData', status: 'failed' }),
                showToastNotification(getAutoRechargeSettingsErrorContent(intl)),
              ),
              of(setRequestStatus({ request: 'getEditAutoRechargeSettingsPageData', status: 'idle' })).pipe(delay(1)),
            ),
          ),
        ),
      ),
    ),
  )

import { defineMessages } from 'react-intl'

export const EditAutoRechargeSettingsMessages = defineMessages({
  autoRechargeTitle: {
    defaultMessage: 'Would you like to set up automatic recharge?',
    id: 'PF6/U9YA',
    description: 'Subtitle asking if automatic recharge should be set up.',
  },
  autoRechargeToggleLabel: {
    defaultMessage: 'Yes, automatically recharge my card when my credit balance falls below a threshold.',
    id: 'pw0S6Wn9',
    description: 'The label for the auto-recharge toggle button.',
  },
  cancelLabel: {
    defaultMessage: 'Cancel',
    id: 'BEvqkEvG',
    description: 'The label for the cancel button.',
  },
  currentCreditBalance: {
    defaultMessage: 'Current Credit Amount: {current_credit_amount}',
    id: '3JhxbF/m',
    description: 'Label displaying the current credit amount.',
  },
  description: {
    defaultMessage:
      'SaladCloud operates on a prepaid service credit system, charged by usage. You can set up auto-recharge by adjusting your settings to automatically reload your balance when it dips under a certain amount. {learn_more_link}',
    id: 'E4CBslFf',
    description: 'The description explaining the auto-recharge system.',
  },
  learnMoreLink: {
    defaultMessage: 'Learn more here.',
    id: 'ZuaGC60U',
    description: 'Text for the "learn more here" link.',
  },
  rechargeAmountHelperText: {
    defaultMessage: 'Enter an amount between $10 and $1,000.',
    id: 'vXiQYfpo',
    description: 'The helper text for the recharge amount field.',
  },
  rechargeAmountLabel: {
    defaultMessage: 'Bring credit balance back up to',
    id: 'vcWpOq3d',
    description: 'The label for the recharge amount field.',
  },
  rechargeThresholdHelperText: {
    defaultMessage: 'Enter an amount between $5 and $995.',
    id: '595NNleq',
    description: 'The helper text for the recharge threshold field.',
  },
  rechargeThresholdLabel: {
    defaultMessage: 'When credit balance goes below',
    id: 'CDXey5iX',
    description: 'The label for the recharge threshold field.',
  },
  saveLabel: {
    defaultMessage: 'Save',
    id: 'Upm+yHuF',
    description: 'The label for the save button.',
  },
  title: {
    defaultMessage: 'Configure Auto-Recharge Settings',
    id: 'qPm33qMC',
    description: 'The title for the Configure Auto-Recharge Settings page.',
  },
})

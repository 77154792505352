import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Modal } from '../../../../../../components/block/Modal'
import { Button } from '../../../../../../components/Button'
import { PaymentSummaryBackWarningModalMessages } from '../../../../messages/PaymentSummaryBackWarningModalMessages'

export interface PaymentSummaryBackWarningModalProps {
  /** The callback executed when the user clicks the `Cancel` button. */
  onCancel: () => void
  /** The callback executed when the user clicks the `Leave Page` button. */
  onLeavePage: () => void
}

export const PaymentSummaryBackWarningModal: FunctionComponent<PaymentSummaryBackWarningModalProps> = ({
  onCancel,
  onLeavePage,
}) => {
  const intl = useIntl()

  return (
    <Modal onClose={onCancel} title={intl.formatMessage(PaymentSummaryBackWarningModalMessages.title)}>
      <h1 className="mb-8 text-2xl font-bold">{intl.formatMessage(PaymentSummaryBackWarningModalMessages.title)}</h1>
      <p className="mb-8 pr-6">{intl.formatMessage(PaymentSummaryBackWarningModalMessages.description)}</p>
      <div className="flex max-w-md gap-6">
        <Button variant="green-outlined" onClick={onCancel} isFullWidth>
          {intl.formatMessage(PaymentSummaryBackWarningModalMessages.cancelButtonLabel)}
        </Button>
        <Button onClick={onLeavePage} isFullWidth>
          {intl.formatMessage(PaymentSummaryBackWarningModalMessages.goBackButtonLabel)}
        </Button>
      </div>
    </Modal>
  )
}

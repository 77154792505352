import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { PaymentRemovedWithoutBillingProfileUpdatedMessages } from './messages'

/**
 * Retrieves the content needed for an info `ToastNotification` that is shown when the user has removed their payment
 * method but we fail to get an updated billing profile to reflect that change..
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getPaymentMethodUpdatedWithoutBillingProfileUpdated = (
  intl: IntlShape,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(PaymentRemovedWithoutBillingProfileUpdatedMessages.body),
    type: 'info',
    title: intl.formatMessage(PaymentRemovedWithoutBillingProfileUpdatedMessages.title),
  }
}

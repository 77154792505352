import { defineMessages } from 'react-intl'

export const GetSystemEventLogsConnectionTimeoutErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We encountered a delay fetching the system event logs, possibly due to a timeout. Try again and if this issue persists {contact_support}.',
    id: 'tKe9UiLT',
    description:
      'The description shown in a toast notification message shown when we encounter a timeout error on their request to get system logs.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: '2c0eqpBb',
    description: 'The text shown for the contact support link in the get system logs error toast notification.',
  },
  title: {
    defaultMessage: 'Error getting system event logs',
    id: 'yfD+L20E',
    description: 'The toast notification title shown when we encounter an error on their request to get system logs.',
  },
})

import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { AddPaymentMethodSucceededMessages } from './messages'

/**
 * Retrieves the content for a success `ToastNotification` when a payment method was added successfully.
 *
 * @param intl The `IntlShape` object used to format messages.
 */
export const getAddPaymentMethodSucceeded = (
  intl: IntlShape,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(AddPaymentMethodSucceededMessages.body),
    type: 'success',
    title: intl.formatMessage(AddPaymentMethodSucceededMessages.title),
  }
}

import { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Link } from '../../../components/base'
import { Spinner } from '../../../components/Spinner'
import { StripeFormWrapper } from '../../CreateOrganizationWizard/components/AddPaymentMethod/components/StripeFormWrapper'
import { ManualCreditRequestChangePaymentMethodMainContentMessages } from '../messages'

export interface ManualCreditRequestChangePaymentMethodMainContentProps {
  /** Client secret for Stripe setup intent */
  clientSecret: string | undefined
  /** Indicates if payment is in progress */
  isGetManualCreditRequestChangePaymentMethodSetupStatusPending: boolean
  /** Flag to indicate if this component is being rendered in Storybook */
  isStorybookRender?: boolean
  /** The callback handler for when the user clicks the back button */
  onBack: () => void
  /** Callback for when the form submission error occurs */
  onSubmitStripeFormError: (message?: string) => void
  /** Callback for when the form submission succeeds */
  onSubmitStripeFormSucceeded: () => void
}

export const ManualCreditRequestChangePaymentMethodMainContent: FunctionComponent<
  ManualCreditRequestChangePaymentMethodMainContentProps
> = ({
  clientSecret,
  isGetManualCreditRequestChangePaymentMethodSetupStatusPending,
  isStorybookRender,
  onBack,
  onSubmitStripeFormError,
  onSubmitStripeFormSucceeded,
}) => {
  const intl = useIntl()

  return (
    <div className="relative h-[calc(100vh-4rem)] w-full overflow-y-auto">
      <div className="px-6 pt-8">
        <div className="w-full max-w-2xl">
          <h1 className="mb-3 text-3xl font-bold">
            {intl.formatMessage(ManualCreditRequestChangePaymentMethodMainContentMessages.title)}
          </h1>
          <p className="mb-10 text-base">
            {intl.formatMessage(ManualCreditRequestChangePaymentMethodMainContentMessages.description, {
              billing_terms_link: (
                <Link url="https://salad.com/terms/saladcloud">
                  {intl.formatMessage(ManualCreditRequestChangePaymentMethodMainContentMessages.billingTermsLink)}
                </Link>
              ),
            })}
          </p>
          {!clientSecret ? (
            <div className="flex justify-center align-middle">
              <Spinner size="lg" />
            </div>
          ) : (
            <div className="mb-4">
              <StripeFormWrapper
                clientSecret={clientSecret}
                isGetAddPaymentMethodSetupStatusPending={isGetManualCreditRequestChangePaymentMethodSetupStatusPending}
                isStorybookRender={isStorybookRender}
                onBack={onBack}
                onSubmitStripeFormError={onSubmitStripeFormError}
                onSubmitStripeFormSucceeded={onSubmitStripeFormSucceeded}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { CreateJobQueueOutOfCreditsErrorContentMessages } from './messages/'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown when the user encounters an error when
 * attempting to create a job queue when the user is out of credits.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getCreateJobQueueOutOfCreditsErrorContent = (
  intl: IntlShape,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(CreateJobQueueOutOfCreditsErrorContentMessages.body),
    type: 'error',
    title: intl.formatMessage(CreateJobQueueOutOfCreditsErrorContentMessages.title),
  }
}

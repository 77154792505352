import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { boolean, number, object } from 'yup'
import { EditAutoRechargeSettingsMessages } from '../Messages'
import { EditAutoRechargeSettingsFormValues } from '../models/EditAutoRechargeSettingsFormValues'

export const useEditAutoRechargeSettingsFormValidation = (): ObjectSchema<EditAutoRechargeSettingsFormValues> => {
  const intl = useIntl()

  const validationSchema = useMemo(
    () =>
      object({
        enabled: boolean(),
        rechargeThreshold: number().when('enabled', {
          is: true,
          then: (schema) =>
            schema
              .required(intl.formatMessage(EditAutoRechargeSettingsMessages.rechargeThresholdHelperText))
              .min(5, intl.formatMessage(EditAutoRechargeSettingsMessages.rechargeThresholdHelperText))
              .max(995, intl.formatMessage(EditAutoRechargeSettingsMessages.rechargeThresholdHelperText)),
          otherwise: (schema) => schema.notRequired(),
        }),
        rechargeAmount: number().when('enabled', {
          is: true,
          then: (schema) =>
            schema
              .required(intl.formatMessage(EditAutoRechargeSettingsMessages.rechargeAmountHelperText))
              .min(10, intl.formatMessage(EditAutoRechargeSettingsMessages.rechargeAmountHelperText))
              .max(1000, intl.formatMessage(EditAutoRechargeSettingsMessages.rechargeAmountHelperText)),
          otherwise: (schema) => schema.notRequired(),
        }),
      }),
    [intl],
  )

  return validationSchema
}

import { defineMessages } from 'react-intl'

export const SystemEventLogsMessages = defineMessages({
  createTimeLabel: {
    defaultMessage: 'Time (UTC)',
    id: 'fSk5IMlj',
    description: 'Label for the Time column in system event logs table',
  },
  eventLabel: {
    defaultMessage: 'Event',
    id: 'DEIQPC5r',
    description: 'Label for the Event column in system event logs table',
  },
  filterByMachineIdPlaceholder: {
    defaultMessage: 'Machine ID',
    id: 'adFk44D6',
    description: 'Filter by Machine ID placeholder text',
  },
  gpuTypeLabel: {
    defaultMessage: 'GPU',
    id: 'Ol7YyNwF',
    description: 'Label for the GPU Type column in system event logs table',
  },
  machineIdLabel: {
    defaultMessage: 'Machine ID',
    id: 'c7oqE8KU',
    description: 'Label for the Machine ID column in system event logs table',
  },
  ourDocumentationHereLinkText: {
    defaultMessage: 'our documentation here.',
    id: '3WwSJpQK',
    description: 'Link text for documentation',
  },
  recentContainerEventsHeader: {
    defaultMessage: 'Recent Container Events',
    id: 'yuFt2tS1',
    description: 'Header for recent container events section',
  },
  systemEventLogsDescriptionText: {
    defaultMessage:
      'Access the combined system and container logs for a specific instance by clicking the button to the right of any event or the hyperlinked Machine ID. To refresh and view the latest system logs at your convenience, use the refresh button above. Explore more about these events and how to resolve them in {our_documentation_here}',
    id: 'f/be09ZP',
    description: 'System event logs description',
  },
  versionLabel: {
    defaultMessage: 'Version',
    id: 'r4D0vPDD',
    description: 'Label for the Version column in system event logs table',
  },
})

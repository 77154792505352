import { InferenceEndpoint } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { InferenceEndpointCard } from '../../../../components/InferenceEndpoint'
import { SearchWithFilters } from '../../../../components/search'
import { SearchWithFilterContentMessages } from '../../../../components/search/SearchWithFilters/messages'
import { getInferenceEndpointDetailsPagePath } from '../../../../routes/routes-utils'
import { InferenceEndpointsMarketplaceMainContentMessages } from '../../messages'

interface InferenceEndpointsMarketplaceMainContentProps {
  /** The list of inference endpoints deployments for the current project */
  inferenceEndpointsList: InferenceEndpoint[]
  /** The name of the current organization */
  organizationName: string
}

export const InferenceEndpointsMarketplaceMainContent: FunctionComponent<
  InferenceEndpointsMarketplaceMainContentProps
> = ({ inferenceEndpointsList, organizationName }) => {
  const intl = useIntl()

  const [query, setQuery] = useState<{ query: string; filter: { [key: string]: string | undefined } }>({
    query: '',
    filter: {},
  })

  const sortByLabel = intl.formatMessage(SearchWithFilterContentMessages.searchSortBy)
  const sortByAToZOption = intl.formatMessage(SearchWithFilterContentMessages.searchSortByAToZOption)
  const sortByZToAOption = intl.formatMessage(SearchWithFilterContentMessages.searchSortByZToAOption)

  const updateInferenceEndpointsListBasedOnSort = useCallback(
    (query: string, filter: { [key: string]: string | undefined }) => {
      let inferenceEndpointsListOrder = [...inferenceEndpointsList]

      inferenceEndpointsListOrder = inferenceEndpointsList.filter((inferenceEndpoint) => {
        const filterByQuery =
          query.length > 0 ? inferenceEndpoint.name.toUpperCase().includes(query.toUpperCase()) : true

        return filterByQuery
      })

      const selectedSortByOption =
        filter[sortByLabel] !== undefined && filter[sortByLabel] !== sortByLabel ? filter[sortByLabel] : undefined

      switch (selectedSortByOption) {
        case sortByAToZOption:
          inferenceEndpointsListOrder = inferenceEndpointsListOrder.sort((inferenceEndpointA, inferenceEndpointB) =>
            inferenceEndpointA.name.localeCompare(inferenceEndpointB.name),
          )
          break

        case sortByZToAOption:
          inferenceEndpointsListOrder = inferenceEndpointsListOrder
            .sort((inferenceEndpointA, inferenceEndpointB) =>
              inferenceEndpointA.name.localeCompare(inferenceEndpointB.name),
            )
            .reverse()
          break
      }

      return inferenceEndpointsListOrder
    },
    [inferenceEndpointsList, sortByAToZOption, sortByLabel, sortByZToAOption],
  )

  const inferenceEndpointsListBasedOnSort = useMemo(() => {
    return updateInferenceEndpointsListBasedOnSort(query.query, query.filter)
  }, [query, updateInferenceEndpointsListBasedOnSort])

  const shouldShowInferenceEndpointsListSearch = inferenceEndpointsListBasedOnSort.length >= 5

  return (
    <div className="flex flex-col">
      {shouldShowInferenceEndpointsListSearch && (
        <div className="mb-4 w-full">
          <SearchWithFilters
            filters={[
              {
                label: sortByLabel,
                options: [sortByAToZOption, sortByZToAOption],
              },
            ]}
            isFullWidth
            onChange={({ filter, query }) => setQuery({ query, filter })}
            placeholder={intl.formatMessage(InferenceEndpointsMarketplaceMainContentMessages.searchPlaceholder)}
          />
        </div>
      )}

      <div className="grid gap-4">
        {inferenceEndpointsListBasedOnSort.map((inferenceEndpoint) => (
          <InferenceEndpointCard
            inferenceEndpoint={inferenceEndpoint}
            key={inferenceEndpoint.id}
            path={getInferenceEndpointDetailsPagePath(organizationName, inferenceEndpoint.name)}
          />
        ))}
      </div>
    </div>
  )
}

import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Link } from '../../../components/base'
import { Button } from '../../../components/Button'
import { RadioCardsTemp } from '../../../components/forms/RadioCards'
import { TextField } from '../../../components/TextField'
import { useFixedForm } from '../../../hooks'
import { AddCreditsMainContentMessages } from '../messages'
import { AddCreditFormValues } from '../models'
import { useAddCreditFormValidation } from './useAddCreditFormValidation'
import { getCreditRequestCardOptions, setCreditRadioCardOption } from './utils'

export interface AddCreditMainContentProps {
  /** The current credit balance for the organization in cents. */
  currentCreditBalance: number
  /** The default values for the credit settings form in cents. */
  currentCreditRequestAmount?: number
  /** The flag indicating that the request to add credit to the manual credit request workflow is pending. */
  isPending: boolean
  /** A callback that when executed adds credit to the manual credit request workflow */
  onAddCredit: (values: AddCreditFormValues) => void
  /** A callback to handle navigation (Back) */
  onBack: () => void
}

export const AddCreditMainContent: FunctionComponent<AddCreditMainContentProps> = ({
  currentCreditBalance,
  currentCreditRequestAmount,
  isPending,
  onAddCredit,
  onBack,
}) => {
  const intl = useIntl()
  const currentCreditRequestAmountInDollars = currentCreditRequestAmount ? currentCreditRequestAmount / 100 : undefined
  const { control, handleSubmit, setValue, trigger, watch } = useFixedForm<AddCreditFormValues>({
    resolver: yupResolver(useAddCreditFormValidation()),
    onSubmit: (values) => onAddCredit(values),
    defaultValues: {
      credit: currentCreditRequestAmountInDollars ?? 'other',
    },
    mode: 'onChange',
  })
  const defaultOption = setCreditRadioCardOption(currentCreditRequestAmountInDollars)
  const [creditOption, setCreditOption] = useState<string | number>(defaultOption)
  const currentCreditBalanceInDollars = currentCreditBalance / 100
  const credit = watch('credit')

  const handleCreditAmountSelection = useCallback(
    (value?: string) => {
      const creditValue = setCreditRadioCardOption(value)

      if (value === 'other') {
        setValue('credit', '')
        setCreditOption('other')
      } else if (creditValue !== 'other') {
        setValue('credit', creditValue)
        setCreditOption(creditValue)
        trigger('credit')
      } else {
        setValue('credit', Number(value))
        setCreditOption('other')
        trigger('credit')
      }
    },
    [setValue, trigger],
  )

  useEffect(() => {
    if (currentCreditRequestAmountInDollars) {
      handleCreditAmountSelection(currentCreditRequestAmountInDollars.toString())
    }
  }, [currentCreditRequestAmountInDollars, handleCreditAmountSelection])

  const radioCardOptions = getCreditRequestCardOptions(intl)

  return (
    <div className="w-full max-w-2xl">
      <h1 className="mb-3 text-3xl font-bold">{intl.formatMessage(AddCreditsMainContentMessages.title)}</h1>
      <p className="mb-10 text-base">
        {intl.formatMessage(AddCreditsMainContentMessages.description, {
          learn_more_link: (
            <Link url="https://docs.salad.com/products/sce/billing">
              {intl.formatMessage(AddCreditsMainContentMessages.learnMoreLink)}
            </Link>
          ),
        })}
      </p>

      <p className="mb-6 text-sm font-bold">
        {intl.formatMessage(AddCreditsMainContentMessages.currentCreditAmount, {
          credit_amount: (
            <span className="text-green-80">
              {intl.formatNumber(currentCreditBalanceInDollars, { style: 'currency', currency: 'USD' })}
            </span>
          ),
        })}
      </p>

      <form onSubmit={handleSubmit}>
        <div className="mb-8">
          <Controller
            name="credit"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <div className="mb-4 flex gap-4">
                  <RadioCardsTemp
                    cards={radioCardOptions}
                    value={creditOption}
                    hasNoMarginBottom
                    isInline
                    label={intl.formatMessage(AddCreditsMainContentMessages.creditAmountLabel)}
                    name="credit"
                    onChange={(value) => handleCreditAmountSelection(value)}
                  />
                </div>
                <div className="mb-4 flex gap-4">
                  <TextField
                    {...field}
                    {...fieldState}
                    defaultValue={field.value}
                    hasNoDefaultHeight
                    hasNoDefaultMarginBottom
                    helperText={
                      fieldState.error?.message ??
                      intl.formatMessage(AddCreditsMainContentMessages.customAmountHelperText)
                    }
                    hideLabelText
                    isFullWidth
                    label={intl.formatMessage(AddCreditsMainContentMessages.customAmountLabel)}
                    leftIconClassName="fa-solid fa-dollar-sign text-neutral-90"
                    onChange={(value) => {
                      field.onChange(value)
                      if (value) {
                        const numericValue = Number(value)
                        const updatedValue = isNaN(numericValue) ? undefined : numericValue
                        const option = setCreditRadioCardOption(updatedValue)
                        setCreditOption(option)
                      }
                    }}
                    placeholder={credit !== 'other' ? credit : undefined}
                    type="number"
                  />
                </div>
              </>
            )}
          />
        </div>

        <p className="text-xs">
          {intl.formatMessage(AddCreditsMainContentMessages.enterprisePricingInquiry, {
            contact_sales_link: (
              <Link url="https://salad.com/sales-salad-gpu-cloud?utm_campaign=cloud&utm_medium=referral&utm_source=portal">
                {intl.formatMessage(AddCreditsMainContentMessages.contactSalesLinkText)}
              </Link>
            ),
          })}
        </p>

        <div className="mt-6 flex w-full gap-12">
          <div className="flex-1">
            <Button variant="green-outlined" type="button" onClick={onBack} isFullWidth>
              {intl.formatMessage(AddCreditsMainContentMessages.backLabel)}
            </Button>
          </div>
          <div className="flex-1">
            <Button type="submit" isLoading={isPending} isFullWidth>
              {intl.formatMessage(AddCreditsMainContentMessages.continueLabel)}
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}
